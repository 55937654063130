.woocommerce-cart {
    & .ht-header-visual {
        &.ht-header-visual-plain {
            & .ht-wrapper {
                & .ht-header-visual-content {
                    padding: 12.28125% 0 0;
                    min-height: 0;

                    & .ht-breadcrumbs {
                        margin: 24px 0 16px;
                    }

                    & h1 {
                        margin: 0;
                    }
                }
            }
        }
    }

    & .woocommerce {
        & .woocommerce-cart-form {
            & table {
                margin: 0;

                & thead {
                    & tr {
                        & th {
                            padding: 8px;
                            color: var(--black);
                        }
                    }
                }

                & tbody {
                    & tr {
                        &.cart_item {
                            & td {
                                padding: 8px;
                                vertical-align: top;

                                &.product-remove {
                                    max-width: 64px;

                                    & a.remove {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        flex-direction: column;
                                        width: auto;
                                        height: auto;
                                        max-width: 64px;
                                        background-color: transparent;

                                        & i,
                                        & svg {
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            width: 32px;
                                            height: 32px;
                                            color: var(--red) !important;
                                            font-size: 20px;
                                            line-height: 1;
                                            background-color: transparent;
                                            border-radius: 16px;
                                            transition: color var(--standard), background-color var(--standard);
                                        }

                                        & span {
                                            margin: 8px 0 0;
                                            color: var(--red) !important;
                                            font-size: 12px;
                                            line-height: 1;
                                        }

                                        &:hover {
                                            & i,
                                            & svg {
                                                color: var(--white) !important;
                                                background: var(--red) !important;
                                            }
                                        }
                                    }
                                }

                                &.product-thumbnail {
                                    width: 120px;

                                    & a {
                                        display: block;
                                        max-width: 96px;
                                        margin: 0;

                                        & img {
                                            width: 96px;
                                            margin: 0;
                                        }
                                    }
                                }

                                &.product-name,
                                &.product-quantity {
                                    font-size: responsive 16px 20px;
                                    line-height: 1.5;

                                    & dl.variation {
                                        margin: 8px 0 0;

                                        & dt,
                                        & dd {
                                            & p {
                                                font-size: responsive 16px 20px;
                                                line-height: 1.5;

                                                & span.pewc-price.pewc-cart-item-price {
                                                    font-size: responsive 16px 20px;
                                                    line-height: 1.5;
                                                }
                                            }
                                        }
                                    }
                                }

                                &.product-price,
                                &.product-subtotal {
                                    font-size: responsive 16px 20px;
                                    line-height: 1.5;

                                    & span.woocommerce-Price-amount {
                                        font-size: responsive 16px 20px;
                                        line-height: 1.5;

                                        & .woocommerce-Price-currencySymbol {
                                            font-size: responsive 16px 20px;
                                            line-height: 1.5;
                                        }

                                        &:after {
                                            content: '(ex BTW)';
                                            margin: 0 0 0 4px;
                                            font-size: responsive 8px 12px;
                                        }
                                    }
                                }
                            }
                        }

                        &:not(.cart_item) {
                            & td {
                                padding: 8px;

                                &.actions {
                                    & button.button[name="update_cart"] {
                                        position: relative;
                                        display: inline-flex;
                                        align-items: center;
                                        justify-content: center;
                                        padding: 16px 40px;
                                        margin: 0;
                                        color: var(--white);
                                        font-family: var(--font);
                                        font-weight: 500;
                                        font-size: responsive 12px 18px;
                                        line-height: 1.22222;
                                        text-align: center;
                                        text-decoration: none;
                                        text-transform: uppercase;
                                        background-color: var(--blue);
                                        border-radius: 39px;
                                        border: 0;
                                        white-space: nowrap;
                                        transition: background-color var(--standard);
                                        will-change: background-color;
                                        appearance: none;
                                        float: none;
                                        outline: none;
                                        cursor: pionter;
                
                                        & i,
                                        & svg {
                                            margin: 0 0 0 16px;
                                            font-family: var(--icons);
                                            font-size: responsive 24px 24px;
                                            line-height: 1;
                                        }
                
                                        &:hover {
                                            background-color: var(--pink);
                                        }
        
                                        &:disabled {
                                            display: none;
                                            color: var(--grey-medium-50);
                                            background-color: var(--grey-medium-35);
                                        }

                                        &:enabled {
                                            display: inline-flex;
                                            color: var(--grey-medium-50);
                                            background-color: var(--grey-medium-35);
                                        }
                                    }
        
                                    & .coupon {
                                        display: flex;
                                        
                                        & input.input-text {
                                            position: relative;
                                            width: 100%;
                                            min-width: 248px;
                                            padding: 12px 50px 12px 16px;
                                            margin: 0 -42px 0 0;
                                            color: var(--grey-dark);
                                            font-size: responsive 12px 18px;
                                            line-height: 1.22222;
                                            font-weight: 400;
                                            text-align: left;
                                            background-color: var(--grey-medium-20);
                                            border: 0;
                                            border-radius: 42px 0 0 42px;
                                            outline: none;
                                            text-transform: none;
        
                                            &::-webkit-input-placeholder,
                                            &::-moz-placeholder,
                                            &:-ms-input-placeholder,
                                            &::placeholder {
                                                color: var(--grey-medium);
                                            }
                                        }

                                        & button.button {
                                            padding: 16px 40px;
                                            color: var(--white);
                                            font-size: 0;
                                            line-height: 0;
                                            background-color: var(--blue);
                                            border-radius: 39px;
                                            transition: background-color var(--standard);
                                            will-change: background-color;

                                            &:after {
                                                content: 'Toepassen';
                                                color: var(--white);
                                                font-size: responsive 12px 18px;
                                                line-height: 1.22222;
                                            }

                                            &:hover {
                                                background-color: var(--pink);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        & .cart-collaterals {
            & .cart_totals {
                & h2 {
                    margin: 24px 0 16px;
                    font-size: responsive 14px 24px;
                    line-height: 1.5;
                }

                & table {
                    & tbody {
                        & tr {
                            &.cart-subtotal,
                            &.tax-rate,
                            &.order-total {
                                & th {
                                    padding: 8px;
                                    color: var(--black);
                                    font-size: responsive 16px 20px;
                                    line-height: 1.5;
                                }

                                & td {
                                    padding: 8px;
                                    font-size: responsive 16px 20px;
                                    line-height: 1.5;

                                    & span.woocommerce-Price-amount {
                                        font-size: responsive 16px 20px;
                                        line-height: 1.5;

                                        & .woocommerce-Price-currencySymbol {
                                            font-size: responsive 16px 20px;
                                            line-height: 1.5;
                                        }

                                        &:after {
                                            margin: 0 0 0 4px;
                                            font-size: responsive 8px 12px;
                                        }
                                    }
                                }
                            }

                            &.cart-subtotal {
                                & td {
                                    & span.woocommerce-Price-amount {
                                        &:after {
                                            content: '(ex BTW)';
                                        }
                                    }
                                }
                            }

                            &.order-total {
                                & td {
                                    & span.woocommerce-Price-amount {
                                        &:after {
                                            content: '(incl BTW)';
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                & .wc-proceed-to-checkout {
                    & .checkout-button {
                        display: inline-flex;
                        width: auto;
                        padding: 16px 40px;
                        color: var(--white);
                        font-size: responsive 12px 18px;
                        line-height: 1.22222;
                        background-color: var(--pink);
                        border-radius: 39px;
                        transition: background-color var(--standard);
                        will-change: background-color;

                        &:hover {
                            background-color: var(--blue);
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 667px) {
    .woocommerce-cart {
        & .woocommerce {
            & .woocommerce-cart-form {
                & table {
                    & tbody {
                        & tr:not(.cart_item) {
                            & td.actions {
                                margin-bottom: 0;

                                & .coupon {
                                    & input.input-text {
                                        min-width: 156px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            & table.shop_table_responsive {
                & tr {
                    &:nth-child(2n) td {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
