.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: var(--font);
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    outline: none !important;
    white-space: nowrap;

    &.small {
        padding: 8px 16px;
        font-size: responsive 10px 14px;
        line-height: 1.21428;
        border-radius: 16px;
    }

    &.medium {
        padding: 16px 40px;
        font-size: responsive 12px 16px;
        line-height: 1.22222;
        border-radius: 39px;
    }

    &.large {
        padding: 24px 56px;
        font-size: responsive 24px 36px;
        line-height: 1.22222;
        border-radius: 46px;
    }

    &.button-pink  {
        color: var(--white);
        background-color: var(--pink);
        transition: background-color var(--standard);
        will-change: background-color;

        &:hover {
            background-color: var(--blue);
        }
    }

    &.button-blue  {
        color: var(--white);
        background-color: var(--blue);
        transition: background-color var(--standard);
        will-change: background-color;

        &:hover {
            background-color: var(--pink);
        }
    }

    &.button-white-pink  {
        color: var(--grey-black);
        background-color: var(--white);
        transition: background-color var(--standard), color var(--standard);
        will-change: color, background-color;

        &:hover {
            color: var(--white);
            background-color: var(--pink);
        }
    }

    &.button-white-blue  {
        color: var(--grey-black);
        background-color: var(--white);
        transition: background-color var(--standard), color var(--standard);
        will-change: color, background-color;

        &:hover {
            color: var(--white);
            background-color: var(--blue);
        }
    }

    &.button-grey-pink  {
        color: var(--white);
        background-color: var(--grey-medium);
        transition: background-color var(--standard), color var(--standard);
        will-change: color, background-color;

        &:hover {
            color: var(--white);
            background-color: var(--pink);
        }
    }

    &.button-grey-blue  {
        color: var(--white);
        background-color: var(--grey-medium);
        transition: background-color var(--standard), color var(--standard);
        will-change: color, background-color;

        &:hover {
            color: var(--white);
            background-color: var(--blue);
        }
    }

    &.button-pink-white  {
        color: var(--white);
        background-color: var(--pink);
        transition: background-color var(--standard), color var(--standard);
        will-change: color, background-color;

        &:hover {
            color: var(--grey-black);
            background-color: var(--white);
        }
    }

    &.button-blue-white  {
        color: var(--white);
        background-color: var(--blue);
        transition: background-color var(--standard), color var(--standard);
        will-change: color, background-color;

        &:hover {
            color: var(--grey-black);
            background-color: var(--white);
        }
    }
}

@keyframes phone-icon {
  0% {
    transform: rotate(0deg) scaleX(-1);
  }
  2% {
    transform: rotate(-5deg) scaleX(-1);
  }
  4% {
    transform: rotate(5deg) scaleX(-1);
  }
  6% {
    transform: rotate(-5deg) scaleX(-1);
  }
  8% {
    transform: rotate(5deg) scaleX(-1);
  }
  10% {
    transform: rotate(-5deg) scaleX(-1);
  }
  12% {
    transform: rotate(5deg) scaleX(-1);
  }
  14% {
    transform: rotate(-5deg) scaleX(-1);
  }
  16% {
    transform: rotate(5deg) scaleX(-1);
  }
  18% {
    transform: rotate(-5deg) scaleX(-1);
  }
  20% {
    transform: rotate(5deg) scaleX(-1);
  }
  22% {
    transform: rotate(-5deg) scaleX(-1);
  }
  24% {
    transform: rotate(5deg) scaleX(-1);
  }
  26% {
    transform: rotate(-5deg) scaleX(-1);
  }
  28% {
    transform: rotate(5deg) scaleX(-1);
  }
  30% {
    transform: rotate(-5deg) scaleX(-1);
  }
  32% {
    transform: rotate(5deg) scaleX(-1);
  }
  34% {
    transform: rotate(-5deg) scaleX(-1);
  }
  36% {
    transform: rotate(5deg) scaleX(-1);
  }
  38% {
    transform: rotate(-5deg) scaleX(-1);
  }
  40% {
    transform: rotate(5deg) scaleX(-1);
  }
  42% {
    transform: rotate(-5deg) scaleX(-1);
  }
  44% {
    transform: rotate(5deg) scaleX(-1);
  }
  46% {
    transform: rotate(0deg) scaleX(-1);
  }
}

@-ms-keyframes phone-icon {
  0% {
    transform: rotate(0deg) scaleX(-1);
  }
  2% {
    transform: rotate(-5deg) scaleX(-1);
  }
  4% {
    transform: rotate(5deg) scaleX(-1);
  }
  6% {
    transform: rotate(-5deg) scaleX(-1);
  }
  8% {
    transform: rotate(5deg) scaleX(-1);
  }
  10% {
    transform: rotate(-5deg) scaleX(-1);
  }
  12% {
    transform: rotate(5deg) scaleX(-1);
  }
  14% {
    transform: rotate(-5deg) scaleX(-1);
  }
  16% {
    transform: rotate(5deg) scaleX(-1);
  }
  18% {
    transform: rotate(-5deg) scaleX(-1);
  }
  20% {
    transform: rotate(5deg) scaleX(-1);
  }
  22% {
    transform: rotate(-5deg) scaleX(-1);
  }
  24% {
    transform: rotate(5deg) scaleX(-1);
  }
  26% {
    transform: rotate(-5deg) scaleX(-1);
  }
  28% {
    transform: rotate(5deg) scaleX(-1);
  }
  30% {
    transform: rotate(-5deg) scaleX(-1);
  }
  32% {
    transform: rotate(5deg) scaleX(-1);
  }
  34% {
    transform: rotate(-5deg) scaleX(-1);
  }
  36% {
    transform: rotate(5deg) scaleX(-1);
  }
  38% {
    transform: rotate(-5deg) scaleX(-1);
  }
  40% {
    transform: rotate(5deg) scaleX(-1);
  }
  42% {
    transform: rotate(-5deg) scaleX(-1);
  }
  44% {
    transform: rotate(5deg) scaleX(-1);
  }
  46% {
    transform: rotate(0deg) scaleX(-1);
  }
}
