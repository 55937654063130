.ht-1-col {
    padding: 56px 0;

    &.narrow {
        & .ht-wrapper {
            max-width: 840px;
        }
    }

    &-content {
        & h1, & h2, & h3, & h4, & h5, & h6,
        & .h1, & .h2, & .h3, & .h4, & .h5, & .h6 {
            margin: 40px 0 32px;
            color: var(--grey-dark);

            &:first-child {
                margin: 0 0 32px;
            }
        }

        & p.intro {
            margin: 0 0 56px;
            font-size: responsive 28px 36px;
            line-height: 1.22222;
        }

        & a {
            color: var(--blue);
            transition: color var(--standard);
            will-change: color;

            &:hover {
                color: var(--pink);
            }
        }
    }

    .pewc-theme-element {
        display: none;
    }
}

@media only screen and (max-width: 1024px) {
    .ht-1-col {
        & .ht-wrapper {
            max-width: 812px;
        }
        
        &.narrow {
            & .ht-wrapper {
                max-width: 812px;
            }
        }
    }
}

@media only screen and (max-width: 876px) {
    .ht-1-col {
        & .ht-wrapper {
            max-width: 768px;
        }

        &.narrow {
            & .ht-wrapper {
                max-width: 768px;
            }
        }
    }
}

@media only screen and (max-width: 812px) {
    .ht-1-col {
        & .ht-wrapper {
            max-width: 736px;
        }

        &.narrow {
            & .ht-wrapper {
                max-width: 736px;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .ht-1-col {
        & .ht-wrapper {
            max-width: 704px;
        }

        &.narrow {
            & .ht-wrapper {
                max-width: 704px;
            }
        }
    }
}

@media only screen and (max-width: 736px) {
    .ht-1-col {
        & .ht-wrapper {
            max-width: calc(100vw - 48px);
        }

        &.narrow {
            & .ht-wrapper {
                max-width: calc(100vw - 48px);
            }
        }
    }
}

@media only screen and (max-width: 568px) {
    .ht-1-col {
        padding: 24px 0;
    }
}

@media only screen and (max-width: 414px) {
    .ht-1-col {
        & .ht-wrapper {
            max-width: calc(100vw - 32px);
        }

        &.narrow {
            & .ht-wrapper {
                max-width: calc(100vw - 32px);
            }
        }
    }
}
