.ht-search-cities {
    padding: 56px 0;
    
    & .ht-search-cities-container {
        padding: 80px 110px;
        background-color: var(--blue);
        border-radius: 8px;

        & h2 {
            margin: 0 0 24px;
            color: var(--white);
            text-align: center;
        }

        & p.ht-search-cities-subtitle {
            color: var(--white);
            text-align: center;
        }

        & input#searchCities {
            display: block;
            width: 100%;
            max-width: 730px;
            padding: 22px 40px;
            margin: 44px auto 0;
            color: var(--grey-dark);
            font-weight: 500;
            line-height: 1.20833;
            border: none;
            border-radius: 42px;
            outline: none;

            &::-webkit-input-placeholder,
            &::-moz-placeholder,
            &:-ms-input-placeholder,
            &:-moz-placeholder {
                color: var(--pink);
                font-weight: 700;
            }
        }

        & table {
            width: 100%;
            margin: 72px auto 0;

            & tr {
                &:nth-child(n+6) {
                    display: none;
                }

                &:last-child {
                    & td {
                        border: none;
                    }
                }
                
                & td {
                    padding: 16px 0;
                    border-bottom: 1px solid var(--white-20);
                    vertical-align: top;

                    &:first-child {
                        & a {
                            display: inline-flex;
                            align-items: center;
                            justify-content: flex-start;
                            color: var(--white);
                            font-family: var(--font);
                            font-size: responsive 16px 24px;
                            font-weight: 500;
                            line-height: 1.5;
                            text-align: left;
                            text-decoration: none;
                            transition: color var(--standard);
                            will-change: color;
                            white-space: nowrap;

                            &:hover {
                                color: var(--pink);
                            }
                        }
                    }

                    &:last-child {
                        text-align: right;

                        & .tag {
                            display: inline-flex;
                            align-items: center;
                            justify-content: center;
                            margin: 0 0 0 8px;
                            padding: 0 10px;
                            color: var(--blue);
                            font-family: var(--font);
                            font-weight: 500;
                            font-size: responsive 12px 16px;
                            line-height: 1.5;
                            background-color: var(--white);
                            border-radius: 12px;
                        }
                    }
                }
            }
        }

        & .ht-search-cities-more {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin: 48px 0 0;
        }
    }
}

@media only screen and (max-width: 812px) {
    .ht-search-cities {            
        & .ht-search-cities-container {
            padding: 40px 56px;
        }
    }
}

@media only screen and (max-width: 568px) {
    .ht-search-cities {
        padding: 24px 0;

        & .ht-search-cities-container {
            padding: 24px 32px;
        }
    }
}

@media only screen and (max-width: 375px) {
    .ht-search-cities {
        & .ht-search-cities-container {
            padding: 16px;
        }
    }
}
