.ht-maps-booking {
    padding: 56px 0;

    & .ht-maps-booking-box {
        position: relative;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 56px;
        background-color: var(--grey-dark);
        border-radius: 8px 8px 0 0;

        & .ht-maps-booking-content {
            & h2 {
                margin: 0 0 24px;
                color: var(--white);
            }
    
            & p {
                margin: 0;
                color: var(--white);
            }
        }

        & .button {
            margin: 0 0 0 56px;
        }
    }

    & .embed-holder {
        position: relative;
        z-index: 1;
        width: 100%;
        padding-top: 58.59375%;
        border-radius: 0 0 8px 8px;
        overflow: hidden;

        & .map-overlay {
            position: absolute;
            z-index: 2;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            color: var(--white);
            background-color: var(--black-40);
            cursor: pointer;
            transition: color var(--standard);
            will-change: color;

            & i,
            & svg {
                font-size: 32px;
            }

            &:hover {
                color: var(--pink);
            }
        }

        & small {
            display:none;
        }

        & iframe {
            position: absolute;
            top: -54px;
            left: 0;
            width:100%;
            height: calc(100% + 54px);
            border: none;
        }
    }
}

@media only screen and (max-width: 736px) {
    .ht-maps-booking {
        & .ht-maps-booking-box {
            flex-direction: column;
            align-items: flex-start;
            
            & .button {
                margin: 24px 0 0;
            }
        }
    }
}

@media only screen and (max-width: 568px) {
    .ht-maps-booking {
        padding: 24px 0;

        & .ht-maps-booking-box {
            padding: 24px;
        }
    }
}
