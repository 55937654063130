.ht-pricing {
    padding: 56px 0;

    & .ht-wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    &-left,
    &-right {
        position: relative;
        width: calc(50% - 20px);
        background-color: var(--blue);
        border-radius: 8px;
        overflow: hidden;

        & .ht-video-background {
            position: absolute;
            z-index: 1;
            top: 50%;
            left: 50%;
            min-width: 100%;
            min-height: 100%;
            opacity: 1;
            transform: translate(-50%, -50%);
            transition: opacity var(--standard);
        }

        &:after {
            content: '';
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--black-50);
        }

        &.inactive {
            &:after {
                position: absolute;
                z-index: 4;
                width: 100%;
                height: 100%;
                background-color: var(--blue-80);
                border-radius: 8px;
            }

            & .ht-video-background {
                opacity: 0;
            }
        }

        & .ht-pricing-content-step-1 {
            position: relative;
            z-index: 3;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
            width: 100%;
            height: 100%;
            min-height: 450px;
            padding: 56px;

            & h3,
            & p {
                color: var(--white);
            }

            & a.button {
                margin: auto 0 0;
                cursor: pointer;
            }
        }

        & .ht-pricing-content-step-2 {
            position: relative;
            z-index: 3;
            display: none;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
            min-height: 450px;
            padding: 56px;
            background-color: var(--blue);

            & h3,
            & p {
                color: var(--white);
            }

            & ul.prices {
                width: 100%;
                margin: 24px 0 40px;
                padding: 0;
                list-style: none;

                & li {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    padding: 16px 0;
                    margin: 0;
                    border-bottom: 1px solid var(--white-20);

                    &:last-child {
                        border: none;
                    }

                    & span.product {
                        width: 60%;
                        margin: 0;
                        color: var(--white);
                        text-align: left;
                    }

                    & span.price {
                        width: 40%;
                        margin: 0;
                        color: var(--white);
                        text-align: right;
                    }
                }
            }

            & .ht-button-wrapper {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin: 56px 0 0;

                & a.button {
                    margin: 0;
                    cursor: pointer;
                }

                & span.button-spacer {
                    margin: 0 0 0 24px;
                    color: var(--white);
                    font-size: responsive 12px 18px;
                    line-height: 1.5;
                }

                & a.link {
                    margin: 0;
                    color: var(--white);
                    font-size: responsive 12px 18px;
                    line-height: 1.5;
                    font-weight: 500;
                    cursor: pointer;
                    transition: color var(--standard);
                    will-change: color;

                    &:hover {
                        color: var(--pink);
                    }
                }
            }

            & .ht-no-tarrifs {
                padding: 8px 16px;
                margin: 16px 0 32px;
                color: var(--white);
                background-color: var(--grey-dark);
                border-radius: 4px;
            }
        }
    }
}

@media only screen and (max-width: 1280px) {
    .ht-pricing {
        &-left,
        &-right {
            & .ht-pricing-content-step-2 {
                & .ht-button-wrapper {
                    flex-direction: column;
                    align-items: center;

                    & a.button {
                        padding: 16px 32px;
                    }
    
                    & span.button-spacer {
                        margin: 16px 0;
                        color: var(--white);
                        font-size: responsive 12px 18px;
                        line-height: 1.5;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 812px) {
    .ht-pricing {
        & .ht-wrapper {
            flex-direction: column;
        }
    
        &-left,
        &-right {
            width: 100%;

            & .ht-pricing-content-step-1,
            & .ht-pricing-content-step-2 {
                padding: 24px;
            }
        }

        &-left {
            margin: 0 0 32px;
        }
    }
}

@media only screen and (max-width: 568px) {
    .ht-pricing {
        padding: 24px 0;
    }
}
