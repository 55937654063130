.ht-header-visual {
    &-maps {
        width: 100vw;

        & .ht-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }

        & iframe {
            position: absolute;
            z-index: 1;
            top: 50%;
            left: 50%;
            width: 100vw;
            height: calc(100vw / 1.33333);
            transform: translate(-50%, -50%);
        }

        &:after {
            content: '';
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--black-50);
        }
    }

    &-content {
        max-height: 774px;
        min-height: 320px;
        padding: 18.28125% 0 15.3125%;
    }

    & h1 {
        margin: 0;
        color: var(--white);
        text-align: center;
        text-transform: uppercase;
    }

    & .ht-header-visual-subtitle {
        margin: 0 auto;
        color: var(--white);
        text-align: center;
    }
}

@media only screen and (max-width: 414px) {
    .ht-header-visual {
        &-maps {
            width: 100vw;

            & iframe {
                position: absolute;
                z-index: 1;
                top: 50%;
                left: 50%;
                width: 600px;
                height: 450px;
                transform: translate(-50%, -50%);
            }
        }
    }
}
