header,
footer,
section {
    position: relative;
    width: 100vw;
    margin: 0 auto;

    & .ht-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        max-width: 1280px;
        margin: 0 auto;
    }
}

@media only screen and (max-width: 1366px) {
    header,
    footer,
    section {
        & .ht-wrapper {
            max-width: 1180px;
        }
    }
}

@media only screen and (max-width: 1280px) {
    header,
    footer,
    section {
        & .ht-wrapper {
            max-width: 1024px;
        }
    }
}

@media only screen and (max-width: 1180px) {
    header,
    footer,
    section {
        & .ht-wrapper {
            max-width: 960px;
        }
    }
}

@media only screen and (max-width: 1024px) {
    header,
    footer,
    section {
        & .ht-wrapper {
            max-width: 812px;
        }
    }
}

@media only screen and (max-width: 876px) {
    header,
    footer,
    section {
        & .ht-wrapper {
            max-width: 768px;
        }
    }
}

@media only screen and (max-width: 812px) {
    header,
    footer,
    section {
        & .ht-wrapper {
            max-width: 736px;
        }
    }
}

@media only screen and (max-width: 768px) {
    header,
    footer,
    section {
        & .ht-wrapper {
            max-width: 704px;
        }
    }
}

@media only screen and (max-width: 736px) {
    header,
    footer,
    section {
        & .ht-wrapper {
            max-width: calc(100vw - 48px);
        }
    }
}

@media only screen and (max-width: 414px) {
    header,
    footer,
    section {
        & .ht-wrapper {
            max-width: calc(100vw - 32px);
        }
    }
}
