footer {
    position: relative;
    padding: 98px 80px 60px;
    margin: 150px 0 0;
    background-color: var(--grey-dark);

    & .ht-logo {
        position: absolute;
        top: -160px;
        left: 50%;
        transform: translateX(-50%);
    }

    & .ht-back-to-top {
        position: absolute;
        right: 0;
        top: -248px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 56px;
        height: 56px;
        background-color: var(--grey-dark);
        border-radius: 28px;
        transition: background-color var(--standard);
        will-change: background-color;

        & i,
        & svg {
            color: var(--white);
            font-family: var(--icons);
            font-weight: 400;
            font-size: responsive 24px 24px;
            line-height: 1;
        }

        &:hover {
            background-color: var(--pink);
        }
    }

    & .ht-footer-content {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        & .ht-footer-copyright {
            color: var(--white);
            font-family: var(--font);
            font-weight: 400;
            font-size: responsive 14px 18px;
            line-height: 1.16667;
        }

        & .ht-footer-links-wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            & a {
                margin: 0 0 0 36px;
                color: var(--white);
                font-family: var(--font);
                font-weight: 400;
                font-size: responsive 14px 18px;
                line-height: 1.16667;
                text-decoration: none;
                transition: color var(--standard);
                will-change: color;

                &:hover {
                    color: var(--pink);
                }
            }
        }

        & .ht-footer-social-wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin: 0 0 0 auto;

            & a {
                margin: 0 0 0 32px;
                color: var(--white);
                font-family: var(--font);
                font-weight: 400;
                font-size: responsive 17px 21px;
                line-height: 1;
                text-decoration: none;
                transition: color var(--standard);
                will-change: color;

                &:hover {
                    color: var(--pink);
                }
            }
        }
    }
}

@media only screen and (max-width: 812px) {
    footer {
        & .ht-footer-content {
            flex-direction: column;

            & .ht-footer-copyright {
                margin: 0 auto 16px;
            }

            & .ht-footer-links-wrapper {
                flex-direction: column;
                margin: 0 auto 16px;

                & a {
                    margin: 0 auto 16px;
                }
            }

            & .ht-footer-social-wrapper {
                margin: 0 auto 16px;

                & a {
                    margin: 0 8px;
                }
            }
        }
    }
}
