h1, .h1 {
    font-size: responsive 28px 36px;
    line-height: 1.17142;

    &.big {
        font-size: responsive 40px 70px;
        line-height: 1.16667;
    }

    &.xl {
        font-size: responsive 48px 74px;
        line-height: 1.21875;
    }
}

h2, .h2 {
    font-size: responsive 24px 32px;
    line-height: 1.16667;

    &.big {
        font-size: responsive 40px 40px;
        line-height: 1.22222;
    }

    &.xl {
        font-size: responsive 48px 44px;
        line-height: 1.21875;
    }
}

h3, .h3 {
    font-size: responsive 20px 30px;
    line-height: 1.22222;

    &.large {
        font-size: responsive 28px 36px;
        line-height: 1.16667;
    }
}

h4, .h4 {
    font-size: responsive 12px 24px;
    line-height: 1.15625;
}

h5, .h5 {
    font-size: responsive 10px 16px;
    line-height: 1.5;	
}

h6, .h6 {
    font-size: responsive 10px 16px;
    line-height: 1.5;	
}


p,
input,
textarea,
select,
label,
td,
th,
blockquote,
ul,
ol,
span {
    font-size: responsive 14px 21px;
    line-height: 1.5;

    &.small {
        font-size: responsive 12px 18px;
        line-height: 1.5;
    }

    &.large {
        font-size: responsive 20px 32px;
        line-height: 1.15625;
    }
}
