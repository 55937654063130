.woocommerce {
    &.single-product {
        & h1 {
            color: var(--grey-dark);
            font-size: responsive 28px 48px;
            line-height: 1.16667;
        }

        & div.product {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-wrap: wrap;

            & .ht-product-map {
                position: relative;
                width: calc(50% - 20px);
                padding-top: 720px;

                & iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border: 0px;
                }
            }

            & .summary {
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                justify-content: flex-start;
                width: calc(50% - 20px);
                float: none;

                & .woocommerce-product-gallery {
                    width: 23.87095%;
                    max-width: 148px;
                    max-height: 148px;
                    margin: 0 0 0 24px;
                    order: 2;
                    float: none;

                    & > figure {
                        & > div {
                            & > a {
                                & > img {
                                    border-radius: 8px;
                                }
                            }
                        }
                    }
                }

                & h1.product_title {
                    width: calc(76.12903% - 24px);
                    order: 1;
                    margin: 0 0 8px;
                    word-break: break-word;
                }

                & p.price,
                & span.price {
                    margin: 0 0 16px;
                    color: var(--grey-medium);
                    order: 3;

                    &.pewc-main-price {
                        & .woocommerce-Price-amount {
                            color: var(--blue);
                            font-size: responsive 24px 32px;
                            line-height: 1.15625;

                            & .woocommerce-Price-currencySymbol {
                                color: var(--blue);
                                font-size: responsive 24px 32px;
                                line-height: 1.15625;
                            }
                        }

                        &:after {
                            content: "(ex BTW)";
                            margin: 0 0 0 4px;
                            font-size: responsive 8px 12px;
                        }
                    }
                }

                & .woocommerce-product-details__short-description {
                    margin: 0;
                    border: 1px solid var(--grey-border);
                    border-bottom: 0;
                    border-radius: 8px 8px 0 0;
                    order: 4;

                    & p {
                        margin: 0;

                        &:first-child {
                            padding: 16px 24px 16px;
                            color: var(--grey-dark);
                            border-bottom: 1px solid var(--grey-border);
                        }

                        &:last-child {
                            padding: 16px 24px;
                            margin: 0;

                            & strong {
                                display: block;
                                padding: 8px 16px;
                                color: var(--pink);
                                font-size: responsive 14px 18px;
                                line-height: 1.5;
                                background-color: var(--pink-5);
                                border-radius: 8px;
                            }
                        }
                    }
                }

                & form.cart {
                    order: 5;
                    margin-bottom: 0;
                }

                & .product_meta {
                    order: 6;
                }
            }

            & .pewc-group-wrap {
                border-left: 1px solid var(--grey-border);
                border-right: 1px solid var(--grey-border);
                padding: 0 24px;
                font-size: responsive 12px 16px;

                & .pewc-group-heading-wrapper {
                    & h3 {
                        padding: 16px 0 0;
                        margin: 0 0 8px;
                        color: var(--grey-dark);
                        font-size: responsive 14px 24px;
                        line-height: 1.5;
                    }
                }

                & .pewc-group-content-wrapper {
                    & .pewc-group-description {
                        margin: 0 0 8px;
                        font-size: responsive 12px 16px;
                    }

                    & ul.pewc-product-extra-groups {
                        padding-left: 0;

                        & li.pewc-group {
                            margin: 0;
                            padding: 0 0 16px;

                            &.pewc-group-calculation.pewc-item-calculation {
                                display: none;
                            }

                            &.pewc-group-checkbox {
                                & label {
                                    display: block;
                                }
                            }

                            & .pewc-item-field-wrapper {
                                & .pewc-field-label,
                                & label,
                                & .pewc-field-price,
                                & .woocommerce-Price-currencySymbol,
                                & .pewc-description {
                                    position: relative;
                                    margin: 0;
                                    font-size: responsive 12px 16px;

                                    & > a {
                                        color: var(--blue);
                                        transition: color var(--standard);

                                        &:hover {
                                            color: var(--pink);
                                        }
                                    }
                                    & .pewc-per-unit-label {
                                        display: none;
                                    }
                                }

                                & .pewc-field-price {
                                    color: var(--grey-medium);
                                    font-size: responsive 16px 24px;
                                    line-height: 1.5;
                                    font-weight: 500;

                                    & .woocommerce-Price-currencySymbol {
                                        color: var(--grey-medium);
                                        font-size: responsive 16px 24px;
                                        line-height: 1.5;
                                        font-weight: 500;
                                    }

                                    &:after {
                                        content: "(ex BTW)";
                                        margin: 0 0 0 4px;
                                        font-size: responsive 12px 16px;
                                        line-height: 1.5;
                                    }

                                    & span.pewc-per-unit-label {
                                        display: none;
                                    }
                                }

                                & .pewc-field-label,
                                & label {
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    margin: 0 0 8px;
                                    padding: 6px 24px 6px 66px;
                                    height: 48px;
                                    color: var(--grey-medium);
                                    font-size: responsive 16px 24px;
                                    line-height: 1.5;
                                    font-weight: 500;
                                    background-color: var(--grey-light-20);
                                    border-radius: 8px;

                                    & > span {
                                        margin: 0;
                                    }

                                    &:before {
                                        content: "\e835";
                                        position: absolute;
                                        z-index: 1;
                                        left: 20px;
                                        top: 6px;
                                        width: 34px;
                                        height: 34px;
                                        color: var(--grey-border);
                                        font-family: "Material Icons";
                                        font-size: 36px;
                                        font-style: normal;
                                        font-weight: normal;
                                        line-height: 1;
                                        text-transform: none;
                                        letter-spacing: normal;
                                        word-wrap: normal;
                                        white-space: nowrap;
                                        vertical-align: -6px;
                                        transition: all 0.25s ease-in-out;
                                        direction: ltr;
                                        /* Support for all WebKit browsers. */
                                        -webkit-font-smoothing: antialiased;
                                        /* Support for Safari and Chrome. */
                                        text-rendering: optimizeLegibility;
                                        /* Support for Firefox. */
                                        -moz-osx-font-smoothing: grayscale;
                                        /* Support for IE. */
                                        font-feature-settings: "liga";
                                    }

                                    &.checked:before {
                                        content: "\e834";
                                        color: var(--pink);
                                    }
                                }

                                & .pewc-form-field {
                                    position: absolute;
                                    display: inline-block;
                                    width: 34px;
                                    height: 34px;
                                    padding: 0;
                                    margin: 0;
                                    cursor: pointer;
                                    visibility: hidden;
                                    outline: none;
                                    opacity: 1;
                                    -webkit-appearance: none !important;
                                    -moz-appearance: none !important;
                                    appearance: none !important;
                                    outline: none;
                                }
                            }
                        }
                    }
                }
            }

            & .product-under {
                & .product-under-container {
                    & .ht-1-col {
                        width: 100%;
                    }
                }
            }
        }

        & .ui-datepicker {
            max-width: 270px;
            padding: 16px;
            margin: 4px 0 0;
            border: 2px solid var(--grey-border);
            border-radius: 8px;
            background-color: var(--white);
            overflow: hidden;

            & .ui-datepicker-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 8px;
                background-color: var(--white);

                & a {
                    margin: 0;
                    padding: 0;

                    &.ui-datepicker-prev {
                        width: 14px;
                        height: 16px;
                        margin: 0;
                        order: 1;

                        &:before {
                            width: 14px;
                            height: 16px;
                            padding: 0;
                            color: var(--grey-dark);
                            font-size: responsive 12px 16px;
                            line-height: 1;
                        }
                    }

                    &.ui-datepicker-next {
                        width: 14px;
                        height: 16px;
                        margin: 0;
                        order: 3;

                        &:before {
                            width: 14px;
                            height: 16px;
                            padding: 0;
                            color: var(--grey-dark);
                            font-size: responsive 12px 16px;
                            line-height: 1;
                        }
                    }
                }

                & .ui-datepicker-title {
                    order: 2;
                    padding: 4px 0 0;

                    & span {
                        color: var(--grey-dark);
                        font-family: var(--font);
                        font-size: responsive 14px 18px;
                        line-height: 1.11111;
                        font-weight: 700;
                    }
                }
            }

            & table.ui-datepicker-calendar {
                & thead {
                    & th {
                        width: calc(100% / 7);
                        height: 24px;
                        padding: 0;
                        background-color: var(--white);
                        border-bottom: 1px solid var(--grey-border);

                        & span {
                            font-size: 0;
                            line-height: 0;

                            &:after {
                                content: "";
                                color: var(--grey-dark);
                                font-family: var(--font);
                                font-size: responsive 10px 12px;
                                line-height: 1.33333;
                                font-weight: 500;
                            }
                        }

                        &:nth-child(1) {
                            & span {
                                &:after {
                                    content: "Zo";
                                }
                            }
                        }

                        &:nth-child(2) {
                            & span {
                                &:after {
                                    content: "Ma";
                                }
                            }
                        }

                        &:nth-child(3) {
                            & span {
                                &:after {
                                    content: "Di";
                                }
                            }
                        }

                        &:nth-child(4) {
                            & span {
                                &:after {
                                    content: "Wo";
                                }
                            }
                        }

                        &:nth-child(5) {
                            & span {
                                &:after {
                                    content: "Do";
                                }
                            }
                        }

                        &:nth-child(6) {
                            & span {
                                &:after {
                                    content: "Vr";
                                }
                            }
                        }

                        &:nth-child(7) {
                            & span {
                                &:after {
                                    content: "Za";
                                }
                            }
                        }
                    }
                }

                & tbody {
                    & tr {
                        & td {
                            width: calc(100% / 7);
                            height: 24px;
                            margin: 0;
                            padding: 0;
                            color: var(--grey-dark);
                            font-family: var(--font);
                            font-size: responsive 10px 12px;
                            line-height: 1.33333;
                            background-color: var(--grey-medium-20);
                            border: 0;
                            border-radius: 3px;
                            text-align: center;
                            transition: background var(--standard),
                                color var(--standard);

                            &:hover {
                                color: var(--white);
                                background-color: var(--pink);

                                & a {
                                    color: var(--white);
                                    background-color: var(--pink);
                                }
                            }

                            &.ui-datepicker-unselectable {
                                font-family: var(--font);
                                font-size: responsive 10px 12px;
                                line-height: 1.33333;
                                background: var(--white);
                            }

                            & .ui-state-default {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                height: 34px;
                                margin: 0;
                                padding: 0;
                                font-family: var(--font);
                                font-size: responsive 10px 12px;
                                line-height: 1.33333;
                                border-radius: 3px;
                                transition: background var(--standard),
                                    color var(--standard);

                                & a {
                                    display: block;
                                    height: 34px;
                                    border-radius: 3px;
                                    transition: background var(--standard),
                                        color var(--standard);
                                }
                            }
                        }
                    }
                }
            }
        }

        & .product-under {
            & .product-under-container {
                display: flex;
                align-items: stretch;
                justify-content: space-between;
                flex-direction: column;

                & .ht-2-col-left {
                    display: none;
                }

                & .ht-2-col-right {
                    width: 100%;
                }

                & .ht-2-col-right {
                    position: relative;
                    padding-top: 75%;
                }

                & iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border: 0px;
                }
            }
        }

        & form.cart {
            margin-bottom: 0;

            & button.button.alt {
                padding: 24px 56px;
                color: var(--white);
                font-size: responsive 24px 36px;
                line-height: 1.22222;
                background-color: var(--pink);
                border-radius: 46px;
                transition: background-color var(--standard);
                will-change: background-color;

                &:hover {
                    background-color: var(--blue);
                }
            }

            &.booking-form {
                & .booking-form-wrapper {
                    & .booking-form-inner {
                        border-left: 1px solid var(--grey-border);
                        border-right: 1px solid var(--grey-border);
                        padding: 0 24px;

                        & .booking-fields {
                            & #bfws-calendar-wrapper {
                                & table {
                                    width: 100%;
                                    margin: 0;
                                    border: 0;

                                    & tbody {
                                        & tr {
                                            & td {
                                                &.bfws-date-field-wrapper {
                                                    border: 0;

                                                    & .gg-calendar-dates {
                                                        display: none;
                                                    }

                                                    & .date-field-block {
                                                        position: relative;
                                                        width: calc(100% - 8px);
                                                        background-color: var(--pink);
                                                        border: 2px solid
                                                            var(--pink);
                                                        border-radius: 8px;
                                                        transition: background-color
                                                                var(--standard),
                                                            border
                                                                var(--standard);

                                                        &:after {
                                                            content: "\f078";
                                                            position: absolute;
                                                            right: 24px;
                                                            top: 11px;
                                                            color: var(--white);
                                                            font-family: var(--icons);
                                                            font-size: responsive
                                                                16px 24px;
                                                            line-height: 1;
                                                            pointer-events: none;
                                                            transform: rotate(0deg);
                                                            transition: color
                                                                var(--standard),
                                                                transform
                                                                var(--standard);
                                                        }

                                                        &.hasFocus {
                                                            background-color: var(--white);
                                                            border: 2px solid
                                                                var(--grey-border);

                                                            & .bfws-start-date,
                                                            & .bfws-end-date {
                                                                color: var(--grey-medium);

                                                                &::placeholder,
                                                                &::placeholder,
                                                                &:-ms-input-placeholder,
                                                                &:-ms-input-placeholder,
                                                                &::-ms-input-placeholder,
                                                                &::-ms-input-placeholder {
                                                                    color: var(--grey-medium);
                                                                }
                                                            }

                                                            &:after {
                                                                color: var(--grey-medium);
                                                                transform: rotate(180deg);
                                                            }
                                                        }
                                                    }

                                                    &:first-child {
                                                        & .date-field-block {
                                                            margin: 0 8px 0 0;
                                                        }
                                                    }

                                                    &:last-child {
                                                        & .date-field-block {
                                                            margin: 0 0 0 8px;
                                                        }
                                                    }

                                                    & .bfws-start-date,
                                                    & .bfws-end-date {
                                                        padding: 14px 40px 13px
                                                            16px;
                                                        color: var(--white);
                                                        font-size: responsive
                                                            14px 20px;
                                                        line-height: 1.16667;
                                                        opacity: 1;
                                                        margin: 0;
                                                        border-radius: 8px;
                                                        background-color: transparent;
                                                        outline: none;
                                                        white-space: nowrap;
                                                        overflow: hidden;
                                                        text-overflow: ellipsis;
                                                        cursor: pointer;
                                                        transition: color
                                                            var(--standard);

                                                        &::placeholder,
                                                        &::placeholder,
                                                        &:-ms-input-placeholder,
                                                        &:-ms-input-placeholder,
                                                        &::-ms-input-placeholder,
                                                        &::-ms-input-placeholder {
                                                            color: var(--white);
                                                            font-size: responsive
                                                                14px 20px;
                                                            line-height: 1.16667;
                                                            opacity: 1;
                                                            transition: color
                                                                var(--standard);
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    & tfoot {
                                        & tr {
                                            padding-top: 16px;

                                            & td {
                                                &.filler {
                                                    padding: 8px 0;
                                                    border: 0;
                                                }

                                                &.booking-cost-header {
                                                    padding: 14px 24px 13px;
                                                    color: var(--grey-medium);
                                                    font-size: responsive 14px
                                                        20px;
                                                    line-height: 1.16667;
                                                    background-color: var(--grey-border);
                                                    border: 0;
                                                    border-radius: 8px 0 0 8px;
                                                }

                                                &.booking-cost-data {
                                                    padding: 14px 24px 13px;
                                                    color: var(--grey-medium);
                                                    font-size: responsive 14px
                                                        20px;
                                                    line-height: 1.16667;
                                                    background-color: var(--grey-input);
                                                    border: 0;
                                                    border-radius: 0 8px 8px 0;
                                                    text-align: left;
                                                }
                                            }
                                        }
                                    }
                                }

                                & .booking-cost {
                                    position: relative;

                                    &:after {
                                        content: "(ex BTW)";
                                        margin: 0 0 0 4px;
                                        font-size: responsive 8px 12px;
                                    }
                                }
                            }
                        }
                    }

                    & .pewc-product-extra-groups-wrap {
                        & .pewc-total-field-wrapper {
                            display: block !important;
                            padding: 16px 24px 24px;
                            margin: 0;
                            background-color: var(--grey-border);
                            border: 1px solid var(--grey-border);
                            border-top: 0;
                            border-radius: 0 0 8px 8px;
                            transition: background-color var(--standard),
                                border var(--standard);

                            & > p {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                width: 100%;
                                padding: 8px 0;
                                margin: 0;
                                border-bottom: 1px solid var(--grey-input);
                                transition: border var(--standard);

                                &:first-child {
                                    padding: 0 0 8px;
                                }

                                &:last-child {
                                    padding: 16px 0 0;
                                }

                                & > span {
                                    margin: 0;
                                }

                                & .pewc-total-field {
                                    & .woocommerce-Price-amount {
                                        color: var(--grey-input);
                                        transition: color var(--standard);

                                        & .woocommerce-Price-currencySymbol {
                                            position: relative;
                                            color: var(--grey-input);
                                            transition: color var(--standard);
                                        }

                                        &:after {
                                            content: "(ex BTW)";
                                            margin: 0 0 0 4px;
                                            font-size: responsive 12px 16px;
                                            line-height: 1.5;
                                        }
                                    }
                                }

                                & #pewc-per-product-label,
                                & #pewc-options-total-label,
                                & #pewc-grand-total-label {
                                    color: var(--grey-input);
                                    font-size: responsive 18px 24px;
                                    line-height: 1.5;
                                    transition: color var(--standard);
                                }

                                & #pewc-per-product-label,
                                & #pewc-options-total-label {
                                    color: var(--grey-input);
                                    font-size: responsive 18px 24px;
                                    line-height: 1.5;
                                    transition: color var(--standard);
                                }

                                & .pewc-total-field {
                                    & .woocommerce-Price-amount {
                                        color: var(--grey-input);
                                        font-size: responsive 18px 24px;
                                        line-height: 1.5;
                                        transition: color var(--standard);

                                        & .woocommerce-Price-currencySymbol {
                                            color: var(--grey-input);
                                            font-size: responsive 18px 24px;
                                            line-height: 1.5;
                                            transition: color var(--standard);
                                        }
                                    }
                                }

                                &:last-child {
                                    border: 0;

                                    & #pewc-grand-total-label {
                                        color: var(--grey-input);
                                        font-size: responsive 24px 32px;
                                        line-height: 1.15625;
                                        transition: color var(--standard);
                                    }

                                    & .pewc-total-field {
                                        & .woocommerce-Price-amount {
                                            color: var(--grey-input);
                                            font-size: responsive 24px 32px;
                                            line-height: 1.15625;
                                            transition: color var(--standard);

                                            &
                                                .woocommerce-Price-currencySymbol {
                                                color: var(--grey-input);
                                                font-size: responsive 24px 32px;
                                                line-height: 1.15625;
                                                transition: color
                                                    var(--standard);
                                            }
                                        }
                                    }
                                }
                            }

                            &.activated {
                                background-color: var(--blue);

                                & > p {
                                    border-bottom: 1px solid var(--white);

                                    & .pewc-total-field {
                                        & .woocommerce-Price-amount {
                                            color: var(--white);

                                            &
                                                .woocommerce-Price-currencySymbol {
                                                color: var(--white);

                                                &:after {
                                                    color: var(--white);
                                                }
                                            }
                                        }
                                    }

                                    & #pewc-per-product-label,
                                    & #pewc-options-total-label,
                                    & #pewc-grand-total-label {
                                        color: var(--white);
                                    }

                                    & #pewc-per-product-label,
                                    & #pewc-options-total-label {
                                        color: var(--white);
                                    }

                                    & .pewc-total-field {
                                        & .woocommerce-Price-amount {
                                            color: var(--white);

                                            &
                                                .woocommerce-Price-currencySymbol {
                                                color: var(--white);
                                            }
                                        }
                                    }

                                    &:last-child {
                                        border: 0;

                                        & #pewc-grand-total-label {
                                            color: var(--white);
                                        }

                                        & .pewc-total-field {
                                            & .woocommerce-Price-amount {
                                                color: var(--white);

                                                &
                                                    .woocommerce-Price-currencySymbol {
                                                    color: var(--white);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        & ~ button.single_add_to_cart_button {
                            margin-top: 40px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 812px) {
    .woocommerce {
        &.single-product {
            & div.product {
                flex-direction: column;

                & .ht-product-map {
                    width: 100%;
                    padding-top: 480px;
                    order: 2;
                }

                & .summary.entry-summary {
                    width: 100%;
                    order: 1;
                }

                & .product-under {
                    order: 3;
                }
            }

            &.bfwc-view-double {
                & .ui-datepicker {
                    left: 16px !important;
                    max-width: calc(100vw - 32px) !important;

                    & table.ui-datepicker-calendar {
                        & thead {
                            & tr {
                                & th {
                                    width: calc(100% / 7);

                                    & span {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        width: 100%;
                                        height: 100%;
                                        margin: 0;
                                        text-align: center;
                                    }
                                }
                            }
                        }

                        & tbody {
                            & tr {
                                & td {
                                    width: calc(100% / 7);

                                    & a.ui-state-default,
                                    & span.ui-state-default {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        width: 100%;
                                        text-align: center;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 480px) {
    .woocommerce {
        &.single-product {
            & div.product {
                & .summary {
                    display: flex;
                    flex-wrap: wrap;
                    width: calc(50% - 20px);
                    float: none;

                    & .woocommerce-product-gallery {
                        width: auto;
                        max-width: 100%;
                        max-height: none;
                        margin: 0 0 24px;
                        order: 3;
                    }

                    & h1.product_title {
                        width: 100%;
                        order: 1;
                    }

                    & p.price,
                    & span.price {
                        width: 100%;
                        order: 2;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 414px) {
    .woocommerce.single-product {
        & div.product {
            & .summary {
                & .woocommerce-product-details__short-description {
                    & p {
                        &:first-child {
                            padding: 24px 16px 16px;
                        }

                        &:last-child {
                            padding: 24px 16px 32px;
                        }
                    }
                }
            }

            & .pewc-group-wrap {
                padding: 0 16px;
            }
        }

        & form.cart.booking-form {
            & .booking-form-wrapper {
                & .booking-form-inner {
                    padding: 0 16px;

                    & .booking-fields {
                        & #bfws-calendar-wrapper {
                            & table {
                                & tbody {
                                    & tr {
                                        & td {
                                            &.bfws-date-field-wrapper {
                                                & .date-field-block {
                                                    &:after {
                                                        top: 15px;
                                                        right: 16px;
                                                    }
                                                }

                                                & .bfws-start-date,
                                                & .bfws-end-date {
                                                    padding: 16px 34px 16px 18px;
                                                    color: var(--grey-medium);
                                                }
                                            }
                                        }
                                    }
                                }

                                & tfoot {
                                    & tr {
                                        & td {
                                            &.booking-cost-header,
                                            &.booking-cost-data {
                                                padding: 16px 18px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                & .pewc-product-extra-groups-wrap {
                    & .pewc-total-field-wrapper {
                        padding: 32px 16px 40px;
                    }
                }
            }
        }
    }
}
