.ht-download {
    padding: 56px 0;
    
    &-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 32px 56px;
        background-color: var(--grey-dark);
        border-radius: 8px;

        & .ht-download-text {
            & h2 {
                color: var(--white);
                margin: 0;
            }

            & p.ht-download-subtitle {
                color: var(--white);
                margin: 19px 0 0;
            }
        }
    }
}

@media only screen and (max-width: 667px) {
    .ht-download {
        &-container {
            flex-direction: column;

            & .ht-download-text {
                & h2,
                & p.ht-download-subtitle {
                    text-align: center;
                }
            }

            & .button {
                margin-top: 16px;
            }
        }
    }
}


@media only screen and (max-width: 568px) {
    .ht-download {
        padding: 24px 0;
    }
}
