.ht-cards-block {
    padding: 56px 0;

    & h2 {
        margin: 0 0 40px;
        color: var(--grey-dark);
        text-align: center;
    }

    & .ht-cards {
        display: flex;
        align-items: stretch;
        justify-content: center;
        flex-wrap: wrap;
        margin-left: -20px;
        margin-right: -20px;
        margin-bottom: -40px;

        & .ht-card {
            width: calc((100% - 120px) / 3);
            margin: 0 20px 40px;
            background-color: var(--blue);
            border-radius: 8px;

            & .ht-card-image {
                position: relative;
                width: 100%;
                height: 215px;
                overflow: hidden;
                border-radius: 8px 8px 0 0;

                & img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 100%;
                    max-width: none;
                    height: auto;
                    min-height: 100%;
                    transform: translate(-50%, -50%);
                }
            }

            & .ht-card-content {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                flex-direction: column;
                height: calc(100% - 215px);
                padding: 32px 40px;

                & h3,
                & p {
                    margin: 0 0 24px;
                    color: var(--white);
                }

                & a.button {
                    margin: auto 0 0;
                    width: 100%;
                }
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .ht-cards-block {
        & .ht-cards {    
            & .ht-card {
                width: calc(50% - 40px);
            }
        }
    }
}

@media only screen and (max-width: 568px) {
    .ht-cards-block {  
        padding: 24px 0;
          
        & .ht-cards {
            margin: 0;

            & .ht-card {
                width: 100%;
                margin: 0 0 40px;
            }
        }
    }
}
