.ht-contact {
    padding: 56px 0;

    &-left {
        margin-bottom: 40px;

        & .ht-wrapper {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            gap: 40px;
        }

        & .ht-contact-left-1,
        & .ht-contact-left-2 {
            & h2 {
                color: var(--grey-dark);
            }

            & p {
                margin: 0 0 24px;
                color: var(--grey-medium);
                font-family: var(--font);
                font-weight: 700;
                font-size: 24px;
                line-height: 1.22222;
            }

            & span {
                line-height: 1.22222;
            }

            & a:not(.button) {
                color: var(--grey-medium);
                font-family: var(--font);
                font-weight: 700;
                font-size: 24px;
                line-height: 1.22222;
                text-decoration: none;
                transition: color var(--standard);
                will-change: color;

                &:hover {
                    color: var(--pink);
                }
            }
        }

        & .ht-contact-left-1 {
            background-color: var(--blue);

            & h2,
            & p,
            & a:not(.button) {
                color: var(--white);
            }
        }

        & .ht-contact-left-2 {
            background-color: var(--grey-light);
        }

        & .ht-contact-left-1,
        & .ht-contact-left-2 {
            grid-column: span 1 / span 1;
            padding: 56px;
            border-radius: 8px;
        }
    }

    &-right {
        width: 1000px;

        & h2 {
            color: var(--grey-dark);
        }
    }
}

@media only screen and (max-width: 1280px) {
    .ht-contact {
        & .ht-contact-right {
            & .gform_wrapper {
                & .gform_body {
                    & ul.gform_fields {
                        & li.gfield {
                            &.gfield-recaptcha:not(.gf_left_half):not(
                                    .gf_right_half
                                ) {
                                position: relative !important;
                                max-width: none !important;
                                margin: 7px 0;

                                & > .ginput_recaptcha {
                                    & > div {
                                        margin: 0 0 0 auto;
                                    }
                                }
                            }
                        }
                    }
                }

                & .gform_footer {
                    max-width: none;
                    padding: 0;
                    margin: 24px 0 0 auto;
                }
            }
        }
    }
}

@media only screen and (max-width: 876px) {
    .ht-contact {
        padding: 56px 0;

        &-left,
        &-right {
            width: 100%;
        }

        &-left {
            & .ht-wrapper {
                grid-template-columns: repeat(1, minmax(0, 1fr));
            }
        }
    }
}

@media only screen and (max-width: 568px) {
    .ht-contact {
        padding: 24px 0;
    }
}
