.ht-reviews {
    padding: 56px 0;

    & .ht-wrapper {
        text-align: center;

        & h2 {
            color: var(--grey-dark);
        }

        & .ht-review {
            & > p:first-child {
                margin: 0 180px 16px;
            }
        }

        & .ht-review-name {
            color: var(--blue);
            margin-bottom: 40px;
        }

        & .slick-arrow {
            position: absolute;
            z-index: 4;
            top: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 56px;
            height: 56px;
            color: var(--white);
            font-size: 24px;
            line-height: 20px;
            background-color: var(--grey-dark);
            border: none;
            border-radius: 28px;
            outline: none;
            cursor: pointer;
            opacity: 1;
            transition: background-color var(--standard),
                opacity var(--standard);
            will-change: background-color, opacity;

            &:hover {
                background-color: var(--pink);
            }

            &.slick-disabled {
                opacity: 0;
            }

            &.slick-prev {
                left: 32px;
            }

            &.slick-next {
                right: 32px;
            }
        }

        & ul.slick-dots {
            position: absolute;
            z-index: 4;
            left: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            margin: 0;
            list-style: none;

            & li {
                width: 32px;
                height: 6px;
                margin: 0 8px 0 0;
                font-size: 0;
                line-height: 0;
                outline: none;
                transition: width var(--standard);
                will-change: width;

                & button {
                    width: 32px;
                    height: 6px;
                    font-size: 0;
                    line-height: 0;
                    background: var(--grey-medium);
                    border: 0;
                    border-radius: 3px;
                    outline: none;
                    cursor: pointer;
                    transition: width var(--standard),
                        background var(--standard);
                    will-change: width, background;
                }

                &.slick-active {
                    width: 64px;

                    & button {
                        width: 64px;
                        background: var(--pink);
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .ht-reviews {
        & .ht-wrapper {
            & .ht-review {
                & > p:first-child {
                    margin: 0 auto 16px;
                    max-width: 900px;
                }
            }
        }
    }
}
