.ht-content-box {
    padding: 56px 0;

    & .ht-content-box-container {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        width: 100%;
        padding: 56px 56px 60px;
        background-color: var(--grey-dark);
        border-top: 16px solid var(--pink);
        border-radius: 8px;

        & h2 {
            color: var(--white);
        }

        & p {
            margin: 0;
            color: var(--white);
        }

        & a.button {
            margin: 40px 0 0;
        }
    }
}

@media only screen and (max-width: 568px) {
    .ht-content-box {
        padding: 24px 0;
    }
}
