.ht-image-grid {
    padding: 56px 0;

    & .ht-image-grid-row {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        margin: 0 0 40px;

        &:last-child {
            margin: 0;
        }

        & .ht-image-grid-left,
        & .ht-image-grid-right {
            position: relative;
            height: 480px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 8px;

            & span {
                position: absolute;
                bottom: 24px;
                right: 24px;
                max-width: calc(100% - 48px);
                padding: 8px 16px;
                margin: 0;
                color: var(--white);
                background-color: var(--blue-50);
                border-radius: 4px;
            }
        }

        &.col-6-6 {
            & .ht-image-grid-left,
            & .ht-image-grid-right {
                width: calc(50% - 20px);
            }
        }

        &.col-4-8 {
            & .ht-image-grid-left {
                width: calc((100% - 40px) / 3);
            }

            & .ht-image-grid-right {
                width: calc(((100% - 40px) / 3) * 2);
            }
        }

        &.col-8-4 {
            & .ht-image-grid-left {
                width: calc(((100% - 40px) / 3) * 2);
            }

            & .ht-image-grid-right {
                width: calc((100% - 40px) / 3);
            }
        }
    }
}



@media only screen and (max-width: 1366px) {
    .ht-image-grid {
        & .ht-image-grid-row {
            & .ht-image-grid-left,
            & .ht-image-grid-right {
                height: 442px;
            }
        }
    }
}

@media only screen and (max-width: 1280px) {
    .ht-image-grid {
        & .ht-image-grid-row {
            & .ht-image-grid-left,
            & .ht-image-grid-right {
                height: 384px;
            }
        }
    }
}

@media only screen and (max-width: 1180px) {
    .ht-image-grid {
        & .ht-image-grid-row {
            & .ht-image-grid-left,
            & .ht-image-grid-right {
                height: 360px;
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .ht-image-grid {
        & .ht-image-grid-row {
            & .ht-image-grid-left,
            & .ht-image-grid-right {
                height: 304px;
            }
        }
    }
}

@media only screen and (max-width: 876px) {
    .ht-image-grid {
        & .ht-image-grid-row {
            & .ht-image-grid-left,
            & .ht-image-grid-right {
                height: 288px;
            }
        }
    }
}

@media only screen and (max-width: 812px) {
    .ht-image-grid {
        & .ht-image-grid-row {
            & .ht-image-grid-left,
            & .ht-image-grid-right {
                height: 276px;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .ht-image-grid {
        & .ht-image-grid-row {
            & .ht-image-grid-left,
            & .ht-image-grid-right {
                height: 264px;
            }
        }
    }
}

@media only screen and (max-width: 736px) {
    .ht-image-grid {
        & .ht-image-grid-row {
            flex-direction: column;

            &.col-6-6,
            &.col-4-8,
            &.col-8-4 {
                margin: 0;

                & .ht-image-grid-left,
                & .ht-image-grid-right {
                    width: 100%;
                    height: 264px;
                    margin: 0 0 32px;
                }

                &:last-child {
                    & .ht-image-grid-right {
                        margin: 0;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 568px) {
    .ht-image-grid {
        padding: 24px 0;
    }
}
