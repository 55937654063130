.ht-image {
    padding: 56px 0;

    & img {
        width: 100%;
        height: auto;
        margin: 0 auto;
    }

    &.narrow {
        & img {
            max-width: 840px;
        }    
    }
}

@media only screen and (max-width: 1024px) {
    .ht-image {
        &.narrow {
            & img {
                max-width: none;
            }    
        }
    }
}

@media only screen and (max-width: 568px) {
    .ht-image {
        padding: 24px 0;
    }
}
