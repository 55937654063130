header {
    & .gtranslate_wrapper {
        & a.gt-current-lang {
            display: none;
        }
        & a.glink {
            color: var(--grey-medium);
            &:hover {
                color: white;
            }
        }
    }

    & .menu {
        display: flex;
        align-items: center;
        list-style: none;
        margin: 0;

        & .menu-item-has-children {
            
            &:hover > a::after{
                transform: rotate(180deg);
            }
            & > a {
                &:after {
                    font-family: "Font Awesome 5 Pro";
                    content: "\f078";
                    display: inline-block;
                    margin-left: 8px;
                    transition: transform var(--standard);
                }
            }
            & .sub-menu {
                position: absolute;
                z-index: 101;
                top: 100%;
                margin: 0 0 0 -30px;
                list-style-type: none;
                opacity: 0;
                pointer-events: none;
                transition: opacity var(--standard);
                position: absolute;
                z-index: 1;
                padding: 16px;
                background-color: var(--black);
                outline: 0;
                box-shadow: 0px 20px 25px rgba(0, 0, 0, 0.1),
                    0px 10px 10px rgba(0, 0, 0, 0.04);

                & li {
                    padding: 8px 16px;
                    margin: 0;

                    & a {
                        margin: 0 !important;
                        color: var(--white);
                    }
                }

                /* IE10+ */
                @media screen and (-ms-high-contrast: active),
                    (-ms-high-contrast: none) {
                    min-width: 170px;
                }
            }

            &:hover .sub-menu {
                opacity: 1;
                pointer-events: all;
            }
        }
    }
}

@media only screen and (min-width: 1025px) {
    html[lang="nl"],
    html[lang="auto"] {
        header {
            & nav.main-menu-container {
                & .main-menu {
                    & li {
                        & > a[title="Dutch"] {
                            display: none;
                        }
                        & > a[title="English"] {
                            opacity: 0.5;

                            &:hover {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
            & .ht-mobile-nav {
                & > a[title="Dutch"] {
                    display: none;
                }
                & > a[title="English"] {
                    opacity: 0.5;

                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }
    }
    html[lang="en"] {
        header {
            & nav.main-menu-container {
                & .main-menu {
                    & li {
                        & > a[title="Dutch"] {
                            opacity: 0.5;
                            &:hover {
                                opacity: 1;
                            }
                        }
                        & > a[title="English"] {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

html[lang="nl"],
html[lang="auto"] {
    & header {
        & .ht-mobile-nav {
            & a[title="Dutch"] {
                display: none;
            }
            & a[title="English"] {
                opacity: 0.5;
            }
        }
    }
}
html[lang="en"] {
    & header {
        & .ht-mobile-nav {
            & a[title="Dutch"] {
                opacity: 0.5;
            }
            & a[title="English"] {
                display: none;
            }
        }
    }
}

@media only screen and (min-width: 1025px) {
    header {
        & nav.main-menu-container {
            position: relative;
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 0;

            & .main-menu {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 100%;
                height: 100%;
                padding: 0;
                margin: 0;
                list-style: none;

                & li {
                    padding: 30px 0;
                    margin: 0 0 0 20px;
                    color: var(--white);
                    font-family: var(--font);
                    font-weight: 700;
                    font-size: responsive 12px 14px;
                    line-height: 1.21428;
                    text-transform: uppercase;
                    transition: padding var(--standard);
                    will-change: padding;
                    cursor: pointer;

                    &.active {
                        overflow: hidden;

                        & > i,
                        & > svg {
                            color: var(--pink);
                            transform: rotate(180deg);
                        }

                        & > div.sub-menu-container {
                            z-index: 9;
                            opacity: 1;
                            pointer-events: all;
                            transition: opacity var(--standard);
                            will-change: opacity;
                        }
                    }

                    &:first-child {
                        margin: 0;
                    }

                    & > i,
                    & > svg {
                        margin: 0 0 0 6px;
                        color: var(--white);
                        font-family: var(--icons);
                        font-weight: 900;
                        font-size: responsive 12px 12px;
                        line-height: 0.83333;
                        transition: transform var(--standard),
                            color var(--standard);
                    }

                    & > a {
                        color: var(--white);
                        font-family: var(--font);
                        font-weight: 700;
                        font-size: responsive 12px 14px;
                        line-height: 1.21428;
                        text-decoration: none;
                        text-transform: uppercase;
                        transition: all var(--standard);

                        &:hover {
                            color: var(--pink);
                        }
                    }

                    & > div.sub-menu-container {
                        position: absolute;
                        z-index: -1;
                        top: 45px;
                        left: 0;
                        width: 100%;
                        padding: 0;
                        margin: 0;
                        opacity: 0;
                        overflow: hidden;
                        pointer-events: none;
                        transition: opacity var(--standard);
                        will-change: opacity;

                        & > div.sub-menu-wrapper {
                            display: flex;
                            align-items: flex-start;
                            justify-content: flex-start;
                            width: calc(100% + 48px);
                            padding: 144px 64px 112px 24px;
                            max-height: calc(100vh - 76px);
                            overflow-y: auto;
                            overflow-x: hidden;

                            & > ul.sub-menu {
                                display: flex;
                                align-items: flex-start;
                                justify-content: flex-start;
                                flex-direction: column;
                                width: calc(20% - 25.6px);
                                padding: 0;
                                margin: 0 32px 0 0;
                                list-style: none;

                                &:last-child {
                                    margin: 0;
                                }

                                & > span.sub-menu-topic {
                                    margin: 0;
                                    padding: 0 0 8px;
                                    color: var(--grey-medium);
                                    font-family: var(--font);
                                    font-weight: 500;
                                    font-size: responsive 16px 16px;
                                    line-height: 1.5;
                                    text-transform: none;
                                }

                                & li {
                                    width: 100%;

                                    & > a {
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;
                                        padding: 16px 0;
                                        color: var(--white) !important;
                                        font-family: var(--font);
                                        font-weight: 700;
                                        font-size: responsive 16px 16px;
                                        line-height: 1.25;
                                        border-bottom: 1px solid var(--white-10);
                                        text-decoration: none;
                                        transition: color var(--standard);
                                        will-change: color;

                                        & > i,
                                        & > svg {
                                            color: var(--grey-medium);
                                            font-family: var(--icons);
                                            font-weight: 400;
                                            font-size: responsive 16px 16px;
                                            line-height: 1;
                                            transition: color var(--standard);
                                            will-change: color;
                                        }

                                        &:hover {
                                            color: var(--pink) !important;

                                            & > i,
                                            & > svg {
                                                color: var(--pink) !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        & .sub-menu-cta {
                            width: calc(40% - 25.6px);
                            margin: 0 0 0 auto;

                            & img {
                                width: 100%;
                                height: auto;
                            }

                            & .subscript {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                width: 100%;
                                margin: 20px 0 0;

                                & span {
                                    margin: 0;
                                    color: var(--grey-medium);
                                    font-family: var(--font);
                                    font-weight: 700;
                                    font-size: responsive 14px 14px;
                                    line-height: 1.21428;
                                    text-transform: none;
                                }

                                & a.link {
                                    margin: 0;
                                    color: var(--white);
                                    font-family: var(--font);
                                    font-weight: 700;
                                    font-size: responsive 12px 12px;
                                    line-height: 1.25;
                                    text-decoration: none;
                                    text-transform: uppercase;
                                    transition: color var(--standard);
                                    will-change: color;

                                    & i,
                                    & svg {
                                        margin: 0 0 0 4px;
                                        color: var(--white);
                                        font-family: var(--icons);
                                        font-weight: 900;
                                        font-size: responsive 13px 13px;
                                        line-height: 1;
                                        transition: color var(--standard);
                                        will-change: color;
                                    }

                                    &:hover {
                                        color: var(--pink) !important;

                                        & > i,
                                        & > svg {
                                            color: var(--pink) !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                & li.site-header-cart {
                    padding: 7px 0 24px;
                    margin: 0 0 0 10px;
                    color: var(--white);
                    font-family: var(--font);
                    font-weight: 700;
                    font-size: responsive 12px 14px;
                    line-height: 1.21428;
                    text-transform: uppercase;
                    transition: padding var(--standard);
                    will-change: padding;
                    cursor: pointer;

                    & a.cart-contents {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-decoration: none;

                        & span {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding: 0;
                            margin: 0;
                            background-color: transparent;
                            border: none;
                            border-radius: 13px;
                            color: var(--white);
                            font-family: var(--font);
                            font-weight: 700;
                            font-size: responsive 12px 12px;
                            line-height: 1.25;
                            text-decoration: none;
                            transition: color var(--standard),
                                background-color var(--standard);
                            will-change: color, background-color;

                            & i {
                                margin: 0 4px 0 0;
                                font-size: responsive 13px 13px;
                                line-height: 1;
                                font-weight: 900;
                            }
                        }

                        &:hover {
                            & span {
                                color: var(--white);
                                background-color: var(--pink);
                            }
                        }
                    }
                }
            }

            & #mainsearchform {
                position: absolute;
                z-index: -1;
                top: 160px;
                right: 0;
                width: 40%;
                opacity: 0;
                transition: opacity var(--standard);
                will-change: opacity;
                pointer-events: none;

                &.show {
                    opacity: 1;
                    z-index: 10;
                    top: 60px;
                    pointer-events: all;
                }

                & .ht-search-field {
                    width: 100%;
                    padding: 15px 40px 15px 51px;
                    margin: 0;
                    color: var(--white);
                    font-family: var(--font);
                    font-weight: 500;
                    font-size: responsive 14px 18px;
                    line-height: 1.21428;
                    background-color: var(--blue);
                    border-radius: 8px;
                    border: none;
                    outline: none;
                    appearance: none;

                    &::-webkit-input-placeholder,
                    &::-moz-placeholder,
                    &:-ms-input-placeholder,
                    &::placeholder {
                        color: var(--white);
                    }
                }

                & .ht-search-button {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 51px;
                    height: 51px;
                    padding: 0;
                    margin: 0;
                    transform: scaleX(-1);
                    color: var(--white);
                    font-family: var(--icons);
                    font-size: responsive 14px 18px;
                    line-height: 1;
                    font-weight: 900;
                    background-color: transparent;
                    border: none;
                    outline: none;
                    appearance: none;
                    cursor: pointer;
                }
            }
        }

        &.scrolled {
            & nav.main-menu-container {
                & .main-menu {
                    & li {
                        & > .sub-menu-container {
                            top: 44px;
                            width: 100%;
                            margin: 0;
                            max-height: calc(100vh - 76px);
                        }
                    }
                }
            }
        }

        & .ht-mobile-nav {
            display: none;
        }
    }
}

@media only screen and (max-width: 1180px) {
    header {
        & nav.main-menu-container {
            & .main-menu {
                & li {
                    margin: 0 0 0 12px;

                    & > div.sub-menu-container {
                        padding: 76px 24px 76px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1300px) {
    header {
        & .ht-wrapper {
            display: flex;

            & .ht-mobile-nav-button {
                margin: 0 0 0 auto;

                & .ht-mobile-nav-toggle.open svg#nav-icon #bar-1 {
                    transition: all var(--standard);
                    transform: rotateZ(45deg) translate3d(6px, -6px, 0);
                }

                & .ht-mobile-nav-toggle.open svg#nav-icon #bar-2 {
                    transition: all var(--standard);
                    transform: translateX(20vw);
                }

                & .ht-mobile-nav-toggle.open svg#nav-icon #bar-3 {
                    transition: all var(--standard);
                    transform: rotateZ(-45deg) translate3d(-14px, -2px, 0);
                }

                & .ht-mobile-nav-toggle.closed svg#nav-icon #bar-1 {
                    transition: all var(--standard);
                    transform: rotateZ(0) translate3d(0, 0, 0);
                }

                & .ht-mobile-nav-toggle.closed svg#nav-icon #bar-2 {
                    transition: all var(--standard);
                    transform: translateX(0);
                }

                & .ht-mobile-nav-toggle.closed svg#nav-icon #bar-3 {
                    transition: all var(--standard);
                    transform: rotateZ(0) translate3d(0, 0, 0);
                }
            }
        }

        & nav.main-menu-container {
            display: none;
        }

        & .ht-mobile-nav {
            position: absolute;
            top: 44px;
            left: calc((100% - 100vw) / 2);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            width: 100vw;
            height: calc(100vh - 44px);
            padding: 0;
            background-color: var(--black);
            transition: all var(--standard);
            overflow-y: scroll;

            & .menu-item-has-children {
                flex-direction: column;

                & .sub-menu {
                    opacity: 1;
                    position: relative;
                    left: 0;
                    top: 0;
                    margin-top: 20px;
                    margin: 16px 0 0 12px;
                    padding: 0;

                    & li {
                        border: none;
                    }
                }
            }

            & .back {
                position: absolute;
                top: 40px;
                left: 50%;
                width: 288px;
                transform: translateX(-50%);

                &.show {
                    opacity: 1;
                    pointer-events: all;
                }

                &.hide {
                    opacity: 0;
                    pointer-events: none;
                }

                & i,
                & svg {
                    margin-right: 8px;
                }
            }

            & .menu {
                display: flex;
                flex-direction: column;
                padding: 0;
                align-items: flex-start;
            }

            & .main-menu {
                & li {
                    opacity: 0;
                    width: 100%;

                    &:nth-last-child(3) {
                        border: none;

                        & > a {
                            width: auto;
                            width: 100%;

                            & > i,
                            & > svg {
                                margin-right: 8px;
                                color: var(--white);
                                font-family: var(--icons);
                                font-weight: 900;
                                font-size: responsive 13px 13px;
                                line-height: 1;
                                transform: scaleX(-1);
                            }
                        }
                    }

                    &.site-header-cart {
                        padding: 7px 0 24px;
                        margin: 0;
                        color: var(--white);
                        font-family: var(--font);
                        font-weight: 700;
                        font-size: responsive 12px 14px;
                        line-height: 1.21428;
                        border: none;
                        text-transform: uppercase;
                        transition: padding var(--standard);
                        will-change: padding;
                        cursor: pointer;

                        & a.cart-contents {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            text-decoration: none;

                            & span {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                padding: 16px 40px;
                                margin: 0;
                                background-color: var(--white);
                                border: none;
                                border-radius: 39px;
                                color: var(--blue);
                                font-family: var(--font);
                                font-weight: 700;
                                font-size: responsive 14px 14px;
                                line-height: 1.25;
                                text-decoration: none;
                                transition: color var(--standard),
                                    background-color var(--standard);
                                will-change: color, background-color;

                                & i {
                                    margin: 0 4px 0 0;
                                    font-size: responsive 14px 14px;
                                    line-height: 1;
                                    font-weight: 900;
                                }
                            }

                            &:hover {
                                & span {
                                    color: var(--white);
                                    background-color: var(--pink);
                                }
                            }
                        }
                    }
                }

                & #mobilesearchform {
                    position: absolute;
                    z-index: -1;
                    bottom: 16px;
                    left: 0;
                    width: 100%;
                    opacity: 0;
                    transition: opacity var(--standard);
                    will-change: opacity;

                    &.show {
                        opacity: 1;
                        z-index: 10;
                    }

                    & .ht-search-field {
                        width: 100%;
                        padding: 17px 40px 16px 51px;
                        margin: 0;
                        color: var(--white);
                        font-family: var(--font);
                        font-size: responsive 14px 18px;
                        line-height: 1.22222;
                        background-color: var(--blue);
                        border-radius: 8px;
                        border: none;
                        outline: none;
                        appearance: none;

                        &::-webkit-input-placeholder,
                        &::-moz-placeholder,
                        &:-ms-input-placeholder,
                        &::placeholder {
                            color: var(--white);
                        }
                    }

                    & .ht-search-button {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 51px;
                        height: 51px;
                        padding: 0;
                        margin: 0;
                        color: var(--white);
                        font-family: var(--icons);
                        font-size: responsive 14px 18px;
                        line-height: 1;
                        font-weight: 900;
                        background-color: transparent;
                        border: none;
                        outline: none;
                        appearance: none;
                        cursor: pointer;
                    }
                }
            }

            & .main-menu,
            & .sub-menu {
                position: absolute;
                top: 76px;
                left: 50%;
                width: 288px;
                padding: 0;
                list-style: none;
                transition: all var(--slow);

                &.previous {
                    transform: translateX(-150vw);
                }

                &.current {
                    transform: translateX(-50%);
                }

                &.next {
                    transform: translateX(150vw);
                }

                & li {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 16px 0;
                    color: var(--white) !important;
                    font-family: var(--font);
                    font-weight: 700;
                    font-size: responsive 16px 16px;
                    line-height: 1.25;
                    border-bottom: 1px solid var(--white-10);
                    text-decoration: none;
                    transition: color var(--standard);
                    will-change: color;

                    & > i,
                    & > svg {
                        color: var(--grey-medium);
                        font-family: var(--icons);
                        font-weight: 400;
                        font-size: responsive 16px 16px;
                        line-height: 1;
                        transition: color var(--standard);
                        will-change: color;
                    }

                    &:hover {
                        color: var(--pink) !important;

                        & > i,
                        & > svg {
                            color: var(--pink) !important;
                        }
                    }

                    & a {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        color: var(--white) !important;
                        font-family: var(--font);
                        font-weight: 700;
                        font-size: responsive 16px 16px;
                        line-height: 1.25;
                        text-decoration: none;
                        transition: color var(--standard);
                        will-change: color;

                        & > i,
                        & > svg {
                            color: var(--grey-medium);
                            font-family: var(--icons);
                            font-weight: 400;
                            font-size: responsive 16px 16px;
                            line-height: 1;
                            transition: color var(--standard);
                            will-change: color;
                        }

                        &:hover {
                            color: var(--pink) !important;

                            & > i,
                            & > svg {
                                color: var(--pink) !important;
                            }
                        }
                    }
                }

                & .menu-image {
                    padding: 16px 0;

                    & .subscript {
                        width: 100%;

                        & li {
                            border: none;
                            padding: 16px 0 8px;
                        }

                        & a {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: 100%;
                            color: var(--white) !important;
                            font-family: var(--font);
                            font-weight: 700;
                            font-size: responsive 16px 16px;
                            line-height: 1.25;
                            text-decoration: none;
                            transition: color var(--standard);
                            will-change: color;

                            & > i,
                            & > svg {
                                color: var(--grey-medium);
                                font-family: var(--icons);
                                font-weight: 400;
                                font-size: responsive 16px 16px;
                                line-height: 1;
                                transition: color var(--standard);
                                will-change: color;
                            }

                            &:hover {
                                color: var(--pink) !important;

                                & > i,
                                & > svg {
                                    color: var(--pink) !important;
                                }
                            }
                        }
                    }
                }
            }

            &.open {
                transform: translateX(0);

                & li {
                    animation: slide-in 250ms 250ms ease-in-out forwards;
                }
            }

            &.closed {
                transform: translateX(100vw);

                & li {
                    transform: translateX(-10vw);
                }
            }
        }
    }
}

@media only screen and (max-width: 568px) {
    header {
        & .ht-wrapper {
            & .ht-mobile-nav {
                & .back {
                    top: 8px;
                }

                & .main-menu {
                    top: 40px;
                }
            }
        }
    }
}

@keyframes slide-in {
    0% {
        opacity: 0;
        transform: translateX(-10vw);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}
