.ht-btn {
    padding: 56px 0;

    &.narrow {
        & .ht-wrapper {
            margin: 0 auto;
            max-width: 840px;
        }
    }

    & .ht-wrapper {
        display: flex;

        &.left {
            justify-content: flex-start;
        }

        &.center {
            justify-content: center;
        }

        &.right {
            justify-content: flex-end;
        }
    }
}

@media only screen and (max-width: 1024px) {
    .ht-btn,
    .ht-btn.narrow {
        & .ht-wrapper {
            max-width: 812px;
        }
    }
}

@media only screen and (max-width: 876px) {
    .ht-btn,
    .ht-btn.narrow {
        & .ht-wrapper {
            max-width: 768px;
        }
    }
}

@media only screen and (max-width: 812px) {
    .ht-btn,
    .ht-btn.narrow {
        & .ht-wrapper {
            max-width: 736px;
        }
    }
}

@media only screen and (max-width: 768px) {
    .ht-btn,
    .ht-btn.narrow {
        & .ht-wrapper {
            max-width: 704px;
        }
    }
}

@media only screen and (max-width: 736px) {
    .ht-btn,
    .ht-btn.narrow {
        & .ht-wrapper {
            max-width: calc(100vw - 48px);
        }
    }
}

@media only screen and (max-width: 568px) {
    .ht-btn,
    .ht-btn.narrow {
        padding: 24px 0;
    }
}

@media only screen and (max-width: 414px) {
    .ht-btn,
    .ht-btn.narrow {
        & .ht-wrapper {
            max-width: calc(100vw - 32px);
        }
    }
}
