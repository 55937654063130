.ht-header-visual {
    &-blog {
        position: relative;
        background-size: cover;
        width: 100vw;
        
        &:after {
            content: "";
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--black-50);
        }

        & .ht-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }

        & .ht-header-visual-content {
            position: relative;
            z-index: 3;
            width: 100%;
            max-width: 840px;
            max-height: none;
            margin: 0 auto;
            text-align: center;
            margin: 76px 0 0;
            padding: 100px 0;
            gap: 0;

            @media only screen and (max-width: 425px) {
                padding: 40px 0;
                gap: 0;
            }

            & .ht-breadcrumbs {
                color: var(--white);
                margin: 0 0 40px;

                @media only screen and (max-width: 425px) {
                    margin-bottom: 0;
                    text-align: start;
                }

                > span {
                    justify-content: center;
                    flex-wrap: wrap;
                }

                span,
                a {
                    color: var(--white);
                    text-align: center;
                }
            }

            & h1 {
                margin: 0 0 24px;
                color: var(--white);
                font-size: responsive 40px 60px;
                line-height: 1.21875;
                text-align: center;
            }

            & p.ht-header-blog-date {
                margin: 0;
                color: var(--white);
            }
        }
    }
}
