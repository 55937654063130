.ht-4-items {
    padding: 56px 0;

    h2,
    h3 {
        color: var(--grey-dark);
        text-align: center;
    }

    .ht-4-item-row {
        display: flex;
        flex-wrap: wrap;
        gap: 40px; /* Adjust the gap as needed */
        justify-content: center;
        align-items: flex-start;

        & > div {
            display: flex;
            flex-direction: column;
            align-items: center;
            flex-grow: 0;
            flex-shrink: 0;
        }

        /* For 3 or fewer items */
        &:has(> div:nth-child(3):last-child) > div,
        &:has(> div:nth-child(2):last-child) > div,
        &:has(> div:nth-child(1):last-child) > div {
            flex-basis: calc(33.33% - 40px); /* Adjust based on your gap */
        }

        /* For 4 or more items */
        &:has(> div:nth-child(4):not(:last-child)) > div,
        &:has(> div:nth-child(4):last-child) > div {
            flex-basis: calc(25% - 40px); /* Adjust based on your gap */
        }

        @media only screen and (max-width: 1024px) {
            &:has(> div:nth-child(3):last-child) > div,
            &:has(> div:nth-child(2):last-child) > div,
            &:has(> div:nth-child(1):last-child) > div,
            &:has(> div:nth-child(4):not(:last-child)) > div,
            &:has(> div:nth-child(4):last-child) > div {
                flex-basis: calc(40%); /* Adjust based on your gap */
            }
        }

        @media only screen and (max-width: 425px) {
            &:has(> div:nth-child(3):last-child) > div,
            &:has(> div:nth-child(2):last-child) > div,
            &:has(> div:nth-child(1):last-child) > div,
            &:has(> div:nth-child(4):not(:last-child)) > div,
            &:has(> div:nth-child(4):last-child) > div {
                flex-basis: calc(100%); /* Adjust based on your gap */
            }
        }

        img {
            margin-bottom: 20px;
            height: 200px;
            object-fit: cover;
        }
    }
}

body.single-blog {
    & .ht-4-items {
    
        & .ht-4-item-row {
            & img {
                margin-bottom: 20px;
                width: 100%;
                height: auto;
                min-height: 200px;
                object-fit: cover;

                & + p {
                    text-align: center;
                }
            }
        }
    }   
}
