@media only screen and (min-width: 737px) {
    .ht-spacer {
        display: block;
    }

    .ht-spacer-mobile {
        display: none;
    }   
}

@media only screen and (max-width: 736px) {
    .ht-spacer {
        display: none;
    }

    .ht-spacer-mobile {
        display: block;
    }   
}
