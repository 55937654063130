.ht-usp_items {
    padding: 56px 0;

    & .ht-wrapper {
        & h2 {
            color: var(--grey-dark);
            text-align: center;
            margin: 0 0 40px;
        }

        & img {
            border-radius: 50%;
            object-fit: cover;
            height: 300px;
            width: 300px;
        }

        & .slick-track {
            display: flex;
            gap: 40px;
        }

        & .ht-usp_item {
            display: flex;
            align-items: center;
            flex-direction: column;

            & .ht-usp-content {
                margin-top: 20px;
                text-align: center;

                & h1,
                & h2,
                & h3,
                & h4,
                & h5,
                & h6,
                & .h1,
                & .h2,
                & .h3,
                & .h4,
                & .h5,
                & .h6 {
                    color: var(--grey-dark);
                    margin: 0 0 12px;
                }

                & p {
                    margin-bottom: 5px;
                    font-size: 22px;
                }
            }
        }

        & .slick-arrow {
            position: absolute;
            z-index: 4;
            top: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 56px;
            height: 56px;
            color: var(--white);
            font-size: 24px;
            line-height: 20px;
            background-color: var(--grey-dark);
            border: none;
            border-radius: 28px;
            outline: none;
            cursor: pointer;
            opacity: 1;
            transition: background-color var(--standard),
                opacity var(--standard);
            will-change: background-color, opacity;

            &:hover {
                background-color: var(--pink);
            }

            &.slick-disabled {
                opacity: 0;
            }

            &.slick-prev {
                left: 32px;
            }

            &.slick-next {
                right: 32px;
            }
        }

        & ul.slick-dots {
            position: absolute;
            z-index: 4;
            left: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            margin: 0;
            list-style: none;

            & li {
                width: 32px;
                height: 6px;
                margin: 0 8px 0 0;
                font-size: 0;
                line-height: 0;
                outline: none;
                transition: width var(--standard);
                will-change: width;

                & button {
                    width: 32px;
                    height: 6px;
                    font-size: 0;
                    line-height: 0;
                    background: var(--grey-medium);
                    border: 0;
                    border-radius: 3px;
                    outline: none;
                    cursor: pointer;
                    transition: width var(--standard),
                        background var(--standard);
                    will-change: width, background;
                }

                &.slick-active {
                    width: 64px;

                    & button {
                        width: 64px;
                        background: var(--pink);
                    }
                }
            }
        }
    }
}
