.ht-contact-right {
    & .gform_wrapper {
        margin: 40px 0 0 0;

        &.gform_validation_error {
            & div.validation_error {
                margin: 0 0 24px;
                padding: 0 0 0 40px;
                color: var(--pink);
                font-size: responsive 12px 16px;
                line-height: 1.5;
                border: none;
                text-align: left;
            }

            & .gform_body {
                & ul.gform_fields {
                    & li.gfield {
                        &.field_description_above {
                            & .gfield_description:not(.validation_message) {
                                padding: 0 40px 22px 40px;
                            }
                        }

                        &.field_description_below {
                            & .gfield_description:not(.validation_message) {
                                padding: 22px 40px 0 40px;
                            }
                        }

                        &.gfield_error:not(.gf_left_half):not(.gf_right_half) {
                            width: 100% !important;
                            max-width: none !important;
                            margin: 0 0 32px !important;
                            border: none;
                            background-color: transparent;

                            & .validation_message {
                                position: absolute;
                                padding: 0 0 0 40px;
                                color: var(--pink);
                                font-size: responsive 12px 16px;
                                line-height: 1.5;
                                border: none;
                                text-align: left;
                            }

                            & div.ginput_container {
                                display: flex;
                                align-items: center;

                                &
                                    input:not([type="radio"]):not(
                                        [type="checkbox"]
                                    ):not([type="submit"]):not(
                                        [type="button"]
                                    ):not([type="image"]):not([type="file"]) {
                                    background-color: var(--pink-20);
                                }

                                & textarea {
                                    background-color: var(--pink-20);
                                }
                            }

                            &.gfield-recaptcha {
                                position: absolute;
                                max-width: 50% !important;
                                margin: 7px auto 0 0;

                                & label.gfield_label {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }

        & .gform_body {
            & ul.gform_fields {
                position: relative;

                & li.gfield {
                    position: relative;
                    margin: 0 0 32px;
                    padding: 0;

                    &.gfield_error:not(.gf_left_half):not(.gf_right_half) {
                        width: 100% !important;
                        max-width: none !important;
                        margin: 0 0 32px !important;
                        border: none;
                        background-color: transparent;

                        & .validation_message {
                            position: absolute;
                            padding: 0 0 0 40px;
                            color: var(--pink);
                            font-size: responsive 12px 16px;
                            line-height: 1.5;
                            border: none;
                            text-align: left;
                        }

                        & div.ginput_container {
                            &
                                input:not([type="radio"]):not(
                                    [type="checkbox"]
                                ):not([type="submit"]):not([type="button"]):not(
                                    [type="image"]
                                ):not([type="file"]) {
                                background-color: var(--pink-20);
                            }

                            & textarea {
                                background-color: var(--pink-20);
                            }
                        }
                    }

                    & label.gfield_label {
                        position: absolute;
                        z-index: 2;
                        top: 0;
                        left: 0;
                        width: auto;
                        padding: 22px 40px;
                        margin: 0;
                        color: var(--grey-medium);
                        font-size: responsive 16px 24px;
                        line-height: 1.20833;
                        font-weight: 700;
                        border-radius: 42px 0 0 42px;
                        transition: color var(--standard),
                            background-color var(--standard);
                        will-change: color, background-color;

                        &.not-empty {
                            color: var(--white);
                            background-color: var(--blue);
                        }

                        & span.gfield_required {
                            margin: 0 0 0 4px;
                            color: var(--pink);
                            font-size: responsive 16px 24px;
                            line-height: 1.20833;
                            font-weight: 700;
                        }
                    }

                    & div.ginput_container {
                        position: relative;
                        z-index: 1;
                        margin: 0;
                        display: flex;
                        align-items: center;

                        &
                            input:not([type="radio"]):not(
                                [type="checkbox"]
                            ):not([type="submit"]):not([type="button"]):not(
                                [type="image"]
                            ):not([type="file"]) {
                            width: 100%;
                            padding: 22px 40px 22px 0;
                            margin: 0;
                            color: var(--grey-dark);
                            font-size: responsive 16px 24px;
                            line-height: 1.20833;
                            font-weight: 700;
                            background-color: var(--grey-medium-20);
                            border: none;
                            border-radius: 42px;
                            outline: none;
                        }

                        & textarea {
                            width: 100%;
                            padding: 22px 40px;
                            margin: 0;
                            color: var(--grey-dark);
                            font-size: responsive 16px 24px;
                            line-height: 1.20833;
                            font-weight: 700;
                            background-color: var(--grey-medium-20);
                            border: none;
                            border-radius: 42px;
                            outline: none;
                        }
                    }

                    &.gfield-textarea {
                        & label.gfield_label {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            padding: 40px 40px 34px;
                            margin: 0;
                            color: var(--grey-medium);
                            font-size: responsive 16px 24px;
                            line-height: 1.20833;
                            font-weight: 700;
                            border-radius: 42px 42px 0 0;
                            transition: color var(--standard),
                                background-color var(--standard);
                            will-change: color, background-color;

                            &.not-empty {
                                color: var(--white);
                                background-color: var(--blue);
                            }
                        }

                        & .ginput_container_textarea {
                            & textarea {
                                padding: 143px 40px 64px;
                                min-height: 328px;
                            }
                            
                        }
                    }
                   
                        

                    &.gfield-recaptcha:not(.gf_left_half):not(.gf_right_half) {
                        position: absolute;
                        max-width: 50% !important;
                        margin: 7px auto 0 0;

                        & label.gfield_label {
                            display: none;
                        }
                    }
                }
            }

            & .gform_validation_container,
            & .gfield_visibility_hidden {
                margin: 0 !important;
                padding: 0 !important;
                font-size: 0 !important;
                line-height: 0 !important;

                & label,
                & input {
                    margin: 0 !important;
                    padding: 0 !important;
                    font-size: 0 !important;
                    line-height: 0 !important;
                }
            }
        }

        & .gform_footer {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            max-width: 50%;
            padding: 0;
            margin: 0 0 0 auto;

            & input.gform_button[type="submit"] {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                margin: 0;
                color: var(--white);
                font-family: var(--font);
                font-size: 18px;
                padding: 16px 40px;
                font-weight: 700;
                text-align: center;
                text-decoration: none;
                text-transform: uppercase;
                line-height: 1.22222;
                background-color: var(--grey-medium);
                border: none;
                border-radius: 39px;
                outline: none;
                transition: background-color var(--standard),
                    color var(--standard);
                will-change: color, background-color;
                cursor: pointer;

                &:hover {
                    color: var(--white);
                    background-color: var(--pink);
                }
            }
        }
    }

    & .ginput_container_consent {
        & input[type="checkbox"] {
            width: 13px;
            height: 13px;
            padding: 0;
            margin: 0;
            vertical-align: top;
            position: relative;
            margin-right: 10px;
        }
        & .gfield_consent_label {
            display: inline-block;
            width: 90%;
            font-size: 16px;
            margin: 0;

            &.not-empty {
                background-color: transparent;
            }
        }
    }
}

.form_nolabel {
    & .ginput_container_textarea{
        & textarea {
            padding: 20px 40px 64px!important;
        }
    }
}