.ht-item_slider {
    padding: 56px 0;

    & .ht-wrapper {
        & .slick-track {
            display: flex;
            gap: 36px;
        }

        & .ht-slider-items {
            position: relative;
            width: 100%;
        }

        & .ht-item-content {
            width: 100%;
        }

        & .ht-item-card {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            text-decoration: none;
            height: inherit;
            color: var(--grey-medium);

            &:hover .button {
                opacity: 0.75;
            }

            & img {
                height: 350px;
                width: 100%;
                object-fit: cover;
            }

            & h3 {
                margin: 24px 0 12px;
                color: var(--grey-dark);
            }

            & ul {
                padding-left: 20px;
                margin: 0;

                & li {
                    font-size: 20px;
                }
            }

            & .button {
                color: var(--pink);
                text-decoration: underline;
                transition: all 250ms ease-in-out;
                margin: 12px 0 0;
                padding: 0;
            }
        }

        & .slick-arrow {
            position: absolute;
            z-index: 4;
            top: 0;
            bottom: 0;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 56px;
            height: 56px;
            color: var(--white);
            font-size: 24px;
            line-height: 20px;
            background-color: var(--grey-dark);
            border: none;
            border-radius: 28px;
            outline: none;
            cursor: pointer;
            opacity: 1;
            transition: background-color var(--standard),
                opacity var(--standard);
            will-change: background-color, opacity;

            &:hover {
                background-color: var(--pink);
            }

            &.slick-disabled {
                opacity: 0;
            }

            &.slick-prev {
                left: -60px;
            }

            &.slick-next {
                right: -60px;
            }
        }

        & ul.slick-dots {
            position: absolute;
            z-index: 4;
            left: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            margin: 0;
            list-style: none;

            & li {
                width: 32px;
                height: 6px;
                margin: 0 8px 0 0;
                font-size: 0;
                line-height: 0;
                outline: none;
                transition: width var(--standard);
                will-change: width;

                & button {
                    width: 32px;
                    height: 6px;
                    font-size: 0;
                    line-height: 0;
                    background: var(--grey-medium);
                    border: 0;
                    border-radius: 3px;
                    outline: none;
                    cursor: pointer;
                    transition: width var(--standard),
                        background var(--standard);
                    will-change: width, background;
                }

                &.slick-active {
                    width: 64px;

                    & button {
                        width: 64px;
                        background: var(--pink);
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .ht-item_slider {
        & .ht-wrapper {
            & .ht-slider-items {
                padding-bottom: 40px;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .ht-item_slider {
        & .ht-wrapper {
            & .slick-track {
                gap: 20px;
            }
        }
    }
}
