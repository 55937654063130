.ht-anchornav {
    position: relative;
    z-index: 8;
    transform: translate(0, -50%);

    & .ht-anchornav-dropdown {
        display: none;
    }

    & .ht-wrapper {
        padding: 15px 40px;
        background-color: var(--blue);
        border-radius: 8px;
        overflow-x: auto;
        transition: padding var(--standard);
        will-change: padding;
    }

    &-inner {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &-left {
            display: block;
            height: 100%;
            max-width: calc(100% - 180px);
            margin: 0 auto 0 0;
            white-space: nowrap;
            overflow-x: auto;
        }

        &-right {
            max-width: 180px;
            margin: 0 0 0 auto;
        }

        & .ht-anchor-link {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            /* padding: 8px 16px; */
            margin: 0 0 0 16px;
            color: var(--white);
            font-family: var(--font);
            font-size: responsive 10px 12px;
            font-weight: 700;
            line-height: 1.25;
            text-align: center;
            text-decoration: none;
            text-transform: uppercase;
            /* background-color: var(--grey-dark-30); */
            border: 1px solid transparent;
            border-radius: 4px;
            /* transition: background-color var(--standard), border var(--standard); */
            will-change: border, background-color;
            white-space: nowrap;

            display: inline-block;

            &:first-child {
                margin: 0;
            }
            
            /*
            &.active,
            &:hover {
                background-color: transparent;
                border-bottom: 1px solid var(--white);
            }
            */
            
            &::after {
                content: '';
                display: block;
                width: 0;
                height: 1px;
                margin-top:4px;
                background: var(--white);
                transition: width .3s;
            }
            &:hover::after {
                width: 100%;
            }

        }
    }

    &.sticky {
        position: fixed;
        top: 84px;
        left: 50%;
        transform: translate(-50%, 0);

        & .ht-wrapper {
            padding: 15px 24px;
            border-radius: 0 0 8px 8px;
        }
    }
}

@media only screen and (max-width: 1024px) {
    .ht-anchornav {
        & .ht-wrapper {
            transition: padding var(--standard), border-radius var(--standard), width var(--standard);
            will-change: padding, border-radius, width;
        }

        &-inner {
            & .ht-anchor-link {
                margin: 0 0 0 8px;
            }
        }
    
        &.sticky {
            top: 60px;
    
            & .ht-wrapper {
                width: 100vw;
                max-width: none;
                padding: 15px calc(50vw - 406px);
                border-radius: 0;
            }
        }
    }
}

@media only screen and (max-width: 812px) {
    .ht-anchornav {
        top: -18px;
        transform: none;

        & .ht-wrapper {
            padding: 0 40px;
            background-color: var(--blue);
            border-radius: 8px;
            overflow-x: auto;
            transition: padding var(--standard);
            will-change: padding;
        }

        & .ht-anchornav-dropdown {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            padding: 8px 0;
            
            & span {   
                margin: 0 8px 0 0;
                color: var(--white);
                font-size: 20px;
                line-height: 1;
            }

            & i,
            & svg {
                color: var(--white);
                transition: transform var(--standard);
                will-change: transform;
            }

            &.open {
                & i,
                & svg {
                    transform: rotate(180deg);
                }   
            }
        }

        &-inner {
            display: none;
            flex-direction: column;
            padding: 15px 0;

            &-left,
            &-right {
                display: flex;
                flex-direction: column;
                width: 100%;
                max-width: none;
                margin: 0 auto;
            }

            &-left {
                & .ht-anchor-link {
                    margin: 0 0 16px;

                    &:first-child {
                        margin: 0 0 16px;
                    }
                }
            }
            
            & .ht-anchor-link {
                &::after {
                   
                    display: none;
                  
                }
            }
        }
    }
}
