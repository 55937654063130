.ht-item-overview {
    padding: 56px 0;

    & .ht-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 36px;

        & .ht-item-card {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            width: calc(33.33% - 24px);
            text-decoration: none;
            color: var(--grey-medium);

            & .ht-item-content {
                width: 100%;
            }

            &:hover .button {
                opacity: 0.75;
            }

            & img {
                height: 350px;
                width: 100%;
                object-fit: cover;
            }

            & h3 {
                margin: 24px 0 12px;
                color: var(--grey-dark);
            }

            & ul {
                padding-left: 20px;
                margin: 0;

                & li {
                    font-size: 20px;
                }
            }

            & .button {
                color: var(--pink);
                text-decoration: underline;
                transition: all 250ms ease-in-out;
                margin: 12px 0 0;
                padding: 0;
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .ht-item-overview {
        & .ht-wrapper {
            & .ht-item-card {
                width: calc(50% - 18px);
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .ht-item-overview {
        & .ht-wrapper {
            & .ht-item-card {
                width: 100%;
            }
        }
    }
}
