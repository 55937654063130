.post-type-archive-product,
.tax-product_cat {
    & .ht-header-visual {
        &.ht-header-visual-plain {
            & .ht-wrapper {
                & .ht-header-visual-content {
                    padding: 12.28125% 0 0;
                    min-height: 0;

                    & .ht-breadcrumbs {
                        margin: 24px 0 16px;
                    }

                    & h1 {
                        margin: 0;
                        font-size: responsive 48px 64px;
                        text-align: center;
                    }
                    & .webshop-intro {
                        margin-top: 16px;
                    }
                }
            }
        }
    }

    & .ht-product-overview-options {
        padding: 24px 0 0;

        & .ht-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        & p.woocommerce-result-count {
            margin: 0;
            float: none;
        }

        & form.woocommerce-ordering {
            position: relative;
            width: auto;
            max-width: 400px;
            padding: 0;
            margin: 0;
            color: var(--grey-dark);
            font-size: responsive 16px 20px;
            line-height: 1.20833;
            font-weight: 500;
            background-color: var(--grey-medium-20);
            border: none;
            border-radius: 8px;
            outline: none;
            float: none;

            &:after {
                content: "\f078";
                position: absolute;
                top: 16px;
                right: 16px;
                font-family: var(--icons);
                font-size: responsive 24px 24px;
                line-height: 1;
            }

            & select {
                width: 100%;
                height: 100%;
                margin: 0;
                padding: 16px 16px 15px;
                color: var(--grey-dark);
                font-size: responsive 16px 20px;
                line-height: 1.20833;
                font-weight: 500;
                background-color: transparent;
                border: none;
                outline: none;
                appearance: none;

                &::-ms-expand {
                    display: none;
                }
            }
        }
    }

    & .ht-product-loop {
        padding: 40px 0;

        & ul.products {
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
            justify-content: flex-start;
            margin-left: -12px;
            margin-right: -12px;
            padding-top: 20px;

            & li.product {
                float: none;
                width: 100%;
                margin: 0 auto 16px;
                max-width: 840px;
                /* margin: 0 12px 40px; */
                border-bottom: 1px solid var(--grey-border);

                @media only screen and (max-width: 876px) {
                    max-width: 768px;
                }
                @media only screen and (max-width: 812px) {
                    max-width: 736px;
                }
                @media only screen and (max-width: 768px) {
                    max-width: 704px;
                    width: 100% !important;
                    margin: 0 auto 16px !important;
                }
                @media only screen and (max-width: 736px) {
                    max-width: calc(100vw - 48px);
                }

                & a {
                    &.woocommerce-loop-product__link {
                        display: flex;
                        /* flex-direction: column; */

                        &:hover {
                            & h2.woocommerce-loop-product__title {
                                color: var(--pink);
                            }
                        }

                        & img.size-woocommerce_thumbnail {
                            margin: 0 0 16px;
                            border-radius: 8px;
                            width: 100px;
                        }

                        & h2.woocommerce-loop-product__title {
                            padding: 0;
                            color: var(--grey-dark);
                            font-size: responsive 16px 28px;
                            line-height: 1.20833;
                            margin-top: 10px;
                            margin-left: 20px;

                            /* width:60%;
                            background-color: red; */

                            @media screen and (max-width: 460px) {
                                margin-top: 10px;
                            }
                        }

                        & span.price {
                            position: relative;
                            margin: 8px 0 0;
                            color: var(--grey-medium);
                            font-size: responsive 12px 16px;
                            line-height: 1.5;
                            position: absolute;
                            margin-left: 120px;
                            margin-top: 50px;

                            @media screen and (max-width: 460px) {
                                margin-top: 0;
                                bottom: 0;
                                margin-bottom: 26px;
                            }

                            & .woocommerce-Price-amount {
                                color: var(--blue);
                                font-size: responsive 16px 20px;
                                line-height: 1.5;

                                & .woocommerce-Price-currencySymbol {
                                    font-size: responsive 16px 20px;
                                    line-height: 1.5;
                                }
                            }

                            &:after {
                                content: "(ex BTW)";
                                margin: 0 0 0 4px;
                                font-size: responsive 8px 12px;
                            }
                        }
                    }

                    &.button.product_type_simple_booking.add_to_cart_button {
                        /* width: 100%; */
                        margin: 16px 0 0;
                        padding: 16px 40px;
                        color: var(--white);
                        font-size: responsive 12px 18px;
                        line-height: 1.22222;
                        background-color: var(--blue);
                        border-radius: 39px;
                        transition: background-color var(--standard);
                        will-change: background-color;
                        display: none;

                        &:hover {
                            background-color: var(--pink);
                        }
                    }
                }
            }
        }
    }
}
/* verberg het aantal resultaten en de sortering */
.post-type-archive-product {
    & .ht-product-overview-options {
        display: none;
    }
    & .ht-pricing {
        padding-top: 0;
    }

    & a.button.button-white-pink.medium {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-family: var(--font);
        font-weight: 700;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        outline: none !important;
        white-space: nowrap;

        padding: 16px 40px;
        font-size: responsive 12px 18px;
        line-height: 1.22222;
        border-radius: 39px;

        color: var(--pink);
        background-color: var(--white);
        transition: background-color var(--standard);
        will-change: background-color;

        &:hover {
            background-color: var(--pink);
            color: var(--white);
        }
    }
}

.tax-product_cat {
    & .ht-product-overview-options {
        max-width: 840px;
    }
}

.ht-search-full {
    display: flex;
    flex-direction: column;
    gap: 28px;
    width: 100%;
    margin-bottom: 28px;
}

.ht-search-button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 100%;

    & input#searchCampaigns {
        position: relative;
        display: block;
        width: 100%;
        max-width: 730px;
        padding: 22px 70px 22px 40px;
        margin: 0;
        color: var(--grey-dark);
        font-weight: 500;
        line-height: 1.20833;
        border: 1px solid var(--pink);
        border-radius: 42px;
        outline: none;

        &::-webkit-input-placeholder,
        &::-moz-placeholder,
        &:-ms-input-placeholder,
        &:-moz-placeholder {
            color: var(--pink);
            font-weight: 700;
        }
    }

    & div.button {
        z-index: 10;
        padding-top: 23px;
        padding-bottom: 23px;
        font-size: responsive 14px 24px;
        line-height: 1.20833;
        margin-left: -63px;
        background-color: var(--pink);
        color: var(--white);
        border-radius: 39px;

        &:hover {
            color: var(--white);
            background-color: var(--blue);
        }
    }
}
