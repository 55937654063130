header + .ht-spacer + .ht-form {
    padding-top: 72px;
}

.modal-form-wrapper {
    margin: 80px auto 0 !important;
}

.modal-form,
.ht-form {
    padding: 56px 0;
    width: 100%;

    & .gform_confirmation_message {
        margin: 0 0 16px;
        color: var(--grey-dark);
        font-family: var(--font);
        font-weight: 500;
        text-align: center;
    }

    & h2 {
        width: 100%;
        max-width: 730px;
        margin: 80px auto;
        text-align: center;
        color: var(--grey-dark);
    }

    &-wrapper {
        width: 100%;
        max-width: 730px;
        margin: 0 auto;

        & .gform_wrapper {
            margin: 0;

            &.gform_validation_error {
                & div.validation_error {
                    margin: 0 0 24px;
                    padding: 0 0 0 40px;
                    color: var(--pink);
                    font-size: responsive 12px 16px;
                    line-height: 1.5;
                    border: none;
                    text-align: left;
                }

                & .gform_body {
                    & ul.gform_fields {
                        & li.gfield {
                            &.gfield_error:not(.gf_left_half):not(.gf_right_half) {
                                width: 100% !important;
                                max-width: none !important;
                                margin: 0 0 32px !important;
                                border: none;
                                background-color: transparent;

                                & .validation_message {
                                    position: absolute;
                                    padding: 0 0 0 40px;
                                    color: var(--pink);
                                    font-size: responsive 12px 16px;
                                    line-height: 1.5;
                                    border: none;
                                    text-align: left;
                                }

                                & div.ginput_container {
                                    & input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
                                        background-color: var(--pink-20);
                                    }
            
                                    & textarea {
                                        background-color: var(--pink-20);
                                    }
                                }

                                &.gfield-recaptcha {
                                    position: absolute;
                                    max-width: 50% !important;
                                    margin: 7px auto 0 0;
            
                                    & label.gfield_label {
                                        display: none;
                                    }
                                }

                                &.gfield-select {
                                    & label.gfield_label {
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        width: auto;
                                        padding: 22px 40px;
                                        margin: 0;
                                        color: var(--white);
                                        font-size: 20px;
                                        line-height: 1.20833;
                                        font-weight: 700;
                                        background-color: var(--blue);
                                        border-radius: 42px 0 0 42px;
                                    }
        
                                    & .ginput_container_select {
                                        position: relative;
                                        width: 100%;
                                        padding: 22px 40px 22px 0;
                                        margin: 0;
                                        color: var(--grey-dark);
                                        font-size: 20px;
                                        line-height: 1.20833;
                                        font-weight: 700;
                                        background-color: var(--grey-medium-20);
                                        border: none;
                                        border-radius: 42px;
                                        outline: none;
        
                                        &:after {
                                            content: '\f078';
                                            position: absolute;
                                            top: 24px;
                                            right: 40px;
                                            font-family: var(--icons);
                                            font-size: 20px;
                                            line-height: 1;
                                        }
            
                                        & select {
                                            width: 100%;
                                            height: 100%;
                                            margin: 0;
                                            padding: 0;
                                            color: var(--grey-dark);
                                            font-size: 20px;
                                            line-height: 1.20833;
                                            font-weight: 700;
                                            background-color: transparent;
                                            border: none;
                                            outline: none;
                                            appearance: none;
        
                                            &::-ms-expand {
                                                display: none;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            & .gform_body {
                & ul.gform_fields {
                    position: relative;

                    & li.gfield {
                        position: relative;
                        margin: 0 0 32px;
                        padding: 0;

                        &.field_description_above {
                            & .gfield_description:not(.validation_message) {
                                padding: 0 40px 22px 40px;
                            }
                        }

                        &.field_description_below {
                            & .gfield_description:not(.validation_message) {
                                padding: 22px 40px 0 40px;
                            }
                        }

                        &.gfield_error:not(.gf_left_half):not(.gf_right_half) {
                            width: 100% !important;
                            max-width: none !important;
                            margin: 0 0 32px !important;
                            border: none;
                            background-color: transparent;

                            & .validation_message {
                                position: absolute;
                                padding: 0 0 0 40px;
                                color: var(--pink);
                                font-size: responsive 12px 16px;
                                line-height: 1.5;
                                border: none;
                                text-align: left;
                            }

                            & div.ginput_container {
                                & input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
                                    background-color: var(--pink-20);
                                }
        
                                & textarea {
                                    background-color: var(--pink-20);
                                }
                            }

                            &.gfield-recaptcha {
                                position: absolute;
                                max-width: 50% !important;
                                margin: 7px auto 0 0;
        
                                & label.gfield_label {
                                    display: none;
                                }
                            }

                            &.gfield-select {
                                & label.gfield_label {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: auto;
                                    padding: 22px 40px;
                                    margin: 0;
                                    color: var(--white);
                                    font-size: 20px;
                                    line-height: 1.20833;
                                    font-weight: 700;
                                    background-color: var(--blue);
                                    border-radius: 42px 0 0 42px;
                                }
    
                                & .ginput_container_select {
                                    position: relative;
                                    width: 100%;
                                    padding: 22px 40px 22px 0;
                                    margin: 0;
                                    color: var(--grey-dark);
                                    font-size: 20px;
                                    line-height: 1.20833;
                                    font-weight: 700;
                                    background-color: var(--grey-medium-20);
                                    border: none;
                                    border-radius: 42px;
                                    outline: none;
    
                                    &:after {
                                        content: '\f078';
                                        position: absolute;
                                        top: 24px;
                                        right: 40px;
                                        font-family: var(--icons);
                                        font-size: 20px;
                                        line-height: 1;
                                    }
        
                                    & select {
                                        width: 100%;
                                        height: 100%;
                                        margin: 0;
                                        padding: 0;
                                        color: var(--grey-dark);
                                        font-size: 20px;
                                        line-height: 1.20833;
                                        font-weight: 700;
                                        background-color: transparent;
                                        border: none;
                                        outline: none;
                                        appearance: none;
    
                                        &::-ms-expand {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }

                        & label.gfield_label {
                            position: absolute;
                            z-index: 2;
                            top: 0;
                            left: 0;
                            width: auto;
                            padding: 22px 40px;
                            margin: 0;
                            color: var(--grey-medium);
                            font-size: 20px;
                            line-height: 1.20833;
                            font-weight: 700;
                            border-radius: 42px 0 0 42px;
                            transition: color var(--standard), background-color var(--standard);
                            will-change: color, background-color;

                            &.not-empty {
                                color: var(--white);
                                background-color: var(--blue);
                            }

                            & span.gfield_required {
                                margin: 0 0 0 4px;
                                color: var(--pink);
                                font-size: 20px;
                                line-height: 1.20833;
                                font-weight: 700;
                            }
                        }
                        
                        & div.ginput_container {
                            position: relative;
                            z-index: 1;
                            margin: 0;

                            & input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
                                width: 100%;
                                padding: 22px 40px 22px 0;
                                margin: 0;
                                color: var(--grey-dark);
                                font-size: 20px;
                                line-height: 1.20833;
                                font-weight: 700;
                                background-color: var(--grey-medium-20);
                                border: none;
                                border-radius: 42px;
                                outline: none;
                            }

                            & textarea {
                                width: 100%;
                                min-height: 328px;
                                padding: 22px 40px;
                                margin: 0;
                                color: var(--grey-dark);
                                font-size: 20px;
                                line-height: 1.20833;
                                font-weight: 700;
                                background-color: var(--grey-medium-20);
                                border: none;
                                border-radius: 8px;
                                outline: none;
                            }
                        }

                        &.gfield-textarea {
                            & label.gfield_label {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                padding: 40px 40px 34px;
                                margin: 0;
                                color: var(--grey-medium);
                                font-size: 20px;
                                line-height: 1.20833;
                                font-weight: 700;
                                border-radius: 8px 8px 0 0;
                                text-align: left;
                                transition: color var(--standard), background-color var(--standard);
                                will-change: color, background-color;

                                &.not-empty {
                                    color: var(--white);
                                    background-color: var(--blue);
                                }
                            }

                            & .ginput_container_textarea{
                                & textarea {
                                    padding: 143px 40px 64px;
                                    min-height: 328px;
                                }
                            }
                            
                            
                            
                            
                        }
                        
                       

                        &.gfield-recaptcha {
                            position: absolute;
                            max-width: 50% !important;
                            margin: 7px auto 0 0;
    
                            & label.gfield_label {
                                display: none;
                            }
                        }

                        &.gfield-select {
                            & label.gfield_label {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: auto;
                                padding: 22px 40px;
                                margin: 0;
                                color: var(--white);
                                font-size: 20px;
                                line-height: 1.20833;
                                font-weight: 700;
                                background-color: var(--blue);
                                border-radius: 42px 0 0 42px;
                            }

                            & .ginput_container_select {
                                position: relative;
                                width: 100%;
                                padding: 0;
                                margin: 0;
                                color: var(--grey-dark);
                                font-size: 20px;
                                line-height: 1.20833;
                                font-weight: 700;
                                background-color: var(--grey-medium-20);
                                border: none;
                                border-radius: 42px;
                                outline: none;

                                &:after {
                                    content: '\f078';
                                    position: absolute;
                                    top: 24px;
                                    right: 40px;
                                    font-family: var(--icons);
                                    font-size: 20px;
                                    line-height: 1;
                                }
    
                                & select {
                                    width: 100%;
                                    height: 100%;
                                    margin: 0;
                                    padding: 22px 40px 22px 0;
                                    color: var(--grey-dark);
                                    font-size: 20px;
                                    line-height: 1.20833;
                                    font-weight: 700;
                                    background-color: transparent;
                                    border: none;
                                    outline: none;
                                    appearance: none;

                                    &::-ms-expand {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }

                & .gform_validation_container,
                & .gfield_visibility_hidden {
                    margin: 0 !important;
                    padding: 0 !important;
                    font-size: 0 !important;
                    line-height: 0 !important;

                    & label,
                    & input {
                        margin: 0 !important;
                        padding: 0 !important;
                        font-size: 0 !important;
                        line-height: 0 !important;
                    }
                }
            }

            & .gform_footer {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                max-width: 50%;
                padding: 0;
                margin: 0 0 0 auto;

                & input.gform_button[type=submit] {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0;
                    padding: 16px 40px;
                    color: var(--white);
                    font-family: var(--font);
                    font-size: responsive 20px 20px;
                    font-weight: 700;
                    text-align: center;
                    text-decoration: none;
                    text-transform: uppercase;
                    line-height: 1.22222;
                    background-color: var(--grey-medium);
                    border: none;
                    border-radius: 46px;
                    outline: none;
                    transition: background-color var(--standard), color var(--standard);
                    will-change: color, background-color;
                    cursor: pointer;
            
                    &:hover {
                        color: var(--white);
                        background-color: var(--pink);
                    }
                }
            }
        }
    }
    


}

@media only screen and (max-width: 876px) {
    .modal-form,
    .ht-form {
        & h2 {
            max-width: 100%;
        }

        &-wrapper {
            max-width: 100%;
        }
    }
}

@media only screen and (max-width: 736px) {
    .ht-form {
        & .gform_wrapper {
            & .gform_body {
                & ul.gform_fields {
                    & li.gfield {
                        &.gfield-recaptcha:not(.gf_left_half):not(.gf_right_half) {
                            position: relative !important;
                            max-width: none !important;
                            margin: 7px 0;

                            & > .ginput_recaptcha {
                                & > div {
                                    margin: 0 0 0 auto;
                                }
                            }
                        }
                    }
                }
            }

            & .gform_footer {
                max-width: none;
                padding: 0;
                margin: 24px 0 0 auto;
            }
        }
    }
}

@media only screen and (max-width: 568px) {
    .ht-form {
        padding: 24px 0;
    }
}
