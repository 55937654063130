.ht-full-cta-2-col {
    position: relative;
    z-index: 1;
    width: 100vw;
    height: 100vh;

    &:after {
        content: '';
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: var(--black-50);
    }

    & .ht-video-background {
        position: fixed;
        top: 50%;
        left: 50%;
        width: auto;
        height: auto;
        min-width: 100vw;
        min-height: 100vh;
        transform: translate(-50%, -50%);
    }

    & h1 {
        position: absolute;
        z-index: 3;
        top: 33.26923%;
        left: 50%;
        width: 100%;
        margin: 0 auto;
        padding: 0;
        color: var(--white);
        font-family: var(--font);
        font-weight: 700;
        font-size: responsive 28px 48px;
        line-height: 1.16667;
        text-align: center;
        transform: translateX(-50%);
    }

    & .ht-wrapper {
        z-index: 3;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 11.7307% 0 0;

        & .ht-full-cta-2-col-left {
            width: calc(50% + 1px);
        }

        & .ht-full-cta-2-col-right {
            width: calc(50% - 1px);
            border-left: 1px solid var(--white-50);

            & .button-pink  {
                margin-top: 15px;
            }
        }

        & .ht-full-cta-2-col-left,
        & .ht-full-cta-2-col-right {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 16px;
            opacity: 1;
            transition: opacity var(--standard);

            &.inactive {
                opacity: 0.5;
            }

            & .button:hover {
                & .button-white-pink  {
                    color: var(--white);
                    background-color: var(--pink);
                }
            
                & .button-white-blue  {
                    color: var(--white);
                    background-color: var(--blue);
                }
            }

            & h2 {
                margin: 0 0 18px;
                color: var(--white);
                font-family: var(--font);
                font-weight: 700;
                font-size: responsive 24px 32px;
                line-height: 1.15625;
                text-align: center;
            }

            & p.ht-full-cta-2-col-text {
                margin: 0 0 32px;
                color: var(--white);
                font-family: var(--font);
                font-weight: 500;
                font-size: responsive 16px 24px;
                line-height: 1.15625;
                text-align: center;
            }
        }
    }
}

@media only screen and (orientation: landscape) and (max-width: 812px) {
    .ht-full-cta-2-col {
        & h1 {
            top: 30%;
        }
    
        & .ht-wrapper {
            & .ht-full-cta-2-col-left,
            & .ht-full-cta-2-col-right {
                margin: 56px 0 0;
                padding: 8px 16px;
    
                & h2 {
                    margin: 0 0 16px;
                }
    
                & p.ht-full-cta-2-col-text {
                    margin: 0 0 24px;
                }
            }
        }
    }
}

@media only screen and (orientation: landscape) and (max-width: 568px) {
    .ht-full-cta-2-col {
        & h1 {
            top: 28%;
        }
    }
}

@media only screen and (min-height: 813px) {
    .ht-full-cta-2-col {
        & .ht-wrapper {
            & .ht-full-cta-2-col-left {
                margin-bottom: 63px;
            }
        }
    }
}

@media only screen and (orientation: portrait) and (max-height: 812px) {
    .ht-full-cta-2-col {
        & .ht-wrapper {
            flex-direction: column;

            & h1 {
                position: relative;
                top: 0;
                left: 0;
                padding: 0 0 24px;
                transform: none;
            }

            & .ht-full-cta-2-col-right {
                border-top: 1px solid var(--white-50);
                border-left: none;
            }
    
            & .ht-full-cta-2-col-left,
            & .ht-full-cta-2-col-right {
                width: 100%;

                & h2 {
                    margin: 0 0 16px;
                }
    
                & p.ht-full-cta-2-col-text {
                    margin: 0 0 24px;
                }
            }
        }
    }
}

@media only screen and (orientation: portrait) and (max-height: 600px) {
    .ht-full-cta-2-col {
        & .ht-wrapper {
            & h1 {
                padding: 32px 0 0;
            }
        }
    }
}
