.ht-latestblogs {
    padding: 56px 0;

    & h2 {
        max-width: 840px;
        margin: 0 auto 40px;
        color: var(--grey-dark);
        text-align: left;
    }

    & .ht-blog-slider-wrapper {
        & .slick-list {
            padding: 0 calc(50vw - 660px);
        }

        & .slick-track {
            display: flex;
            align-items: stretch;
            margin: 56px 0 0;
        }

        & .ht-card-slider-card {
            position: relartive;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
            padding: 56px;
            margin: 0 20px;
            background-color: var(--blue);
            border-radius: 8px;
            text-decoration: none;
            outline: none;

            &.slick-slide {
                height: auto;
            }

            & h3 {
                margin: 0 0 24px;
                color: var(--white);
            }

            & .ht-card-slider-date {
                margin: 0 0 8px;
                color: var(--white);
                font-size: responsive 12px 16px;
                line-height: 1.5;
            }

            & .text {
                margin: 0 0 56px;
                color: var(--white);
                font-size: responsive 16px 24px;
                line-height: 1.5;
            }

            & div.button {
                margin: auto 0 0;
            }

            &:hover {
                & .button-white-pink {
                    color: var(--white);
                    background-color: var(--pink);
                }
            }
        }

        & ul.slick-dots {
            position: absolute;
            z-index: 4;
            left: calc(50vw - 420px);
            top: 0;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 0;
            margin: 0;
            list-style: none;
    
            & li {
                width: 32px;
                height: 6px;
                margin: 0 8px 0 0;
                font-size: 0;
                line-height: 0;
                outline: none;
                transition: width var(--standard);
                will-change: width;
    
                & button {
                    width: 32px;
                    height: 6px;
                    font-size: 0;
                    line-height: 0;
                    background: var(--grey-medium);
                    border: 0;
                    border-radius: 3px;
                    outline: none;
                    cursor: pointer;
                    transition: width var(--standard), background var(--standard);
                    will-change: width, background;
                }
    
                &.slick-active {
                    & button {
                        background: var(--pink);
                    }    
                }
            }
        }
    }
}

@media only screen and (max-width: 1366px) {
    .ht-latestblogs {
        & .ht-blog-slider-wrapper {
            & .slick-list {
                padding: 0 calc(50vw - 610px);
            }
        }
    }
}

@media only screen and (max-width: 1280px) {
    .ht-latestblogs {
        & .ht-blog-slider-wrapper {
            & .slick-list {
                padding: 0 calc(50vw - 532px);
            }
        }
    }
}

@media only screen and (max-width: 1180px) {
    .ht-latestblogs {
        & .ht-blog-slider-wrapper {
            & .slick-list {
                padding: 0 calc(50vw - 500px);
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .ht-latestblogs {
        & .ht-blog-slider-wrapper {
            & .slick-list {
                padding: 0 calc(50vw - 426px);
            }

            & ul.slick-dots {
                left: calc(50vw - 406px);
            }
        }
    }
}

@media only screen and (max-width: 876px) {
    .ht-latestblogs {
        & .ht-blog-slider-wrapper {
            & .slick-list {
                padding: 0 calc(50vw - 404px);
            }

            & ul.slick-dots {
                left: calc(50vw - 384px);
            }
        }
    }
}

@media only screen and (max-width: 812px) {
    .ht-latestblogs {
        & .ht-blog-slider-wrapper {
            & .slick-list {
                padding: 0 calc(50vw - 388px);
            }

            & ul.slick-dots {
                left: calc(50vw - 368px);
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .ht-latestblogs {
        & .ht-blog-slider-wrapper {
            & .slick-list {
                padding: 0 calc(50vw - 372px);
            }

            & ul.slick-dots {
                left: calc(50vw - 352px);
            }
        }
    }
}

@media only screen and (max-width: 736px) {
    .ht-latestblogs {
        & .ht-blog-slider-wrapper {
            & .slick-list {
                padding: 0 4px;
            }

            & ul.slick-dots {
                left: 24px;
            }
        }
    }
}

@media only screen and (max-width: 667px) {
    .ht-latestblogs {
        padding: 48px 0;
    }
}

@media only screen and (max-width: 568px) {
    .ht-latestblogs {
        padding: 24px 0;
    }
}

@media only screen and (max-width: 414px) {
    .ht-latestblogs {
        & .ht-blog-slider-wrapper {
            & .slick-list {
                padding: 0;
            }

            & .ht-card-slider-card {
                margin: 0 16px;
            }

            & ul.slick-dots {
                left: 16px;
            }
        }
    }
}

@media only screen and (max-width: 375px) {
    .ht-latestblogs {
        & .ht-blog-slider-wrapper {
            & .ht-card-slider-card {
                padding: 24px;
            }
        }
    }
}
