.ht-cc-cookies {
    max-width: 1280px;
    margin: 56px auto;
    padding: 56px;
    background: var(--white);
    box-shadow: var(--box-shadow);
}

.ht-cc-cookies .ht-cookie {
    width: 100%;   
}

.ht-cookie .ht-cookie__link {
    color: var(--grey-light);
    text-decoration: none;
}

.ht-cookie hr.ht-cookie__line {
    border: none;
    border-bottom: 1px solid var(--grey-light);
}

.ht-cookie .button-group {
    margin-bottom: -36px;
    text-align: center;

    & a.ht-cookie-button {
        box-shadow: var(--box-shadow) !important;

        &:before {
            content: '\f00c';
            display: inline-block;
            width: 0;
            margin-left: 8px;
            font-family: var(--icons);
            font-size: responsive 14px 14px !important;
            line-height: 1.235;
            opacity: 0;
            transition: all var(--standard);
        }

        &.selected {
            background-color: var(--pink);
            color:white;
        }

        &:first-child {
            margin-right: 16px;
        }

        &.white {
            & span {
                color: var(--blue) !important;
                transition: all var(--standard);
            }

            &:hover {
                & span {
                    color: var(--white) !important;
                }   
            }

            &:before {
                color: var(--blue) !important;
            }
        }

        &.blue-dark {
            & span {
                color: var(--white) !important;
            }

            &:before {
                color: var(--white) !important;
            }
        }
    }
}

.ht-cookie__save-cookie {
    &.blue-dark {
        & span {
            color: var(--white) !important;
        }

        &:before {
            color: var(--white) !important;
        }
    }
}

.ht-cookie .button-right {
    margin: 56px 0 -36px;
    text-align: right;
}

.ht-cookie-button {
    &:before {
        content: '\f00c';
        position: absolute;
        z-index: 2;
        display: inline-block;
        right: 24px;
        width: 0;
        font-family: var(--icons);
        font-size: responsive 12px 16px !important;
        line-height: 1.235;
        opacity: 0;
        transition: all var(--standard);
    }

    &.selected {
        background-color: var(--pink);
        color:white;
    }
    
    &.ht-cookie__standard {
        margin-right: 12px;

        &:before {
            color: var(--blue);
        }

        &:hover {
            &:before {
                color: var(--white);
            }  
        }
    }

    &.ht-cookie__custom {
        &:before {
            color: var(--blue);
        }

        &:hover {
            &:before {
                color: var(--white);
            }
        }
    }
}

.ht-cookie .ht-cookie__bar {
    position: fixed;
    bottom: 16px;
    left: 16px;
    right: 16px;
    z-index: 999;
    width: calc(100% - 32px);
    padding: 32px 16px;
    margin: 0;
    pointer-events: auto;
    background-color: var(--white);
    box-shadow: var(--box-shadow);
    opacity: 1;
    transition: all 300ms ease-in-out;
}

.ht-cookie .ht-cookie__wrapper {
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
}

.ht-cookie .ht-cookie__bar .ht-cookie__text {
    max-height: 100%;
    padding: 0 32px 0 0;
    margin: 0;
    overflow-y: auto;
    font-size: responsive 12px 16px !important;
    line-height: 1.42857;
    color: var(--blue);
}

.ht-cookie .ht-cookie__bar .ht-cookie__change-cookies {
    color: var(--pink);
    padding: 4px 0 4px;
    white-space: nowrap;
    word-break: keep-all;
}

.ht-cookie .ht-cookie__bar .ht-cookie__agree {
    margin-left: 16px;
    font-size: 17px;
}

.ht-cookie .ht-cookie__bar .ht-cookie__agree:hover {
    cursor: pointer;
}

@media only screen and (max-width: 1366px) {
    .ht-cc-cookies {
        max-width: 1180px;
    }
}

@media only screen and (min-width: 1281px) {
    .ht-cookie .ht-cookie__wrapper {
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;
    }
}

@media only screen and (max-width: 1280px) {
    .ht-cc-cookies {
        max-width: 1024px;
    }

    .ht-cookie .ht-cookie__wrapper {
        width: 100%;
        max-width: 1024px;
        margin: 0 auto;
    }
}

@media only screen and (max-width: 1180px) {
    .ht-cc-cookies {
        max-width: 960px;
    }
}

@media only screen and (max-width: 1024px) {
    .ht-cc-cookies {
        max-width: 812px;
    }

    .ht-cookie .ht-cookie__wrapper {
        width: 100%;
        max-width: 1024px;
        margin: 0 auto;
    }
}


@media only screen and (max-width: 876px) {
    .ht-cc-cookies {
        max-width: 768px;
    }
}

@media only screen and (max-width: 812px) {
    .ht-cc-cookies {
        max-width: 736px;
    }
}

@media only screen and (max-width: 768px) {
    .ht-cc-cookies {
        max-width: 704px;
    }
}

@media only screen and (max-width: 736px) {
    .ht-cc-cookies {
        max-width: calc(100vw - 48px);
    }
}

@media only screen and (max-width: 320px) {
    .ht-cc-cookies {
        max-width: calc(100vw - 32px);
    }
}

@media only screen and (max-width: 600px) and (orientation: portrait) {
    .ht-cookies {
        width: calc(100% - 32px);
        margin: 0 auto 56px;
        padding: 24px 16px 56px;
    }

    .ht-cookie .ht-cookie__wrapper {
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .ht-cookie .ht-cookie__bar .ht-cookie__agree {
        margin: 16px 0 0;
    }
}

@media only screen and (max-width: 600px) and (orientation: landscape) {
    .ht-cookies {
        width: calc(100% - 32px);
        margin: 0 auto;
        padding: 88px 16px 56px;
    }

    .ht-cookie .ht-cookie__wrapper {
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .ht-cookie .ht-cookie__bar .ht-cookie__text {
        overflow: scroll;
    }

    .ht-cookie .ht-cookie__bar .ht-cookie__agree {
        margin: 16px 0 0;
    }
}

@media only screen and (max-width: 380px) {
    .ht-cookie .ht-cookie__bar .ht-cookie__text {
        overflow: scroll;
    }

    .ht-cookie .ht-cookie__bar .ht-cookie__agree {
        margin: 16px 0 0;
    }

    .ht-cookie .button-group {

        & .ht-cookie__button {
            display: block;

            &:first-child {
                margin: 0 0 16px;
            }
        }
    }

    .ht-cookie .ht-cookie-button.ht-cookie__save-cookie {
        display: block;
    }
}

/* Cookie settings */

.ht-cookie .ht-cookie__settings h4 {
    font-family: var(--font);
    font-weight: 400;
    margin: 60px 0 36px;
    text-align: center;
}

.ht-cookie .ht-cookie__settings .ht-cookie__text .ht-cookie__paragraph,
.ht-cookie .ht-cookie__settings .ht-cookie__option-text .ht-cookie__paragraph {
        margin: 0 0 6px;
}

.ht-cookie .ht-cookie__settings .ht-cookie__text {
    margin: 0 0 32px;
}

.ht-cookie .ht-cookie__settings .ht-cookie__option {
    display: -ms-flex;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 56px 40px;
}

.ht-cookie .ht-cookie__settings .ht-cookie__save-cookie {
    margin-top: 16px;
}

.ht-cookie .ht-cookie__toggle-wrapper {
    width: 88px;
    margin: 0 24px 0 0;
    text-align: right;
}

.ht-cookie .ht-cookie__settings .ht-cookie__toggle-wrapper input,
.ht-cookie .ht-cookie__settings .ht-cookie__toggle-wrapper label,
.ht-cookie .ht-cookie__settings .ht-cookie__toggle-wrapper span {
    margin: 0;
}

.ht-cookie input.ht-cookie__toggle {
    max-width: 0;
    max-height: 0;
    opacity: 0;
    position: absolute;
}

.ht-cookie input.ht-cookie__toggle+span+label.ht-cookie__label {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 14px;
    text-indent: -5000px;
    border-radius: 7px;
    background-color: var(--grey-medium-35);
    cursor: pointer;
}
.ht-cookie input.ht-cookie__toggle+span:before {
    content: 'Uit';
    padding-right: 8px;
    opacity: 0.6;
    font-weight: 400;
    color: var(--black);
    letter-spacing: 0;
    text-align: right;
    line-height: 27px;
}

.ht-cookie input.ht-cookie__toggle:checked + span:before {
    content: 'Aan';
}
.ht-cookie input.ht-cookie__toggle + span + label.ht-cookie__label:before,
.ht-cookie input.ht-cookie__toggle + span + label.ht-cookie__label:after {
    content: '';
    position: absolute;
    top: -3px;
    left: 0;
    display: block;
    opacity: 1;
    width: 20px;
    height: 20px;
    border-radius: 15px;
    background-color: var(--blue);
    transition: all 250ms ease-in-out;
}

.ht-cookie input.ht-cookie__toggle + span + label.ht-cookie__label:after {
    opacity: 0;
    background-color: var(--pink);
    transition: all 250ms ease-in-out;
}
.ht-cookie input.ht-cookie__toggle.disabled + span + label {
    opacity: 0.4;
}
.ht-cookie input.ht-cookie__toggle.disabled + span + label.ht-cookie__label:after {
    background: var(--grey-medium-50);
}
.ht-cookie input.ht-cookie__toggle:checked + span + label.ht-cookie__label:before,
.ht-cookie input.ht-cookie__toggle:checked + span + label.ht-cookie__label:after {
    left: 14px;
    opacity: 1;
}
.ht-cookie input.ht-cookie__toggle:checked + span + label.ht-cookie__label:before {
    opacity: 0;
    transition: left 250ms ease-in-out,
    opacity 10ms 240ms ease-in-out;
}

.ht-cookie .ht-cookie__settings .ht-cookie__snackbar {
    padding: 6px 16px;
    margin: 52px 0 0;
    color: var(--black);
    background-color: var(--grey-light);
    box-shadow: var(--box-shadow);
    opacity: 0;
}

.ht-cookie .ht-cookie__settings .ht-cookie__snackbar--show {
    opacity: 1;
    animation: fade 500ms 2000ms forwards ease-in-out;
}

@keyframes fade {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
