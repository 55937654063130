@media only screen and (min-width: 1025px) and (max-width: 1360px) and (min-height: 600px) and (max-height: 800px) {
    /* base settings */
    body {
        font-range: 1025px 1360px;
    }

    h1, .h1 {
        font-size: responsive 38px 46px;
    
        &.big {
            font-size: responsive 58px 72px;
        }
    
        &.xl {
            font-size: responsive 62px 70px;
        }
    }
    
    h2, .h2 {
        font-size: responsive 24px 32px;
    
        &.big {
            font-size: responsive 40px 48px;
        }
    
        &.xl {
            font-size: responsive 62px 70px;
        }
    }
    
    h3, .h3 {
        font-size: responsive 18px 24px;
    }
    
    h4, .h4 {
        font-size: responsive 14px 20px;
    }
    
    h5, .h5 {
        font-size: responsive 12px 18px;
    }
    
    h6, .h6 {
        font-size: responsive 10px 16px;
    }
    
    p,
    input,
    textarea,
    select,
    label,
    td,
    th,
    blockquote,
    ul,
    ol,
    span {
        font-size: responsive 16px 20px;
    
        &.small {
            font-size: responsive 12px 18px;
        }
    }
    
    /* header & visuals */
    header {
        & .ht-logo {
            height: 90px;
    
            & img {
                height: 90px;
            }
        }
    }

    header {
        & nav.main-menu-container {
            & > ul.main-menu {
                & > li {
                    & > div.sub-menu-container {
                        & > div.sub-menu-wrapper {
                            padding: 72px 64px 112px 24px;

                            & > ul.sub-menu {
                    
                                & > span.sub-menu-topic {
                                    font-size: responsive 12px 12px;
                                }
                    
                                & > li {
                                    & > a {
                                        font-size: responsive 12px 12px;
                    
                                        & > i,
                                        & > svg {
                                            font-size: responsive 12px 12px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .ht-header-visual {
        &-plain {
            & .ht-header-visual-content {
                padding: 18.28125% 0 9.375%;

                & h1 {
                    font-size: responsive 40px 70px;
                }
            }
        }
    }

    .ht-header-visual {
        &-blog {  
            & .ht-header-visual-content {
                padding: 18.28125% 0 9.375%;

                & h1 {
                    font-size: responsive 40px 70px;
                }
            }
        }
    }

    /* Search cities component */
    .ht-search-cities {
        & .ht-search-cities-container {
            padding: 40px 56px;
        }
    }

    /* contact page */
    .ht-contact {
        &-left {
            & .ht-contact-left-1,
            & .ht-contact-left-2,
            & .ht-contact-left-3 {
                & p {
                    font-size: responsive 20px 24px;
                }
        
                & a:not(.button) {
                    font-size: responsive 20px 24px;
                }
            }
        }
    
        &-right {
           width: calc(58.59375% - 20px); 
        }
    }
    
    /* contact form */
    .ht-contact-right {
        & .gform_wrapper {
            &.gform_validation_error {
                & div.validation_error {
                    padding: 0 0 0 36px;
                    margin: 0 0 16px;
                }
    
                & .gform_body {
                    & ul.gform_fields {
                        & li.gfield {
                            &.field_description_above {
                                & .gfield_description:not(.validation_message) {
                                    padding: 0 36px 18px 36px;
                                }
                            }
    
                            &.field_description_below {
                                & .gfield_description:not(.validation_message) {
                                    padding: 18px 36px 0 36px;
                                }
                            }
    
                            &.gfield_error:not(.gf_left_half):not(.gf_right_half) {
                                & .validation_message {
                                    padding: 0 0 0 36px;
                                }
                            }
                        }
                    }
                }
            }

            & .gform_body {
                & ul.gform_fields {
                    & li.gfield {
                        & label.gfield_label {
                            padding: 18px 36px;
                            font-size: responsive 16px 20px;
                            border-radius: 30px 0 0 30px;

                            & span.gfield_required {
                                font-size: responsive 16px 20px;
                            }
                        }
                        
                        & div.ginput_container {
                            & input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
                                padding: 18px 36px 18px 0;
                                font-size: responsive 16px 20px;
                                border-radius: 30px;
                            }
    
                            & textarea {
                                padding: 18px 36px;
                                font-size: responsive 16px 20px;
                            }
                        }
    
                        &.gfield-textarea {
                            & label.gfield_label {
                                padding: 36px 36px 32px;
                                font-size: responsive 16px 20px;
                                border-radius: 8px 8px 0 0;
                            }
    
                            & .ginput_container_textarea {
                                & textarea {
                                    padding: 130px 36px 56px;
                                }
                            }
                        }
                    }
                }
            }
    
            & .gform_footer {
                & input.gform_button[type=submit] {
                    padding: 16px 40px;
                    font-size: responsive 20px 28px;
                    border-radius: 33px;
                }
            }
        }
    }
    
    /* modal form, regular form */
    .modal-form,
    .ht-form {
        & h2 {
            margin: 64px auto;
        }

        &-wrapper {
            & .gform_wrapper {
                &.gform_validation_error {
                    & div.validation_error {
                        margin: 0 0 24px;
                        padding: 0 0 0 32px;
                    }

                    & .gform_body {
                        & ul.gform_fields {
                            & li.gfield {
                                &.gfield_error:not(.gf_left_half):not(.gf_right_half) {
                                    & .validation_message {
                                        padding: 0 0 0 36px;
                                    }

                                    &.gfield-select {
                                        & label.gfield_label {
                                            padding: 18px 36px;
                                            font-size: responsive 16px 20px;
                                            border-radius: 30px 0 0 30px;
                                        }
            
                                        & .ginput_container_select {
                                            padding: 18px 36px 18px 0;
                                            font-size: responsive 16px 20px;
                                            border-radius: 30px;
            
                                            &:after {
                                                right: 36px;
                                                font-size: responsive 16px 20px;
                                            }
                
                                            & select {
                                                font-size: responsive 16px 20px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                & .gform_body {
                    & ul.gform_fields {
                        & li.gfield {
                            &.field_description_above {
                                & .gfield_description:not(.validation_message) {
                                    padding: 0 36px 18px 36px;
                                }
                            }

                            &.field_description_below {
                                & .gfield_description:not(.validation_message) {
                                    padding: 18px 36px 0 36px;
                                }
                            }

                            &.gfield_error:not(.gf_left_half):not(.gf_right_half) {
                                & .validation_message {
                                    padding: 0 0 0 36px;
                                }

                                &.gfield-select {
                                    & label.gfield_label {
                                        padding: 18px 36px;
                                        font-size: responsive 16px 20px;
                                        border-radius: 30px 0 0 30px;
                                    }
        
                                    & .ginput_container_select {
                                        padding: 18px 36px 18px 0;
                                        font-size: responsive 16px 20px;
                                        border-radius: 30px;
        
                                        &:after {
                                            right: 36px;
                                            font-size: responsive 16px 20px;
                                        }
            
                                        & select {
                                            font-size: responsive 16px 20px;
                                        }
                                    }
                                }
                            }

                            & label.gfield_label {
                                padding: 18px 36px;
                                font-size: responsive 16px 20px;
                                border-radius: 30px 0 0 30px;

                                & span.gfield_required {
                                    font-size: responsive 16px 20px;
                                }
                            }
                            
                            & div.ginput_container {
                                & input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
                                    padding: 18px 36px 18px 0;
                                    font-size: responsive 16px 20px;
                                    border-radius: 30px;
                                }

                                & textarea {
                                    padding: 18px 36px;
                                    font-size: responsive 16px 20px;
                                }
                            }

                            &.gfield-textarea {
                                & label.gfield_label {
                                    padding: 36px 36px 30px;
                                    font-size: responsive 16px 20px;
                                }

                                & .ginput_container_textarea {
                                    & textarea {
                                        padding: 130px 36px 56px;
                                    }
                                }
                            }

                            &.gfield-select {
                                & label.gfield_label {
                                    padding: 18px 36px;
                                    font-size: responsive 16px 20px;
                                    border-radius: 30px 0 0 30px;
                                }

                                & .ginput_container_select {
                                    font-size: responsive 16px 20px;
                                    border-radius: 30px;

                                    &:after {
                                        right: 36px;
                                        font-size: responsive 16px 20px;
                                    }
        
                                    & select {
                                        padding: 18px 36px 18px 0;
                                        font-size: responsive 16px 20px;
                                    }
                                }
                            }
                        }
                    }
                }

                & .gform_footer {
                    & input.gform_button[type=submit] {
                        padding: 16px 40px;
                        font-size: responsive 20px 28px;
                        border-radius: 33px;
                    }
                }
            }
        }
    }

    /* biglinks component */
    .ht-biglinks {  
        & .ht-biglinks-container {
            & a {
                padding: 32px 0;
    
                & span {
                    font-size: responsive 24px 32px;
                }
    
                & i,
                & svg {
                    font-size: responsive 24px 32px;
                }
            }
        }
    }

    /* 2-col component */
    .ht-2-col {
        &-left {
            & h1, & h2, & h3, & h4, & h5, & h6,
            & .h1, & .h2, & .h3, & .h4, & .h5, & .h6 {
                margin-right: 9.03225%;
            }
    
            & p, & input, & textarea, & select, & label, & td, & th, & blockquote, & ul, & ol, & span {
                margin-right: 9.03225%;
            }
        }

        &-right {
            & h1, & h2, & h3, & h4, & h5, & h6,
            & .h1, & .h2, & .h3, & .h4, & .h5, & .h6 {
                margin-left: 9.03225%;
            }
    
            & p, & input, & textarea, & select, & label, & td, & th, & blockquote, & ul, & ol, & span {
                margin-left: 9.03225%;
            }
        }
    }

    /* contactperson component */
    .ht-contactperson {
        & .ht-wrapper {
            & .ht-contact-container {
                & .ht-contactperson-info {
                    width: 50%;
                }

                & .ht-contactperson-number {
                    width: 50%;

                    & a {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 16px 40px;
                        font-size: responsive 24px 32px;
                    }
                }
            }
        }
    }

    /* contactperson component */
    .ht-quote {
        & .ht-quote-container {
            & blockquote {
                font-size: responsive 24px 32px;
            }
        }
    }

    /* content-box component */
    .ht-content-box {
        & .ht-content-box-container {
            padding: 40px 40px 44px;
        }
    }

    /* cards component */
    .ht-cards-block {
        & .ht-cards {
            & .ht-card {
                & .ht-card-content {
                    padding: 24px 24px 32px;
                }
            }
        }
    }

    /* card-slider component */
    .ht-card-slider {
        & .ht-card-slider-wrapper {
            & .slick-track {
                margin: 40px 0 0;
            }
    
            & .ht-card-slider-card {
                padding: 32px 24px;
            }
        }
    }

    /* maps-booking component */
    .ht-maps-booking {
        & .ht-maps-booking-box {
            padding: 40px;
        }
    }
}
