.ht-employees {
    padding: 56px 0;

    & .ht-employee-list {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 100%;

        & .ht-employee {
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            width: calc(50% - 20px);
            margin: 0 40px 40px 0;

            &:nth-child(even) {
                margin: 0 0 40px;
            }
    
            & .ht-employee-image {
                width: 200px;

                & img {
                    width: 100%;
                    max-width: 100%;
                }
            }
    
            & .ht-employee-info {
                width: calc(100% - 240px);

                & h2 {
                    margin: 0 0 8px;
                    color: var(--grey-dark);
                }

                & p {
                    margin: 0;
                    color: var(--grey-dark);

                    &.job {
                        margin: 0 0 8px;
                        border-bottom: 1px solid var(--grey-dark-30);
                    }

                    &.mail {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        margin: 0;

                        & a {
                            color: var(--blue);
                            transition: color var(--standard);
                            will-change: color;

                            &:hover {
                                color: var(--pink)
                            }
                        }

                        & i {
                            font-size: responsive 16px 20px;
                            line-height: 1;
                            margin: 4px 8px 0 0;
                        }
                    }

                    &.social {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        margin: 0 0 8px;
                        
                        & a {
                            color: var(--blue);
                            transition: color var(--standard);
                            will-change: color;

                            &:hover {
                                color: var(--pink)
                            }
                        }

                        & i {
                            font-size: responsive 16px 20px;
                            line-height: 1;
                            margin: 4px 8px 0 0;
                        }
                    }

                    &.bio {
                        margin: 0;
                        color: var(--grey-medium);
                    }
                }
            }
        }

        &.single {
            & .ht-employee {
                width: 100%;
                margin: 0 0 40px;
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .ht-employees {
        & .ht-employee-list {
            & .ht-employee {
                align-items: flex-start;
                justify-content: flex-start;
                flex-direction: column;
        
                & .ht-employee-image {
                    width: 100%;
                    margin: 0 0 24px;
                }
        
                & .ht-employee-info {
                    width: 100%;

                    & p {
                        &.mail {
                            & i {
                                margin: 2px 8px 0 0;
                            }
                        }
    
                        &.social {
                            & i {
                                margin: 2px 8px 0 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 736px) {
    .ht-employees {
        & .ht-employee-list {
            & .ht-employee {
                width: 100%;
                margin: 0 0 40px 0;
            }
        }
    }
}

@media only screen and (max-width: 568px) {
    .ht-employees {
        padding: 24px 0;
    }
}
