.ht-card-slider {
    padding: 56px 0;

    & h2 {
        margin: 0 0 40px;
        color: var(--grey-dark);
        text-align: center;
    }

    & > .ht-wrapper {
        & > .ht-card-slider-wrapper {
            & > .slick-list {
                padding: 0 !important;
            }
        }
    }

    & .ht-card-slider-wrapper {
        & .slick-list {
            padding: 0 calc(100vw - 1280px) 0 0;
        }

        & .slick-track {
            display: flex;
            align-items: stretch;
            margin: 56px 0 0;
        }

        & .ht-card-slider-card {
            position: relative;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
            padding: 56px;
            margin: 0 20px;
            background-color: var(--blue);
            border-radius: 8px;
            text-decoration: none;
            outline: none;

            &.slick-slide {
                height: auto;
            }

            & h3 {
                margin: 0 0 24px;
                color: var(--white);
            }

            & p {
                margin: 0 0 80px;
                color: var(--white);
            }

            & div.button {
                margin: auto 0 0;
            }

            &:hover {
                & .button-white-pink {
                    color: var(--white);
                    background-color: var(--pink);
                }
            }
        }

        & ul.slick-dots {
            position: absolute;
            z-index: 4;
            left: 50%;
            top: 0;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 0;
            margin: 0;
            list-style: none;
            transform: translateX(-50%);
    
            & li {
                width: 32px;
                height: 6px;
                margin: 0 8px 0 0;
                font-size: 0;
                line-height: 0;
                outline: none;
                transition: width var(--standard);
                will-change: width;
    
                & button {
                    width: 32px;
                    height: 6px;
                    font-size: 0;
                    line-height: 0;
                    background: var(--grey-medium);
                    border: 0;
                    border-radius: 3px;
                    outline: none;
                    cursor: pointer;
                    transition: width var(--standard), background var(--standard);
                    will-change: width, background;
                }
    
                &.slick-active {
                    & button {
                        background: var(--blue);
                    }    
                }
            }
        }
    }
}

@media only screen and (max-width: 1366px) {
    .ht-card-slider {
        & .ht-card-slider-wrapper {
            & .slick-list {
                padding: 0 calc(100vw - 1180px) 0 0;
            }
        }
    }
}

@media only screen and (max-width: 1280px) {
    .ht-card-slider {
        & .ht-card-slider-wrapper {
            & .slick-list {
                padding: 0 calc(100vw - 1024px) 0 0;
            }
        }
    }
}

@media only screen and (max-width: 1180px) {
    .ht-card-slider {
        & .ht-card-slider-wrapper {
            & .slick-list {
                padding: 0 calc(100vw - 960px) 0 0;
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .ht-card-slider {
        & .ht-card-slider-wrapper {
            & .slick-list {
                padding: 0 calc(100vw - 812px) 0 0;
            }
        }
    }
}

@media only screen and (max-width: 876px) {
    .ht-card-slider {
        & .ht-card-slider-wrapper {
            & .slick-list {
                padding: 0 calc(100vw - 768px) 0 0;
            }
        }
    }
}

@media only screen and (max-width: 812px) {
    .ht-card-slider {
        & .ht-card-slider-wrapper {
            & .slick-list {
                padding: 0 calc(100vw - 736px) 0 0;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .ht-card-slider {
        & .ht-card-slider-wrapper {
            & .slick-list {
                padding: 0 calc(100vw - 704px) 0 0;
            }
        }
    }
}

@media only screen and (max-width: 736px) {
    .ht-card-slider {
        & .ht-card-slider-wrapper {
            & .slick-list {
                padding: 0 4px;
            }
        }
    }
}

@media only screen and (max-width: 667px) {
    .ht-card-slider {
        padding: 48px 0;
    }
}

@media only screen and (max-width: 568px) {
    .ht-card-slider {
        padding: 24px 0;
    }
}

@media only screen and (max-width: 414px) {
    .ht-card-slider {
        & .ht-card-slider-wrapper {
            & .slick-list {
                padding: 0;
            }

            & .ht-card-slider-card {
                margin: 0 16px;
            }
        }
    }
}

@media only screen and (max-width: 375px) {
    .ht-card-slider {
        & .ht-card-slider-wrapper {
            & .ht-card-slider-card {
                padding: 24px;
            }
        }
    }
}
