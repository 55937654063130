.ht-pie-chart {
    /*
    padding: 56px 0;
    background-color: var(--blue);
    */
    margin-bottom:56px;
    
    & .ht-wrapper{
        padding: 56px 0;
        background-color: var(--blue);
        border-radius: 8px;
        
    }
    & h2 {
        margin: 0 auto 8.75%;
        color: var(--white);
        text-align: center;
        font-size: 48px;
    }

    &-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
    }
}

@media only screen and (max-width: 1024px) {
    .ht-pie-chart {
        &-wrapper {
            & .ht-canvas-wrapper {
                width: calc(50% - 20px) !important;
            }
    
            &.ht-pie-chart-count-1 {
                & .ht-canvas-wrapper {
                    width: 100% !important;
                }
            }
    
            &.ht-pie-chart-count-2 {
                & .ht-canvas-wrapper {
                    width: calc(50% - 20px) !important;
                }
            }
        }
    }
}

@media only screen and (max-width: 736px) {
    .ht-pie-chart {
        &-wrapper {
            flex-direction: column;
    
            & .ht-canvas-wrapper {
                width: 100% !important;
            }
    
            &.ht-pie-chart-count-1 {
                & .ht-canvas-wrapper {
                    width: 100% !important;
                }
            }
    
            &.ht-pie-chart-count-2 {
                & .ht-canvas-wrapper {
                    width: 100% !important;
                }
            }
        }
    }
}

@media only screen and (max-width: 568px) {
    .ht-pie-chart {
        padding: 24px 0;
    }
}
