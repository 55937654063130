.ht-numbers {
    margin-bottom:56px;
    
    & .ht-wrapper{
        background-color: var(--blue);
        border-radius: 8px;
        padding:56px;
    }
    
    & h2 {
        margin: 0 auto calc(8.75% - 48px);
        color: var(--white);
        text-align: center;
        font-size: 48px;
    }

    &-wrapper {
        display: flex;
        align-items: stretch;
        justify-content: center;
        flex-wrap: wrap;
        margin-left: -24px;
        margin-right: -24px;
    }

    &-stat {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 32px;
        margin: 48px 24px 0;
        background-color: var(--white-10);
        border: 1px solid var(--white-10);
        border-radius: 8px;
    }

    &-num {
        margin: 0 0 10px;
        color: var(--white);
        font-weight: 700;
        font-size: responsive 40px 64px;
        line-height: 1.21875;
        text-align: center;
        text-transform: uppercase;
    }

    &-label {
        margin: 0;
        color: var(--white);
        font-weight: 700;
        font-size: responsive 12px 14px;
        line-height: 1.21428;
        text-align: center;
        text-transform: uppercase;
    }

    &-single {
        & .ht-numbers-stat {
            width: 100%;
        }
    }

    &-double {
        & .ht-numbers-stat {
            width: calc(50% - 48px);
        }
    }

    &-multi {
        & .ht-numbers-stat {
            width: calc((100% - 144px) / 3);
        }
    }
}

@media only screen and (max-width: 1024px) {
    .ht-numbers {
        &-single {
            & .ht-numbers-stat {
                width: 100%;
            }
        }
    
        &-double,
        &-multi {
            & .ht-numbers-stat {
                width: calc(50% - 48px);
            }
        }
    }
}

@media only screen and (max-width: 667px) {
    .ht-numbers {
        &-wrapper {
            flex-direction: column;
            margin-left: 0;
            margin-right: 0;
        }
    
        &-stat {
            margin: 48px 0 0;
        }

        &-single
        &-double,
        &-multi {
            & .ht-numbers-stat {
                width: 100%;
            }
        }
    }
}

@media only screen and (max-width: 568px) {
    .ht-numbers {
        padding: 24px 0;
    }
}
