.ht-header-visual {
    &-color {
        width: 100vw;

        &.dark-grey {
            background-color: var(--grey-dark);

            & .ht-header-visual-content {
                & h1,
                & .ht-header-visual-subtitle {
                    color: var(--white);
                }
            }
        }

        & .ht-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }
    }

    &-content {
        max-height: 774px;
        min-height: 320px;
        padding: 18.28125% 0 15.3125%;
    }

    & h1 {
        margin: 0;
        text-align: center;
        text-transform: uppercase;
    }

    & .ht-header-visual-subtitle {
        margin: 0 auto;
        text-align: center;
    }
}
