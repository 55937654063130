.ht-blog {
    padding: 0 0 56px;
    margin: -8px 0 0;

    & .ht-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;

        & a.button {
            margin-top: 60px;
        }
    }

    &-container {
        display: flex;
        align-items: stretch;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
        margin-left: -20px;
        margin-right: -20px;
    }

    &-article {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        width: calc((100% - 120px) / 3);
        margin: 0 20px 40px;
        background-color: var(--blue);
        border-radius: 8px;
        text-decoration: none;

        &:hover {
            & .ht-blog-details {
                & .button {
                    color: var(--white);
                    background-color: var(--pink);
                }
            }
        }
    }

    &-image {
        position: relative;
        width: 100%;
        height: 215px;
        overflow: hidden;
        border-radius: 8px 8px 0 0;

        & img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            max-width: none;
            height: auto;
            min-height: 100%;
            transform: translate(-50%, -50%);
        }

        &.fallback {
            background-color: var(--grey-medium);
        }
    }

    &-details {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        height: calc(100% - 215px);
        padding: 24px 56px 32px;
        border-radius: 8px 8px 0 0;

        & .date {
            margin: 0 0 8px;
            color: var(--white);
            font-size: responsive 12px 16px;
            line-height: 1.5;
        }

        & h2.h4{
            margin: 0 0 24px;
            color: var(--white);
        }

        & .text {
            margin: 0 0 56px;
            color: var(--white);
            font-size: responsive 16px 24px;
            line-height: 1.5;
        }

        & .button {
            display: inline-flex;
            margin: auto 0 0;
        }
    }
}

@media only screen and (max-width: 1024px) {
    .ht-blog {    
        &-article {
            width: calc(50% - 40px);
        }
    }
}

@media only screen and (max-width: 568px) {
    .ht-blog {
        padding: 0 0 24px;
           
        &-container {
            margin: 0;
        }

        &-article {
            width: 100%;
            margin: 0 0 40px;
        }
    }
}
