.ht-image-slider {
    padding: 56px 0;
    
    & .ht-image-slider-wrapper {
        max-width: 840px;
        margin: 0 auto;
        padding: 0 0 30px;

        & img {
            width: 100%;
            height: auto;
        }
    }

    & ul.slick-dots {
        position: absolute;
        z-index: 4;
        left: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0;
        margin: 0;
        list-style: none;

        & li {
            width: 32px;
            height: 6px;
            margin: 0 8px 0 0;
            font-size: 0;
            line-height: 0;
            outline: none;
            transition: width var(--standard);
            will-change: width;

            & button {
                width: 32px;
                height: 6px;
                font-size: 0;
                line-height: 0;
                background: var(--grey-medium);
                border: 0;
                border-radius: 3px;
                outline: none;
                cursor: pointer;
                transition: width var(--standard), background var(--standard);
                will-change: width, background;
            }

            &.slick-active {
                width: 64px;

                & button {
                    width: 64px;
                    background: var(--pink);
                }    
            }
        }
    }

    & .slick-arrow {
        position: absolute;
        z-index: 4;
        top: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 56px;
        height: 56px;
        color: var(--white);
        font-size: 24px;
        line-height: 20px;
        background-color: var(--grey-dark);
        border: none;
        border-radius: 28px;
        outline: none;
        cursor: pointer;
        opacity: 1;
        transition: background-color var(--standard), opacity var(--standard);
        will-change: background-color, opacity;

        &:hover {
            background-color: var(--pink);
        }

        &.slick-disabled {
            opacity: 0;
        }

        &.slick-prev {
            left: 32px;
        }

        &.slick-next {
            right: 32px;
        }
    }
}

@media only screen and (max-width: 736px) {
    .ht-image-slider {
        & .slick-arrow {
            top: 16px;
            width: 32px;
            height: 32px;
            font-size: 16px;
            line-height: 14px;
            border-radius: 16px;
            
            &.slick-prev {
                left: 16px;
            }
    
            &.slick-next {
                right: 16px;
            }
        }
    }
}

@media only screen and (max-width: 568px) {
    .ht-image-slider {
        padding: 24px 0;
    }
}
