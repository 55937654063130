.ht-full-2-col-split {
    position: relative;
    z-index: 1;
    width: 100vw;
    height: 100vh;

    & .ht-wrapper {
        position: relative;
        z-index: 3;
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }

    & .ht-full-cta-2-col-left,
    & .ht-full-cta-2-col-right {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 50vw;
        padding: 20.83333% 0 0;

        & .ht-video-background-left,
        & .ht-video-background-right {
            position: fixed;
            z-index: 1;
            top: 0;
            width: 50vw;
            height: 100vh;
            overflow: hidden;

            & .ht-video-background {
                position: absolute;
                top: 50%;
                left: 50%;
                width: auto;
                height: auto;
                min-width: 100%;
                min-height: 100%;
                transform: translate(-50%, -50%);
            }

            &:after {
                content: '';
                position: absolute;
                z-index: 2;
                top: 0;
                left: 0;
                width: 100vw;
                height: 100vh;
                background-color: var(--black-50);
                transition: background-color var(--standard);
            }
        }

        & .ht-full-cta-2-col-content {
            position: relative;
            z-index: 4;
            width: 50vw;

            & h2 {
                margin: 0 0 16px;
                color: var(--white);
                font-family: var(--font);
                font-weight: 700;
                font-size: responsive 32px 48px;
                line-height: 1.16667;
                text-align: center;
            }

            & p.ht-full-cta-2-col-text {
                margin: 0 0 56px;
                color: var(--white);
                font-family: var(--font);
                font-weight: 500;
                font-size: responsive 16px 24px;
                line-height: 1.5;
                text-align: center;
            }

            & ul {
                display: block;
                max-width: 332px;
                margin: 0 auto;
                padding: 0 16px;
                list-style: none;

                & li {
                    & a {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 24px 0;
                        color: var(--white);
                        font-family: var(--font);
                        font-weight: 700;
                        font-size: responsive 14px 18px;
                        line-height: 1.22222;
                        border-bottom: 1px solid var(--white-30);
                        text-decoration: none;
                        text-transform: uppercase;
                        transition: color var(--standard);
                        will-change: color;

                        & i,
                        & svg {
                            color: var(--grey-medium);
                            font-family: var(--icons);
                            font-weight: 400;
                            font-size: responsive 24px 24px;
                            line-height: 1;
                            transition: color var(--standard);
                            will-change: color;
                        }

                        &:hover {
                            color: var(--pink);

                            & > i,
                            & > svg {
                                color: var(--pink);
                            }
                        }
                    }

                    &:last-child {
                        & a {
                            border: none;
                        }
                    }
                }
            }
        }
    }

    & .ht-full-cta-2-col-left {
        & .ht-video-background-left {
            left: 0;

            &:before {
                content: '';
                position: absolute;
                z-index: 9;
                top: 0;
                right: 0;
                width: 1px;
                height: 100%;
                background-color: var(--white-50);
            }
        }

        & .ht-full-cta-2-col-content {
            margin: 0 auto 0 0;
        }
    }

    & .ht-full-cta-2-col-right {
        & .ht-video-background-right {
            right: 0;
        }

        & .ht-full-cta-2-col-content {
            margin: 0 0 0 auto;
        }
    }
}

@media only screen and (orientation: landscape) and (max-width: 1024px) {
    .ht-full-2-col-split {
        & .ht-wrapper {
            align-items: center;

            & .ht-full-cta-2-col-left,
            & .ht-full-cta-2-col-right {
                padding: 0;
            }
        }
    }
}

@media only screen and (orientation: landscape) and (max-width: 812px) {
    .ht-full-2-col-split {
        & .ht-wrapper {
            align-items: flex-start;

            & .ht-full-cta-2-col-left,
            & .ht-full-cta-2-col-right {
                padding: 132px 0 32px;

                & .ht-full-cta-2-col-content {
                    & .ht-full-cta-2-col-text {
                        margin: 0 0 24px;
                    }
                }
            }
        }
    }
}

@media only screen and (orientation: portrait) and (max-width: 1024px) {
    .ht-full-2-col-split {
        & .ht-wrapper {
            align-items: center;

            & .ht-full-cta-2-col-left,
            & .ht-full-cta-2-col-right {
                padding: 0;
            }
        }
    }
}

@media only screen and (orientation: portrait) and (max-width: 568px) {
    .ht-full-2-col-split {
        margin-top: 60px;
        height: auto;

        & .ht-wrapper {
            flex-direction: column;
            padding: 60px 0;
            height: auto;
            max-height: none;

            & .ht-full-cta-2-col-left {
                & .ht-video-background-left {
                    position: fixed;
                    z-index: 1;
                    top: 60px;
                    left: 0;
                    width: 100vw;
                    height: 100%;
                    overflow: hidden;
                }
            }

            & .ht-full-cta-2-col-right {
                & .ht-video-background-right {
                    display: none;
                }
            }

            & .ht-full-cta-2-col-left,
            & .ht-full-cta-2-col-right {
                position: relative;
                justify-content: flex-start;
                width: 100%;
                height: auto;
                padding: 40px 0;
                margin: 0;

                & .ht-full-cta-2-col-content {
                    width: 100%;
                    margin: 0;

                    & h2 {
                        margin: 0 0 8px;
                    }

                    & .ht-full-cta-2-col-text {
                        margin: 0 0 8px;
                    }

                    & ul {
                        max-width: 100%;

                        & li {
                            & a {
                                padding: 12px 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
