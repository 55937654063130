.ht-contactperson {
    padding: 56px 0;

    & .ht-contact-container {
        max-width: 1000px;
        margin: auto;
        display: flex;
    }

    & .ht-wrapper {
        & h2 {
            color: var(--grey-dark);
            font-size: responsive 26px 32px;
        }

        & .ht-contactperson-image {
            width: 200px;
            height: 100%;
            min-height: 200px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center top;
            border-radius: 8px 0 0 8px;
        }

        & .ht-contactperson-details {
            display: flex;
            justify-content: center;
            flex-direction: column;
            background-color: var(--blue);
            padding: 40px;
            width: 100%;
            border-radius: 0 8px 8px 0;

            & > * {
                color: var(--white);
            }

            & a {
                font-size: 19px;
                transition: all var(--standard);

                &:hover {
                    opacity: 0.8;
                }
            }

            & .ht-contactperson-links {
                display: flex;
                align-items: center;
                gap: 30px;

                & > * {
                    color: var(--white);
                }
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .ht-contactperson {
        & .ht-wrapper {
            & .ht-contactperson-details {
                padding: 20px;
            }
        }
    }
}

@media only screen and (max-width: 876px) {
    .ht-contactperson {
        & .ht-wrapper {
            & .ht-contact-container {
                flex-direction: row;
            }

            & .ht-contactperson-details {
                & .ht-contactperson-links {
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 12px;
                }
            }
        }
    }
}

@media only screen and (min-width: 550px) and (max-width: 650px) {
    .ht-contactperson {
        & .ht-wrapper {
            & .ht-contact-container {
                flex-direction: row;
            }

            & .ht-contactperson-image {
                width: 200px;
                height: 100%;
            }
        }
    }
}

@media only screen and (max-width: 550px) {
    .ht-contactperson {
        & .ht-wrapper {
            & .ht-contact-container {
                flex-direction: column;
                max-width: 300px;
            }

            & .ht-contactperson-image {
                width: 100%;
                border-radius: 8px 8px 0 0;
            }

            & .ht-contactperson-details {
                border-radius: 0 0 8px 8px;
            }
        }
    }
}
