.woocommerce-checkout {
    & .ht-header-visual {
        &.ht-header-visual-plain {
            & .ht-wrapper {
                & .ht-header-visual-content {
                    padding: 12.28125% 0 0;
                    min-height: 0;

                    & .ht-breadcrumbs {
                        margin: 24px 0 16px;
                    }

                    & h1 {
                        margin: 0;
                    }
                }
            }
        }
    }

    & .woocommerce {
        & .woocommerce-form-coupon {
            & .form-row {
                &.form-row-first {
                    & input {
                        width: 100%;
                        padding: 16px 32px;
                        margin: 0;
                        color: var(--grey-dark);
                        font-size: responsive 16px 20px;
                        line-height: 1.20833;
                        font-weight: 700;
                        background-color: var(--grey-medium-20);
                        border: none;
                        border-radius: 42px;
                        outline: none;
                    }
                }

                &.form-row-last {
                    & button.button {
                        display: inline-flex;
                        width: auto;
                        padding: 16px 32px;
                        color: var(--white);
                        font-size: 0;
                        line-height: 0;
                        font-weight: 700;
                        background-color: var(--blue);
                        border-radius: 39px;
                        transition: background-color var(--standard);
                        will-change: background-color;

                        &:after {
                            content: 'Toepassen';
                            color: var(--white);
                            font-size: responsive 16px 20px;
                            line-height: 1.20833;
                        }

                        &:hover {
                            background-color: var(--pink);
                        }
                    }
                }
            }
        }

        & form.woocommerce-checkout {
            margin-top: 24px;

            & .col2-set {
                display: flex;
                flex-direction: column;
                width: 100%;
                float: none;

                & .col-1,
                & .col-2 {
                    width: 100%;
                    max-width: 848px;
                    float: none;
                }
            }

            & .woocommerce-billing-fields {
                & h3 {
                    margin: 0 0 24px;
                }

                & p.form-row {
                    position: relative;
                    padding: 0;
                    margin: 0 0 16px;

                    &#billing_address_2_field {
                        display: none !important;
                    }

                    &#billing_country_field {
                        & label {
                            color: var(--white);
                            background-color: var(--blue);
                        }
                    }

                    &.form-row-first,
                    &.form-row-last {
                        width: 100%;
                        float: none;
                    }
                    
                    & label {
                        position: absolute;
                        z-index: 2;
                        top: 0;
                        left: 0;
                        width: auto;
                        padding: 16px 32px;
                        margin: 0;
                        color: var(--grey-medium);
                        font-size: responsive 16px 20px;
                        line-height: 1.20833;
                        font-weight: 700;
                        border-radius: 42px 0 0 42px;
                        transition: color var(--standard), background-color var(--standard);
                        will-change: color, background-color;
    
                        & span.optional {
                            color: var(--grey-medium);
                            font-size: responsive 16px 20px;
                            line-height: 1.20833;
                            font-weight: 700;
                            transition: color var(--standard);
                            will-change: color;
                        }
    
                        & .required {
                            color: var(--red);
                        }
    
                        &.not-empty {
                            color: var(--white);
                            background-color: var(--blue);

                            & span.optional {
                                color: var(--white);
                            }
                        }
                    }
    
                    & span.woocommerce-input-wrapper {
                        display: block;
                        margin: 0;

                        & #billing_address_1,
                        & #billing_address_2 {
                            &::-webkit-input-placeholder,
                            &::-moz-placeholder,
                            &:-ms-input-placeholder,
                            &:-moz-placeholder {
                                color: var(--grey-input) !important;
                            }
                        }
    
                        & > strong,
                        & input {
                            display: block;
                            width: 100%;
                            max-width: 100%;
                            padding: 16px 32px;
                            margin: 0;
                            color: var(--grey-dark);
                            font-size: responsive 16px 20px;
                            line-height: 1.20833;
                            font-weight: 700;
                            background-color: var(--grey-medium-20);
                            border: none;
                            border-radius: 42px;
                            outline: none;
                        }
                    }
                }
            }

            & .woocommerce-additional-fields {
                & h3 {
                    margin: 16px 0 16px;
                    font-size: responsive 14px 24px;
                    line-height: 1.5;
                }

                & p.form-row {
                    position: relative;
                    padding: 0;
                    margin: 0 0 16px;

                    & label {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        padding: 40px 40px 34px;
                        margin: 0;
                        color: var(--grey-medium);
                        font-size: responsive 16px 20px;
                        line-height: 1.20833;
                        font-weight: 700;
                        border-radius: 8px 8px 0 0;
                        transition: color var(--standard), background-color var(--standard);
                        will-change: color, background-color;

                        & span.optional {
                            color: var(--grey-medium);
                            font-size: responsive 16px 20px;
                            line-height: 1.20833;
                            font-weight: 700;
                            transition: color var(--standard);
                            will-change: color;
                        }

                        & .required {
                            color: var(--red);
                        }

                        &.not-empty {
                            color: var(--white);
                            background-color: var(--blue);

                            & span.optional {
                                color: var(--white);
                            }
                        }
                    }

                    & span.woocommerce-input-wrapper {
                        display: block;
                        margin: 0;

                        & textarea {
                            width: 100%;
                            padding: 143px 40px 64px;
                            min-height: 328px;
                            margin: 0;
                            color: var(--grey-dark);
                            font-size: responsive 16px 20px;
                            line-height: 1.20833;
                            font-weight: 700;
                            background-color: var(--grey-medium-20);
                            border: none;
                            border-radius: 8px;
                            outline: none;

                            &::-webkit-input-placeholder,
                            &::-moz-placeholder,
                            &:-ms-input-placeholder,
                            &:-moz-placeholder {
                                color: var(--grey-input) !important;
                            }
                        }
                    }
                }
            }

            & #order_review_heading {
                margin: 24px 0 16px;
            }

            & .woocommerce-checkout-review-order {
                & table.shop_table.woocommerce-checkout-review-order-table {
                    & thead {
                        & tr {
                            & th {
                                vertical-align: top;

                                &.product-name,
                                &.product-total {
                                    padding: 8px;
                                    color: var(--black);
                                    font-size: responsive 16px 20px;
                                    line-height: 1.5;
                                }
                            }
                        }
                    }

                    & tbody {
                        & tr {
                            &.cart_item {
                                & td {
                                    vertical-align: top;
                                    
                                    &.product-name,
                                    &.product-total {
                                        padding: 8px;
                                        font-size: responsive 16px 20px;
                                        line-height: 1.5;

                                        & span.woocommerce-Price-amount {
                                            font-size: responsive 16px 20px;
                                            line-height: 1.5;

                                            & .woocommerce-Price-currencySymbol {
                                                font-size: responsive 16px 20px;
                                                line-height: 1.5;
                                            }

                                            &:after {
                                                content: '(ex BTW)';
                                                margin: 0 0 0 4px;
                                                font-size: responsive 8px 12px;
                                            }
                                        }

                                        & dl.variation {
                                            margin: 8px 0 0;
    
                                            & dt,
                                            & dd {
                                                & p {
                                                    font-size: responsive 16px 20px;
                                                    line-height: 1.5;
    
                                                    & span.pewc-price.pewc-cart-item-price {
                                                        font-size: responsive 16px 20px;
                                                        line-height: 1.5;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    & tfoot {
                        & tr {
                            &.cart-subtotal,
                            &.tax-rate,
                            &.order-total {
                                & th,
                                & td {
                                    padding: 8px;
                                    font-size: responsive 16px 20px;
                                    line-height: 1.5;
        
                                    & span.woocommerce-Price-amount {
                                        font-size: responsive 16px 20px;
                                        line-height: 1.5;
        
                                        & .woocommerce-Price-currencySymbol {
                                            font-size: responsive 16px 20px;
                                            line-height: 1.5;
                                        }
        
                                        &:after {
                                            content: '(ex BTW)';
                                            margin: 0 0 0 4px;
                                            font-size: responsive 8px 12px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                & #payment {
                    & button#place_order {
                        padding: 16px 40px;
                        color: var(--white);
                        font-size: responsive 12px 18px;
                        line-height: 1.22222;
                        background-color: var(--pink);
                        border-radius: 39px;
                        transition: background-color var(--standard);
                        will-change: background-color;
    
                        &:hover {
                            background-color: var(--blue);
                        }
                    }
                }

                & .woocommerce-checkout-payment#payment {
                    background-color: var(--grey-light);

                    & ul.wc_payment_methods.payment_methods.methods {
                        & li.wc_payment_method {
                            position: relative;
                            margin: 0 0 16px;
                            color: var(--blue);
                            font-size: responsive 13px 17px;
                            line-height: 1.5;
                            font-weight: 200;

                            &:last-child {
                                margin: 0;
                            }
                            
                            & label {
                                position: relative;
                                display: flex;
                                align-items: center;
                                padding: 0 0 0 30px;
                                margin: 0;
                                color: var(--blue);
                                font-size: responsive 13px 17px;
                                line-height: 1.5;
                                font-weight: 200;
                                cursor: pointer;
                                transition: color var(--standard);
                                will-change: color;
                                vertical-align: middle; 

                                & img {
                                    position: relative;
                                    display: inline-block;
                                    width: auto;
                                    height: auto;
                                    max-height: 100%;
                                    max-width: none;
                                    margin: -3px 0 0 8px;
                                    vertical-align: unset; 
                                }

                                &:hover {
                                    color: var(--pink);

                                    &:before {
                                        color: var(--pink);
                                    }
                                }
                            }

                            & input[type='radio'].input-radio {
                                position: absolute;
                                display: inline-block;
                                width: 22px;
                                height: 22px;
                                margin: 0;
                                cursor: pointer;
                                visibility: hidden;
                                outline: none;
                                opacity: 1;
                                -webkit-appearance: none !important;
                                -moz-appearance: none !important;
                                appearance: none !important;
                                outline: none;

                                &+label:before {
                                    content: 'radio_button_unchecked';
                                    position: absolute;
                                    z-index: 1;
                                    left: 0;
                                    top: -1px;
                                    width: 22px;
                                    height: 22px;
                                    margin: 0;
                                    padding: 0;
                                    font-family: 'Material Icons';
                                    font-size: 24px;
                                    font-style: normal;
                                    font-weight: normal;
                                    line-height: 1;
                                    text-transform: none;
                                    letter-spacing: normal;
                                    word-wrap: normal;
                                    white-space: nowrap;
                                    vertical-align: -6px;
                                    transition: all 0.2s ease;
                                    direction: ltr;
                                    /* Support for all WebKit browsers. */
                                    -webkit-font-smoothing: antialiased;
                                    /* Support for Safari and Chrome. */
                                    text-rendering: optimizeLegibility;
                                    /* Support for Firefox. */
                                    -moz-osx-font-smoothing: grayscale;
                                    /* Support for IE. */
                                    font-feature-settings: 'liga';
                                    transition: color var(--standard);
                                    will-change: color;
                                }

                                &:checked+label:before {
                                    content: 'radio_button_checked';
                                    color: var(--pink);
                                }
                            }

                            & .payment_box {
                                padding: 16px;
                                margin: 16px 0 0;
                                color: var(--blue);
                                font-size: responsive 13px 17px;
                                line-height: 1.5;
                                font-weight: 200;
                                background-color: var(--grey-input);
                                border-radius: 8px;

                                & p {
                                    color: var(--blue);
                                    font-size: responsive 13px 17px;
                                    line-height: 1.5;
                                    font-weight: 200;
                                }

                                &:before {
                                    position: absolute;
                                    top: -12px;
                                    left: 0;
                                    margin: -16px 0 0 32px;
                                    border: 1em solid var(--grey-input);
                                    border-top-color: transparent;
                                    border-left-color: transparent;
                                    border-right-color: transparent;
                                }

                                & .docdatacw-preview-fields {
                                    margin: 0;

                                    & .docdatacw-control-group {
                                        margin: 0;

                                        & .docdatacw-control-label {
                                            padding: 0;
                                            margin: 8px 0 0;

                                            & span.docdatacw-required {
                                                color: var(--red);
                                                font-weight: 200;
                                                font-size: responsive 12px 18px;
                                                line-height: 1.22222;
                                            }
                                        } 

                                        & .docdatacw-select-field {
                                            position: relative;
                                            width: 50%;
                                            max-width: 400px;
                                            padding: 0;
                                            margin: 0;
                                            color: var(--blue);
                                            font-size: responsive 13px 17px;
                                            line-height: 1.5;
                                            font-weight: 200;
                                            background-color: var(--white);
                                            border: none;
                                            border-radius: 8px;
                                            outline: none;
                
                                            &:after {
                                                content: '\f078';
                                                position: absolute;
                                                top: 24px;
                                                right: 24px;
                                                font-family: var(--icons);
                                                font-size: responsive 24px 24px;
                                                line-height: 1;
                                            }
                
                                            & select {
                                                width: 100%;
                                                height: 100%;
                                                margin: 0;
                                                padding: 24px 24px 23px 24px;
                                                color: var(--blue);
                                                font-size: responsive 13px 17px;
                                                line-height: 1.5;
                                                font-weight: 200;
                                                background-color: transparent;
                                                border: none;
                                                outline: none;
                                                appearance: none;
                
                                                &::-ms-expand {
                                                    display: none;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    & .form-row.place-order {
                        & .woocommerce-terms-and-conditions-wrapper {
                            & .woocommerce-privacy-policy-text {
                                & p {
                                    font-size: responsive 12px 18px;
                                    line-height: 1.22222;
                                }
                            }

                            & .form-row.validate-required {
                                position: relative;
                                margin: 0;
                                padding: 0;

                                & .woocommerce-form__label-for-checkbox {
                                    position: relative;
                                    margin: 0;
                                    font-size: responsive 12px 18px;
                                    line-height: 1.22222;

                                    & .required {
                                        font-size: responsive 12px 18px;
                                        line-height: 1.22222;
                                    }

                                    & .woocommerce-terms-and-conditions-checkbox-text {
                                        margin: 0;
                                        padding: 0 0 0 27px;
                                        color: var(--grey-medium);
                                        font-size: responsive 12px 18px;
                                        line-height: 1.22222;
                                    }

                                    & > a {
                                        color: var(--blue);
                                        transition: color var(--standard);

                                        &:hover {
                                            color: var(--pink);
                                        }
                                    }
                                }

                                & .woocommerce-form__label-for-checkbox {
                                    display: inline-flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    margin: 0;
                                    color: var(--grey-medium);
                                    font-size: responsive 12px 18px;
                                    line-height: 1.22222;
                                }

                                & .woocommerce-form__input-checkbox {
                                    position: absolute;
                                    display: inline-block;
                                    width: 24px;
                                    height: 24px;
                                    padding: 0;
                                    margin: 0;
                                    cursor: pointer;
                                    visibility: hidden;
                                    outline: none;
                                    opacity: 1;
                                    -webkit-appearance: none !important;
                                    -moz-appearance: none !important;
                                    appearance: none !important;
                                    outline: none;

                                    &+span:before {
                                        content: '\e835';
                                        position: absolute;
                                        z-index: 1;
                                        left: -2px;
                                        top: 5px;
                                        width: 24px;
                                        height: 24px;
                                        color: var(--blue);
                                        font-family: 'Material Icons';
                                        font-size: 26px;
                                        font-style: normal;
                                        font-weight: normal;
                                        line-height: 1;
                                        text-transform: none;
                                        letter-spacing: normal;
                                        word-wrap: normal;
                                        white-space: nowrap;
                                        vertical-align: -6px;
                                        transition: all 0.25s ease-in-out;
                                        direction: ltr;
                                        /* Support for all WebKit browsers. */
                                        -webkit-font-smoothing: antialiased;
                                        /* Support for Safari and Chrome. */
                                        text-rendering: optimizeLegibility;
                                        /* Support for Firefox. */
                                        -moz-osx-font-smoothing: grayscale;
                                        /* Support for IE. */
                                        font-feature-settings: 'liga';
                                    }

                                    &:checked+span:before {
                                        content: '\e834';
                                        color: var(--pink);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        & .woocommerce-order {
            & .woocommerce-order-details {
                margin: 0;

                & h2.woocommerce-order-details__title {
                    margin: 24px 0 16px;
                    font-size: responsive 14px 24px;
                    line-height: 1.5;
                }

                & table.woocommerce-table--order-details,
                & table.woocommerce-table--custom-fields {
                    & thead {
                        & tr {
                            & th {
                                padding: 8px;

                                &.product-name,
                                &.product-total {
                                    font-size: responsive 16px 20px;
                                    line-height: 1.5;
                                }
                            }
                        }
                    }

                    & tbody {
                        & tr.order_item {
                            & td {
                                padding: 8px;
                            }
                        }
                    }

                    & tfoot {
                        & tr {
                            & th,
                            & td {
                                padding: 8px;
                            }
                        }
                    }
                }
            }

            & .woocommerce-customer-details {
                width: 100% !important;
                margin: 0;

                & h2.woocommerce-column__title {
                    margin: 24px 0 16px;
                    font-size: responsive 14px 24px;
                    line-height: 1.5;
                }

                & address {
                    padding: 8px;
                    font-size: responsive 12px 16px;
                    line-height: 1.5;

                    & p {
                        &.woocommerce-customer-details--phone,
                        &.woocommerce-customer-details--email {
                            position: relative;
                            padding-left: 24px;
                            font-size: responsive 12px 18px;
                            line-height: 1.5;
                            font-weight: 400;

                            &:before {
                                top: 4px;
                                left: 0;
                                margin: 0;
                                font-size: responsive 12px 18px;
                                line-height: 1;
                                font-weight: 400;
                                transform: scaleX(-1);
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 667px) {
    .woocommerce-checkout {
        & .woocommerce {
            & form.woocommerce-checkout {
                & .woocommerce-billing-fields {
                    & p.form-row {
                        &#billing_country_field {
                            & label {
                                position: relative;
                                padding: 0;
                                margin: 0 0 8px;
                                color: var(--blue);
                                font-size: 18px;
                                line-height: 22px;
                                background-color: transparent;
                            }
                        }
                        
                        & label {
                            position: relative;
                            padding: 0;
                            margin: 0 0 8px;
                            color: var(--blue);
                            font-size: 18px;
                            line-height: 22px;
        
                            & span.optional {
                                color: var(--blue);
                            }
                        }
        
                        & span.woocommerce-input-wrapper {
                            & > strong,
                            & input {
                                padding: 25px 32px 25px 16px;
                                font-size: 18px;
                                line-height: 22px;
                                border-radius: 8px;
                            }
                        }
                    }
                }
    
                & .woocommerce-additional-fields {
                    & p.form-row {
                        & label {
                            position: relative;
                            padding: 0;
                            margin: 0 0 8px;
                            color: var(--blue);
                            font-size: 18px;
                            line-height: 22px;
        
                            & span.optional {
                                color: var(--blue);
                            }
                        }
    
                        & span.woocommerce-input-wrapper {
                            & textarea {
                                padding: 25px 32px 25px 16px;
                            }
                        }
                    }
                }
            }
        }
    }
}
