header {
    position: fixed;
    z-index: 9;
    top: 0;
    left: 50%;
    display: flex;
    align-items: center;
    width: 100vw;
    height: 76px;
    background-color: var(--grey-dark);
    transform: translateX(-50%);
    transition: all var(--standard);
    transition-property: top, width, padding, border-radius;
    will-change: top, width, padding, border-radius;

    & .ht-logo {
        width: auto;
        height: 130px;
        transform: translateY(40px);
        transition: height var(--standard), top var(--standard);
        will-change: height, top;
        z-index: 10;

        & img {
            width: auto;
            height: 130px;
            transition: height var(--standard), top var(--standard);
            will-change: height, top;
        }
    }

    & .ht-mega-menu-background {
        content: "";
        position: absolute;
        z-index: 8;
        left: 0;
        top: 76px;
        width: 100%;
        background-color: var(--black);
        transition: top var(--standard);
        will-change: top;
    }
}

header {
    &.scrolled {
        top: 24px;
        width: 1280px;
        max-width: 1280px;
        height: 60px;
        padding: 16px 24px;
        border-radius: 8px;

        &.menu-open {
            border-radius: 8px 8px 0 0;
        }

        & .ht-logo {
            position: relative;
            height: 48px;
            min-width: 48px;
            transform: none;

            & img {
                height: 48px;
                width: 48px;
            }
        }

        & .ht-mega-menu-background {
            top: 60px;
            border-radius: 0 0 8px 8px;
        }
    }

    &.submenu {
        border-radius: 8px 8px 0 0;
    }
}

.ht-header-visual {
    & .ht-header-visual-content {
        gap: 40px;

        & ul {
            list-style: none;
            padding: 0;
            gap: 12px;
            display: flex;
            flex-direction: column;
    
            & li {
                display: flex;
                align-items: center;
                gap: 12px;
                color: var(--white);
    
                & svg {
                    height: 25px;
                    min-width: 25px;
                }
            }
        }
    }
}

@media only screen and (max-width: 1366px) {
    header {
        &.scrolled {
            width: 1180px;
            max-width: 1180px;
        }
    }
}

@media only screen and (max-width: 1280px) {
    header {
        &.scrolled {
            width: 1024px;
            max-width: 1024px;

            & .ht-logo-mobile {
                transform: translateY(-10px);
            }
        }

        & .ht-logo {
            height: 92px;
            transform: none;

            & img {
                height: 92px;
            }
        }
    }
}

@media only screen and (max-width: 1180px) {
    header {
        &.scrolled {
            width: 960px;
            max-width: 960px;
        }
    }
}

@media only screen and (min-width: 1301px) {
    header {
        & .ht-mobile-nav-button,
        & .ht-logo-mobile {
            display: none;
        }
    }
}

@media only screen and (max-width: 1300px) {
    header,
    header.scrolled {
        top: 0;
        width: 100vw;
        height: 60px;
        max-width: none;
        padding: 16px 24px;
        border-radius: 0;

        & .ht-mobile-nav-button,
        & .ht-logo-mobile {
            display: block;
        }
    }
}

@media only screen and (max-width: 667px) {
    header {
        & .ht-logo {
            height: 80px;

            & img {
                height: 80px;
            }
        }
    }
}
