.ht-cta {
    padding: 56px 0;

    .ht-wrapper {
        text-align: center;
    }

    .ht-cta-content {
        max-width: 1000px;
        margin: auto;
    }

    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5,
    h6,
    .h6 {
        color: var(--grey-dark);
    }

    .button-pink-white:hover {
        background-color: var(--blue);
        color: var(--white);
    }

    .button-blue-white:hover {
        background-color: var(--pink);
        color: var(--white);
    }

    .button:not(.button-pink-white):not(.button-blue-white) {
        color: var(--pink);
        text-decoration: underline;
        transition: all var(--standard);
        padding: 0;

        &:hover {
            opacity: 0.75;
        }
    }

    .ht-cta-buttons {
        display: flex;
        flex-wrap: wrap;
        gap: 28px;
        align-items: center;
        justify-content: center;
        margin-top: 28px;
    }
}
