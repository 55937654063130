.ht-header-visual {
    &-plain {
        width: 100vw;

        & .ht-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }

        & .ht-header-visual-content {
            position: relative;
            z-index: 3;
            max-width: 840px;
            max-height: none;
            min-height: 320px;
            margin: 0 auto;
    
            & .ht-breadcrumbs {
                margin: 20px 0 32px;
            }
    
            & h1 {
                color: var(--grey-dark);
                font-size: responsive 40px 60px;
                line-height: 1.21875;
            }
        }

        &.center {
            & .ht-header-visual-content {
                padding: 23.90625% 0 9.375%;

                &.page-search,
                &.page-404 {
                    padding: 17.5% 0 32px;

                    & p.ht-header-visual-subtitle {
                        text-align: center;
                    }
                }

                & h1 {
                    margin: 0 0 32px;
                    text-align: center;
                }

                & p.ht-header-visual-subtitle {
                    color: var(--grey-medium);
                }
            }
        }

        &.left {
            & .ht-header-visual-content {
                padding: 18.28125% 0 0;

                & h1 {
                    margin: 0 0 24px;
                    text-align: left;
                }
            }
        }
    }
}
