.ht-header-visual {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;

    &-video {
        width: 100vw;

        & .ht-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }

        & .ht-video-background {
            position: absolute;
            z-index: 1;
            top: 50%;
            left: 50%;
            min-width: 100%;
            transform: translate(-50%, -50%);
        }

        &:after {
            content: '';
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--black-50);
        }
    }

    &-content {
        position: relative;
        z-index: 3;
        min-height: 320px;
    }

    &.medium {
        & .ht-header-visual-content {
            max-height: 774px;
            padding: 18.28125% 0 15.3125%;
        }
    }

    &.large {
        & .ht-header-visual-content {
            max-height: 874px;
            padding: 21% 0;
        }
    }

    &.big {
        & .ht-header-visual-content {
            max-height: 960px;
            padding: 25% 0;
        }
    }

    & h1 {
        color: var(--white);
        text-align: center;
        text-transform: uppercase;
    }

    & .ht-header-visual-subtitle {
        color: var(--white);
        text-align: center;
    }
}

@media only screen and (min-width: 1680px) {
    .ht-header-visual-video {
        &.big {
            & .ht-header-visual-content {
                display: flex;
                /* align-items: center; */
                justify-content: center;
                flex-direction: column;
                max-height: none;
                /* height: calc(100vh - 62px); */
                padding: auto;
            }
        }
    }
}

@media only screen and (max-height: 900px) and (min-width: 601px) {
    .ht-header-visual {
        &.big {
            & .ht-header-visual-content {
                display: flex;
                /* align-items: center; */
                justify-content: center;
                flex-direction: column;
                /* height: 90vh; */
                padding: 0 !important;
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .ht-header-visual {
        &-content {
            display: flex;
            /* align-items: center; */
            justify-content: center;
            flex-direction: column;
        }

        & h1 {
            margin-top: 24px !important;
        }
    }
}
