.ht-locations {
    display: flex;
    flex-direction: column;
    gap: 20px;

    & > div {
        display: flex;
        justify-content: center;
        flex-direction: column;
        background-color: var(--blue);
        color: var(--white);
        padding: 40px;
        border-radius: 8px;
    }

    & .ht-product_info {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        & div {
            width: calc(25% - 15px);

            & h4 {
                position: relative;
                display: flex;
                align-items: center;
                font-size: 24px;
                gap: 8px;
                margin: 0 0 14px;

                & i {
                    font-size: 18px;
                }
            }

            & p {
                margin: 0;
            }

            & h4 i:hover {
                cursor: pointer;
            }

            & h4 i:hover + .ht-info_box {
                opacity: 1;
            }

            & .ht-info_box {
                position: absolute;
                display: block;
                opacity: 0;
                transition: var(--standard);
                top: 130%;
                background-color: var(--white);
                color: var(--grey-dark);
                font-size: 13px;
                padding: 10px;
                font-size: 13px;
                width: 200px;
                text-align: center;
                border-radius: 8px;
                right: 0;
            }

            & p {
                font-weight: 400;
                font-size: 18px;
            }
        }
    }

    & .ht-fallback_text p {
        font-weight: 400;
        font-size: 18px;

        &:last-child {
            margin: 0;
        }
    }

    & .ht-location-buttons {
        display: flex;
        flex-wrap: wrap;
        gap: 28px;
        align-items: center;
        justify-content: flex-start;
        margin-top: 36px;

        & .ht-more_info {
            color: var(--pink);
            text-decoration: underline;
            transition: all var(--standard);
            padding: 0;

            &:hover {
                opacity: 0.75;
            }
        }
    }

    & .modal-form-wrapper {
        max-width: unset;
        margin-top: 0 !important;
    }

    & .modal-bg {
        opacity: 0;
        transform: var(--standard);
        z-index: -10;
    }

    & .modal-bg.shown {
        opacity: 1;
        z-index: 10;
    }

    & .gform_wrapper {
        margin: 0;

        & h2 {
            margin-bottom: 8px;
        }

        & h2 + p {
            margin-bottom: 20px;
        }

        & h2,
        & h4 {
            color: var(--grey-dark);
        }

        & h4 {
            margin: 0;
        }

        & p,
        & .gfield_consent_label {
            color: var(--grey-medium);
            margin-bottom: 0;
        }

        &.gform_validation_error {
            & div.validation_error {
                margin: 0 0 24px;
                padding: 0 0 0 40px;
                color: var(--pink);
                font-size: responsive 12px 16px;
                line-height: 1.5;
                border: none;
                text-align: left;
            }

            & .gform_body {
                & ul.gform_fields {
                    & li.gfield {
                        &.field_description_above {
                            & .gfield_description:not(.validation_message) {
                                padding: 0 40px 22px 40px;
                            }
                        }

                        &.field_description_below {
                            & .gfield_description:not(.validation_message) {
                                padding: 22px 40px 0 40px;
                            }
                        }

                        &.gfield_error:not(.gf_left_half):not(.gf_right_half) {
                            width: 100% !important;
                            max-width: none !important;
                            margin: 0 0 32px !important;
                            border: none;
                            background-color: transparent;

                            & .validation_message {
                                position: absolute;
                                padding: 0 0 0 40px;
                                color: var(--pink);
                                font-size: responsive 12px 16px;
                                line-height: 1.5;
                                border: none;
                                text-align: left;
                            }

                            & div.ginput_container {
                                &
                                    input:not([type="radio"]):not(
                                        [type="checkbox"]
                                    ):not([type="submit"]):not(
                                        [type="button"]
                                    ):not([type="image"]):not([type="file"]) {
                                    background-color: var(--pink-20);
                                }

                                & textarea {
                                    background-color: var(--pink-20);
                                }
                            }

                            &.gfield-recaptcha {
                                position: absolute;
                                max-width: 50% !important;
                                margin: 7px auto 0 0;

                                & label.gfield_label {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }

        & .gform_heading {
            display: none;
        }

        & .gform_body {
            & .gform_fields {
                position: relative;

                & .gfield {
                    position: relative;
                    margin: 0 0 32px;
                    padding: 0;

                    &.gfield_html {
                        margin: 0;
                    }

                    &.gfield_error:not(.gf_left_half):not(.gf_right_half) {
                        width: 100% !important;
                        max-width: none !important;
                        margin: 0 0 32px !important;
                        border: none;
                        background-color: transparent;

                        & .validation_message {
                            position: absolute;
                            padding: 0 0 0 40px;
                            color: var(--pink);
                            font-size: responsive 12px 16px;
                            line-height: 1.5;
                            border: none;
                            text-align: left;
                        }

                        & div.ginput_container {
                            &
                                input:not([type="radio"]):not(
                                    [type="checkbox"]
                                ):not([type="submit"]):not([type="button"]):not(
                                    [type="image"]
                                ):not([type="file"]) {
                                background-color: var(--pink-20);
                            }

                            & textarea {
                                background-color: var(--pink-20);
                            }
                        }
                    }

                    & label.gfield_label {
                        position: absolute;
                        z-index: 2;
                        top: 0;
                        left: 0;
                        width: auto;
                        padding: 22px 40px;
                        margin: 0;
                        color: var(--grey-medium);
                        font-size: responsive 16px 24px;
                        line-height: 1.20833;
                        font-weight: 700;
                        border-radius: 42px 0 0 42px;
                        transition: color var(--standard),
                            background-color var(--standard);
                        will-change: color, background-color;

                        &.not-empty {
                            color: var(--white);
                            background-color: var(--blue);
                        }

                        & span.gfield_required {
                            margin: 0 0 0 4px;
                            color: var(--pink);
                            font-size: responsive 16px 24px;
                            line-height: 1.20833;
                            font-weight: 700;
                        }
                    }

                    & div.ginput_container {
                        position: relative;
                        z-index: 1;
                        margin: 0;

                        &
                            input:not([type="radio"]):not(
                                [type="checkbox"]
                            ):not([type="submit"]):not([type="button"]):not(
                                [type="image"]
                            ):not([type="file"]) {
                            width: 100%;
                            padding: 22px 40px 22px 0;
                            margin: 0;
                            color: var(--grey-dark);
                            font-size: responsive 16px 24px;
                            line-height: 1.20833;
                            font-weight: 700;
                            background-color: var(--grey-medium-20);
                            border: none;
                            border-radius: 42px;
                            outline: none;
                        }

                        & textarea {
                            width: 100%;
                            padding: 22px 40px;
                            margin: 0;
                            color: var(--grey-dark);
                            font-size: responsive 16px 24px;
                            line-height: 1.20833;
                            font-weight: 700;
                            background-color: var(--grey-medium-20);
                            border: none;
                            border-radius: 8px;
                            outline: none;
                        }
                    }

                    &.gfield-textarea {
                        & label.gfield_label {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            padding: 40px 40px 34px;
                            margin: 0;
                            color: var(--grey-medium);
                            font-size: responsive 16px 24px;
                            line-height: 1.20833;
                            font-weight: 700;
                            border-radius: 8px 8px 0 0;
                            transition: color var(--standard),
                                background-color var(--standard);
                            will-change: color, background-color;

                            &.not-empty {
                                color: var(--white);
                                background-color: var(--blue);
                            }
                        }

                        & .ginput_container_textarea {
                            & textarea {
                                padding: 143px 40px 64px;
                                min-height: 328px;
                            }
                        }
                    }

                    &.gfield-recaptcha:not(.gf_left_half):not(.gf_right_half) {
                        position: absolute;
                        max-width: 50% !important;
                        margin: 7px auto 0 0;

                        & label.gfield_label {
                            display: none;
                        }
                    }
                }
            }

            & .gform_validation_container,
            & .gfield_visibility_hidden {
                margin: 0 !important;
                padding: 0 !important;
                font-size: 0 !important;
                line-height: 0 !important;

                & label,
                & input {
                    margin: 0 !important;
                    padding: 0 !important;
                    font-size: 0 !important;
                    line-height: 0 !important;
                }
            }
        }

        & .gform_footer.top_label {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            max-width: fit-content;
            padding: 0;
            margin-left: auto;
            margin-right: auto;

            & input.gform_button[type="submit"] {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                margin: 0;
                padding: 24px 56px;
                color: var(--white);
                font-family: var(--font);
                font-size: responsive 28px 36px;
                font-weight: 700;
                text-align: center;
                text-decoration: none;
                text-transform: uppercase;
                line-height: 1.22222;
                background-color: var(--grey-medium);
                border: none;
                border-radius: 46px;
                outline: none;
                transition: background-color var(--standard),
                    color var(--standard);
                will-change: color, background-color;
                cursor: pointer;

                &:hover {
                    color: var(--white);
                    background-color: var(--pink);
                }
            }
        }
    }

    & .ginput_container_consent {
        & input[type="checkbox"] {
            width: 13px;
            height: 13px;
            padding: 0;
            margin: 0;
            vertical-align: top;
            position: relative;
            top: 3px;
            margin-right: 10px;
        }
        & .gfield_consent_label {
            display: inline-block;
            width: 90%;
            font-size: 16px;
            &.not-empty {
                background-color: transparent;
            }
        }
    }
}

.ht-reset {
    display: flex;
    align-items: center;
    cursor: pointer;
    opacity: 0;
    transition: all var(--standard);
    width: 100%;
    justify-content: center;
    gap: 0.5rem;

    & i {
        color: var(--black);
    }

    & #reset_filters_input {
        background-color: transparent;
        background-image: none;
        cursor: pointer;
        border: none;
        margin: 0;
        padding: 0;
        font-size: 18px;
        line-height: 1;
    }
}

.open_form {
    cursor: pointer;
}

input#searchLocations {
    position: relative;
    display: block;
    width: 100%;
    max-width: 730px;
    padding: 22px 70px 22px 40px;
    margin: 0;
    color: var(--grey-dark);
    font-weight: 500;
    line-height: 1.20833;
    border: 1px solid var(--pink);
    border-radius: 42px;
    outline: none;

    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder,
    &:-moz-placeholder {
        color: var(--pink);
        font-weight: 700;
    }
}

.ht-search-locations .ht-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & .button {
        z-index: 1;
    }

    & #filters {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 28px;
        margin-top: 28px;
    }

    & .ht-filter-select {
        position: relative;

        &:after {
            content: "\f078";
            position: absolute;
            top: 0;
            bottom: 0;
            height: max-content;
            right: 10px;
            margin: auto 0;
            color: var(--white);
            font-family: var(--icons);
            font-size: 24px;
            line-height: 1;
            font-weight: 900;
            transition: transform var(--standard), color var(--standard);
            will-change: transform, color;
        }
    }

    & select {
        padding: 10px 40px 10px 10px;
        border-radius: 8px;
        cursor: pointer;
        border: 1px solid var(--blue);
        background-color: var(--blue);
        color: var(--white);
        margin: 0;
        appearance: none;
    }

    & .button {
        padding-top: 23px;
        padding-bottom: 23px;
        font-size: responsive 14px 24px;
        line-height: 1.20833;
        margin-left: -63px;

        &:hover {
            color: var(--white);
            background-color: var(--blue);
        }
    }
}

.ht-location-button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 100%;
}

@media only screen and (max-height: 1000px) {
    .ht-cta-locations {
        & p {
            display: none;
        }
    }
}

@media only screen and (max-width: 1024px) {
    .ht-locations {
        & .ht-product_info {
            & div {
                width: calc(50% - 10px);

                & h4 {
                    font-size: 19px;
                    margin: 0 0 4px;
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .ht-locations {
        & .ht-product_info {
            & div {
                width: 100%;
            }
        }

        & .ht-location-buttons {
            justify-content: center;
        }
    }
}

@media screen and (max-width: 420px) {
    .ht-locations {
        & .modal-bg {
            padding: 0 20px;
        }

        & .close-modal {
            right: -16px;
        }

        & .gform_wrapper .gform_body .gform_fields .gfield label.gfield_label {
            font-size: 14px;
            padding: 22px 10px;
        }

        & > div {
            padding: 20px;
        }
    }

    .ht-search-locations .ht-wrapper {
        & #filters {
            gap: 14px;
        }

        & select {
            width: 100%;
        }

        & .button {
            width: 100%;
            margin: 0;
            padding-top: 16px;
            padding-bottom: 16px;
        }

        & .ht-location-button {
            flex-direction: column;
            gap: 12px;
        }

        & input#searchLocations {
            padding: 16px;
        }
    }
}
