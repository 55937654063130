.ht-breadcrumbs {
    margin: 56px 0 20px;

    & span {
        display: flex;
        align-items: center;
        gap: 8px;

        & span {
            margin: 0;
            color: var(--grey-dark);
            font-family: var(--font);
            font-weight: 700;
            font-size: responsive 12px 16px;
            line-height: 1.25;
            text-transform: uppercase;
            text-decoration: underline;

            & a {
                margin: 0;
                color: var(--grey-dark);
                font-family: var(--font);
                font-weight: 700;
                font-size: responsive 12px 16px;
                line-height: 1.25;
                text-transform: uppercase;
                transition: color var(--standard);
                will-change: color;

                &:first-child {
                    & i,
                    & svg {
                        margin: 0;
                        color: var(--grey-dark);
                        font-family: var(--icons);
                        font-weight: 900;
                        font-size: responsive 12px 16px;
                        line-height: 1;
                        text-transform: uppercase;
                        transition: color var(--standard);
                        will-change: color;
                    }
                }

                &:hover {
                    color: var(--pink);

                    & i,
                    & svg {
                        color: var(--pink);
                    }
                }
            }

            & i,
            & svg {
                margin: 0 4px;
                color: var(--grey-dark);
                font-family: var(--icons);
                font-weight: 900;
                font-size: responsive 12px 16px;
                line-height: 1;
                text-transform: uppercase;
            }

            & span.breadcrumb_last {
                margin: 0;
                color: var(--grey-medium);
                font-family: var(--font);
                font-weight: 700;
                font-size: responsive 12px 16px;
                line-height: 1.25;
                text-transform: uppercase;
            }
        }
    }
}

.woocommerce {
    & .woocommerce-breadcrumb {
        margin: 0 0 20px !important;
        color: var(--grey-medium);
        font-family: var(--font);
        font-weight: 700;
        font-size: responsive 12px 16px;
        line-height: 1.25;
        text-transform: uppercase;

        & a {
            display: inline-block;
            margin: 0;
            color: var(--grey-dark) !important;
            font-family: var(--font);
            font-weight: 700;
            font-size: responsive 12px 16px;
            line-height: 1.25;
            text-transform: uppercase;
            transition: color var(--standard);
            will-change: color;

            &:first-child {
                position: relative;
                font-size: 0;
                line-height: 0;
                text-decoration: none;

                &:after {
                    content: "\f015";
                    margin: 0;
                    color: var(--grey-dark) !important;
                    font-family: var(--icons);
                    font-weight: 900;
                    font-size: responsive 12px 16px;
                    line-height: 1;
                    text-transform: uppercase;
                    transition: color var(--standard);
                    will-change: color;
                }
            }

            &:hover {
                color: var(--pink) !important;

                &:after {
                    color: var(--pink) !important;
                }
            }
        }

        & i,
        & svg {
            margin: 0 4px;
            color: var(--grey-dark) !important;
            font-family: var(--icons);
            font-weight: 900;
            font-size: responsive 12px 16px;
            line-height: 1;
            text-transform: uppercase;
        }
    }
}
