.ht-quote {
    padding: 56px 0;

    & .ht-quote-container {
        width: 100%;
        max-width: 840px;
        margin: 0 auto;

        & blockquote {
            width: 100%;
            font-size: responsive 32px 48px;
            line-height: 1.16667;
            font-weight: 700;
            text-align: left;
    
            &.blue {
                color: var(--blue);
            }
    
            &.grey {
                color: var(--grey-medium);
            }
        }
    
        & .quotee {
            color: var(--blue);
            margin: 0;
    
            &:before {
                content: '\2014';
                margin-right: 4px;
            }
        }
    }
}

@media only screen and (max-width: 568px) {
    .ht-quote {
        padding: 24px 0;
    }
}
