.modal-bg {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    display: none;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-color: var(--blue-90);
    transition: all var(--standard);
}
.modal-bg.shown {
    display: flex;
}

.modal-frame {
    width: 100%;
    max-width: 1280px;
    height: auto;
    max-height: 90vh;
    background-color: var(--white);
    border-radius: 8px;
    transition: all var(--standard);
}

.modal-content {
    position: relative;
    width: 100%;
    max-height: calc(90vh);
    padding: 80px 0;
    border: none;
}

.modal-inner {
    height: 100%;
    max-height: calc(90vh - 160px);
    padding: 0 80px;
    overflow-y: auto;

    & .ht-product-list {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 56px 0;

        & .ht-product-link {
            position: relative;
            display: flex;
            width: 100%;
            padding: 0 0 40px;
            margin: 0 0 40px;
            border-bottom: 1px solid var(--grey-medium);
            text-decoration: none;

            &:hover {
                & .ht-product-text {
                    & h3 {
                        color: var(--pink);
                    }
                }

                &:after {
                    color: var(--pink);
                }
            }

            &:last-child {
                margin: 0;
            }

            &:after {
                content: '\f061';
                position: absolute;
                top: 8px;
                right: 0;
                color: var(--grey-medium);
                font-family: var(--icons);
                font-size: responsive 28px 48px;
                line-height: 1;
                transition: color var(--standard);
            }

            & .ht-product-img {
                width: 12%;
                min-width: 80px;
                margin: 0 24px 0 0;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                border-radius: 8px;
            }

            & .ht-product-text {
                display: flex;
                flex-direction: column;
                width: calc(88% - 24px);

                & h3 {
                    padding: 0 48px 0 0;
                    margin: 0 0 16px;
                    color: var(--blue);
                    text-align: left;
                    transition: color var(--standard);
                }
    
                & p {
                    margin: 0;
                    color: var(--grey-medium);
                    font-size: responsive 14px 24px;
                    line-height: 1.5;
                    text-align: left;
    
                    & span.woocommerce-Price-amount.amount {
                        margin: 0;
                        font-size: responsive 20px 32px;
                        line-height: 1.15625;
                        
                        & span.woocommerce-Price-currencySymbol {
                            font-size: responsive 20px 32px;
                            line-height: 1.15625;
                        }
                    }

                    & .vat {
                        font-size: responsive 10px 14px;
                        line-height: 1.5;
                    }
                }
            }
        }
    }

    & h1,
    & .h1,
    & h2,
    & .h2,
    & h3,
    & .h3 h4,
    & .h4,
    & h5,
    & .h5
    & h6,
    & .h6,
    & p,
    & blockquote,
    & ul,
    & ol,
    & span {
        text-align: center;
    }
}

.modal-text {
    max-width: 840px;
    margin: 0 auto;

    & h1,
    & .h1,
    & h2,
    & .h2,
    & h3,
    & .h3 h4,
    & .h4,
    & h5,
    & .h5
    & h6,
    & .h6 {
        color: var(--grey-dark);
    }

    & p,
    & input,
    & textarea,
    & select,
    & label,
    & td,
    & th,
    & blockquote,
    & ul,
    & ol,
    & span {
        color: var(--grey-dark);
    }
}

.close-modal {
    position: absolute;
    top: -28px;
    right: -28px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 56px;
    background-color: var(--grey-dark);
    border-radius: 28px;
    cursor: pointer;

    & img {
        width: 16px;
        height: 16px;
    }
}

@media only screen and (max-width: 1366px) {
    .modal-frame {
        max-width: 1180px;
    }
}

@media only screen and (max-width: 1280px) {
    .modal-frame {
        max-width: 1024px;
    }
}

@media only screen and (max-width: 1180px) {
    .modal-frame {
        max-width: 960px;
    }
}

@media only screen and (max-width: 1024px) {
    .modal-frame {
        max-width: 812px;

        & .modal-text {
            max-width: none;
        }
    }

    .modal-content {
        padding: 40px 24px;
    }

    .modal-inner {
        padding: 0;
    }
}

@media only screen and (max-width: 876px) {
    .modal-frame {
        max-width: 768px;
    }
}

@media only screen and (max-width: 812px) {
    .modal-frame {
        max-width: 736px;
    }
}

@media only screen and (max-width: 768px) {
    .modal-frame {
        max-width: 704px;

        & .modal-form-wrapper {
            & .gform_wrapper {
                & .gform_body {
                    & ul.gform_fields {
                        & li.gfield {
                            &.gfield-recaptcha:not(.gf_left_half):not(.gf_right_half) {
                                position: relative !important;
                                max-width: none !important;
                                margin: 7px 0;
    
                                & > .ginput_recaptcha {
                                    & > div {
                                        margin: 0 0 0 auto;
                                    }
                                }
                            }
                        }
                    }
                }
    
                & .gform_footer {
                    max-width: none;
                    padding: 0;
                    margin: 24px 0 0 auto;
                }
            }
        }
    }
}

@media only screen and (max-width: 736px) {
    .modal-frame {
        max-width: calc(100vw - 48px);
    }
}

@media only screen and (max-width: 414px) {
    .modal-frame {
        max-width: calc(100vw - 32px);
    }
}
