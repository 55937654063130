html,
body {
    padding: 0px;
    margin: 0px;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    word-break: break-word;
    hyphens: none;

    &:not(.locked) {
        overflow-y: auto !important;
    }

    &.locked {
        overflow-y: hidden !important;
    }
}

html {
    background-color: var(--white);
}

body {
    width: 100%;
    color: var(--grey-medium);
    font-family: var(--font);
    font-range: 375px 1440px;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    word-break: break-word;
}

h1,
.h1,
h2,
.h2,
h3,
.h3 h4,
.h4,
h5,
.h5,
h6,
.h6 {
    margin: 0 0 32px;
    font-family: var(--font);
    font-weight: 700;
    hyphens: none;
    word-break: break-word;
}

p,
input,
textarea,
select,
label,
td,
th,
blockquote,
ul,
ol,
span {
    margin: 0 0 16px;
    font-family: var(--font);
    font-weight: 500;
}

a {
    cursor: pointer;
}
