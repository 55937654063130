.ht-faq {
    padding: 56px 0;

    &-container {
        max-width: 840px;
        margin: 0 auto;

        & h2 {
            color: var(--grey-dark);
            text-align: center;
        }

        & .ht-faq-question-wrapper {
            padding: 0;
            border-top: 1px solid var(--grey-dark-30);

            &:last-of-type {
                border-bottom: 1px solid var(--grey-dark-30);
            }

            &.closed {
                & .ht-faq-q {
                    &:after {
                        transform: rotate(0deg);
                    }
                }
            }
    
            &.open {
                & .ht-faq-q {
                    &:after {
                        transform: rotate(180deg);
                    }
                }
            }

            & .ht-faq-q {
                position: relative;
                padding: 16px 36px 16px 0;
                cursor: pointer;

                & p {
                    margin: 0;
                    color: var(--grey-dark);
                    font-weight: 700;
                    transition: color var(--standard);
                    will-change: color;
                }

                &:after {
                    content: '\f078';
                    position: absolute;
                    top: 24px;
                    right: 8px;
                    color: var(--grey-dark);
                    font-family: var(--icons);
                    font-size: 24px;
                    line-height: 1;
                    font-weight: 900;
                    transform: translateY(-50%);
                    transition: transform var(--standard), color var(--standard);
                    will-change: transform, color;
                }

                &:hover {
                    & p {
                        color: var(--pink);
                    }

                    &:after {
                        color: var(--pink);
                    }
                }
            }
    
            & .ht-faq-a {
                display: none;
                padding: 0 0 16px;

                & ol,
                & ul,
                & p {
                    &:last-child {
                        margin: 0;
                    }
                }

                & a {
                    color: var(--blue);
                    transition: color var(--standard);
                    will-change: color;

                    &:hover {
                        color: var(--pink);
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 568px) {
    .ht-faq {
        padding: 24px 0;
    }
}
