.ht-biglinks {
    padding: 56px 0;

    & .ht-biglinks-container {
        padding: 0 8.59375%;

        & a {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 40px 0;
            margin: 0;
            text-decoration: none;
            border-bottom: 1px solid var(--grey-medium);

            &:hover {
                & span,
                & i,
                & svg {
                    color: var(--pink);
                }
            }

            & span {
                margin: 0;
                color: var(--grey-dark);
                font-family: var(--font);
                font-weight: 700;
                font-size: responsive 32px 48px;
                line-height: 1.22916;
                transition: color var(--standard);
                will-change: color;
            }

            & i,
            & svg {
                margin: 0;
                color: var(--grey-medium);
                font-family: var(--icons);
                font-weight: 400;
                font-size: responsive 32px 48px;
                line-height: 1;
                transition: color var(--standard);
                will-change: color;
            }
        }
    }
}

@media only screen and (max-width: 736px) {
    .ht-biglinks {
        & .ht-biglinks-container {
            padding: 0 24px;
        }
    }
}

@media only screen and (max-width: 568px) {
    .ht-biglinks {
        padding: 24px 0;
    }
}

@media only screen and (max-width: 414px) {
    .ht-biglinks {
        & .ht-biglinks-container {
            padding: 0;
        }
    }
}
