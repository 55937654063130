.woocommerce {
    & .woocommerce-message {
        color: var(--black);
        font-size: responsive 12px 18px;
        line-height: 1.5;
        font-weight: 400;
        font-range: 375px 1440px;
        background-color: var(--grey-light);
        border-top: 3px solid;
        border-top-color: var(--green);

        &:before {
            color: var(--green);
        }

        & strong {
            font-size: responsive 12px 18px;
            line-height: 1.5;
            font-weight: 400;
        }

        & > a {
            &:not(.button) {
                color: var(--blue);
            }

            &.button {
                padding: 8px 16px;
                font-size: responsive 10px 14px;
                line-height: 1.21428;
                border-radius: 16px;

                &.wc-forward {
                    color: var(--white);
                    background-color: var(--pink);
                    transition: background-color var(--standard);
                    will-change: background-color;

                    &:hover {
                        background-color: var(--blue);
                    }
                }

                &.change-booking {
                    color: var(--white);
                    background-color: var(--blue);
                    transition: background-color var(--standard);
                    will-change: background-color;
            
                    &:hover {
                        background-color: var(--pink);
                    }
                }
            }
        }
    }

    & .woocommerce-error {
        color: var(--black);
        font-size: responsive 12px 18px;
        line-height: 1.5;
        font-weight: 400;
        font-range: 375px 1440px;
        background-color: var(--grey-light);
        border-top: 3px solid;
        border-top-color: var(--red);

        &:before {
            color: var(--red);
        }

        & strong {
            font-size: responsive 12px 18px;
            line-height: 1.5;
            font-weight: 400;
            font-range: 375px 1440px;
        }

        & a {
            &:not(.button) {
                color: var(--blue);
            }

            &.button {
                padding: 8px 16px;
                font-size: responsive 10px 14px;
                line-height: 1.21428;
                border-radius: 16px;

                &.wc-forward {
                    color: var(--white);
                    background-color: var(--pink);
                    transition: background-color var(--standard);
                    will-change: background-color;

                    &:hover {
                        background-color: var(--blue);
                    }
                }
            }
        }
    }

    & .woocommerce-info {
        color: var(--black);
        font-size: responsive 12px 18px;
        line-height: 1.5;
        font-range: 375px 1440px;
        background-color: var(--grey-light);
        border-top: 3px solid;
        border-top-color: var(--blue);

        &:before {
            color: var(--blue);
        }

        & strong {
            font-size: responsive 12px 18px;
            line-height: 1.5;
            font-weight: 400;
            font-range: 375px 1440px;
        }
    }

    & p.return-to-shop {
        & .button {
            padding: 8px 16px;
            font-size: responsive 10px 14px;
            line-height: 1.21428;
            border-radius: 16px;

            &.wc-backward {
                color: var(--white);
                background-color: var(--blue);
                transition: background-color var(--standard);
                will-change: background-color;

                &:hover {
                    background-color: var(--pink);
                }
            }
        }
    }
}

@media only screen and (max-width: 667px) {
    .woocommerce {
        & .woocommerce-message {
            display: flex;
            flex-direction: column;

            & > a {
                display: flex;
                flex-direction: column;

                &.button {
                    &.wc-forward {
                        order: 2;
                        margin: 16px 0 8px;
                    }
    
                    &.change-booking {
                        width: 100%;
                        order: 3;
                    }
                }
            }
        }

        & .woocommerce-error {
            & li {
                display: flex;
                flex-direction: column;

                & .button {
                    &.wc-forward {
                        margin: 16px 0 0 0;
                        order: 2;
                    }
                }
            }
        }
    }
}
