.ht-2-col {
    padding: 56px 0;

    & .ht-wrapper {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
    }

    & .ht-buttons {
        display: flex;
        flex-wrap: wrap;
    }

    & .button {
        margin-top: 20px;

        &:not(.button-pink-white) {
            color: var(--pink);
            text-decoration: underline;
            transition: all var(--standard);
            padding-right: 0;

            &:hover {
                opacity: 0.75;
            }
        }
    }

    &.i-t,
    &.i-i {
        & .ht-2-col-left {
            width: 50%;
            align-self: flex-end;
        }

        & .ht-2-col-right {
            width: calc(50% + 36px);
            margin-left: -10%;
            margin-bottom: 120px;
        }
    }

    &.t-i {
        & .ht-2-col-left {
            width: calc(50% + 36px);
            margin-right: -10%;
            margin-bottom: 120px;
        }

        & .ht-2-col-right {
            width: 50%;
            align-self: flex-end;
        }
    }

    &.i-t,
    &.t-t {
        &.white{
            & .ht-2-col-left, 
            & .ht-2-col-right {
                background-color: transparent;
                padding: 16px;
            }
        }
        & .ht-2-col-right {
            background-color: var(--grey-light);
            padding: 56px;
            border-radius: 8px;
        }
    }

    &.t-i,
    &.t-t {
        &.white{
            & .ht-2-col- {
                background-color: transparent;
                padding: 16px;
            }
        }
        & .ht-2-col-left {
            background-color: var(--grey-light);
            padding: 56px;
            border-radius: 8px;
        }
    }

    &.t-t {
        & .ht-2-col-left,
        & .ht-2-col-right {
            width: calc(50% - 20px);
        }
    }

    &-left {
        & h1,
        & h2,
        & h3,
        & h4,
        & h5,
        & h6,
        & .h1,
        & .h2,
        & .h3,
        & .h4,
        & .h5,
        & .h6 {
            margin-top: 40px;
            margin-bottom: 32px;
            color: var(--grey-dark);

            &:first-child {
                margin-top: 0;
                margin-bottom: 32px;
            }
        }

        & p,
        & input,
        & textarea,
        & select,
        & label,
        & td,
        & th,
        & blockquote,
        & ul,
        & ol,
        & span {
            color: var(--grey-medium);
            font-size: 20px;
        }

        & ul.usp {
            list-style: none;

            & li {
                position: relative;

                &:before {
                    content: "\f00c";
                    position: absolute;
                    left: -30px;
                    top: 8.5px;
                    color: var(--blue);
                    font-family: var(--icons);
                    font-size: responsive 20px 20px;
                    line-height: 1;
                }
            }
        }

        & a {
            color: var(--blue);
            transition: color var(--standard);
            will-change: color;

            &:hover {
                color: var(--pink);
            }
        }

        & img {
            width: 100%;
            height: auto;
            max-width: none;
            margin-right: -18.06451%;
            border-radius: 8px;
        }
    }

    &-right {
        & h1,
        & h2,
        & h3,
        & h4,
        & h5,
        & h6,
        & .h1,
        & .h2,
        & .h3,
        & .h4,
        & .h5,
        & .h6 {
            color: var(--grey-dark);
        }

        & p,
        & input,
        & textarea,
        & select,
        & label,
        & td,
        & th,
        & blockquote,
        & ul,
        & ol,
        & span {
            color: var(--grey-medium);
        }

        & ul.usp {
            list-style: none;

            & li {
                position: relative;

                &:before {
                    content: "\f00c";
                    position: absolute;
                    left: -30px;
                    top: 8.5px;
                    color: var(--blue);
                    font-family: var(--icons);
                    font-size: responsive 20px 20px;
                    line-height: 1;
                }
            }
        }

        & img {
            width: 100%;
            height: auto;
            max-width: none;
            border-radius: 8px;
        }
    }
}

@media only screen and (max-width: 812px) {
    .ht-2-col {
        padding: 56px 0;

        & .ht-wrapper {
            display: flex;
            align-items: stretch;
            justify-content: space-between;
        }

        &-left,
        &-right {
            width: calc(50% - 20px);
        }

        &-left {
            & h1,
            & h2,
            & h3,
            & h4,
            & h5,
            & h6,
            & .h1,
            & .h2,
            & .h3,
            & .h4,
            & .h5,
            & .h6,
            & p,
            & input,
            & textarea,
            & select,
            & label,
            & td,
            & th,
            & blockquote,
            & ul,
            & ol,
            & span {
                margin-right: 0;
            }
        }

        &-right {
            & h1,
            & h2,
            & h3,
            & h4,
            & h5,
            & h6,
            & .h1,
            & .h2,
            & .h3,
            & .h4,
            & .h5,
            & .h6,
            & p,
            & input,
            & textarea,
            & select,
            & label,
            & td,
            & th,
            & blockquote,
            & ul,
            & ol,
            & span {
                margin-left: 0;
            }
        }
    }
}

@media only screen and (max-width: 667px) {
    .ht-2-col {
        & .ht-wrapper {
            flex-direction: column;
        }

        &.t-t,
        &.t-i,
        &.i-t,
        &.i-i {
            & .ht-2-col-left,
            & .ht-2-col-right {
                width: 100%;
                margin: 0;
            }

            & .ht-2-col-left {
                margin: 0 0 40px;
            }
        }
    }
}

@media only screen and (max-width: 568px) {
    .ht-2-col {
        padding: 24px 0;
    }

    .ht-2-col.i-i .ht-2-col-left, 
    .ht-2-col.i-t .ht-2-col-left, 
    .ht-2-col.t-i .ht-2-col-left, 
    .ht-2-col.t-t .ht-2-col-left,
    .ht-2-col-right, 
    .ht-2-col.i-t .ht-2-col-right, 
    .ht-2-col.t-t .ht-2-col-left
    {
        padding: 24px;
        p, ul, li{
            font-size: 14px;
        }

        h5{
            font-size: 16px;
        }
        h4{
            font-size: 20px;
        }
        h3{
            font-size: 24px;
        }
    }
}
