.ht-home_hero {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    min-height: 700px;
    display: flex;
    align-items: center;

    &:after {
        content: "";
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--black-50);
    }

    & .ht-video-background {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    & video {
        position: absolute;
    }

    & h1,
    & ul {
        color: var(--white);
        margin: 0;
    }

    & ul {
        list-style: none;
        padding: 0;
        gap: 12px;
        display: flex;
        flex-direction: column;

        & li {
            display: flex;
            align-items: center;
            gap: 12px;

            & svg {
                height: 25px;
                min-width: 25px;
            }
        }
    }

    & .ht-wrapper {
        position: relative;
        margin-top: 60px;
        padding: 60px 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 40px;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 3;
    }

    & .ht-home_hero-buttons {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px;
        margin-top: 4px;
    }
}

@media only screen and (max-width: 768px) {
    .ht-home_hero {
        height: 100%;
        min-height: unset;
    }
}

@media only screen and (max-width: 425px) {
    .ht-home_hero {
        & .ht-home_hero-buttons {
            & > * {
                width: 100%;
            }
        }
    }
}
