.ht-video {
    padding: 56px 0;

    & .ht-video-container {
        position: relative;
        width: 100%;
        padding-top: 56.25%;

        & iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &.narrow {
        & .ht-video-container {
            max-width: 840px;
            max-height: 473px;
            padding-top: 36.92%;
            margin: 0 auto;
        }    
    }
}

@media only screen and (max-width: 1024px) {
    .ht-video {
        &.narrow {
            & .ht-video-container {
                width: 100%;
                max-width: none;
                max-height: none;
                padding-top: 56.25%;
                margin: 0 auto;
            }    
        }
    }
}

@media only screen and (max-width: 568px) {
    .ht-video {
        padding: 24px 0;
    }
}
