body.woocommerce-order-received {
    & .woocommerce {
        & .woocommerce-order {
            & p.woocommerce-thankyou-order-received {
                margin: 0 0 40px;
                color: var(--pink);
                font-size: responsive 28px 48px;
                line-height: 1.16667;
                text-align: center;
            }

            & ul.woocommerce-order-overview {
                display: flex;
                align-items: stretch;
                justify-content: space-between;
                padding: 0;
                margin: 0 0 40px;

                & li {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    flex-direction: column;
                    width: 25%;
                    margin: 0;
                    padding: 0 16px;

                    & strong {
                        text-align: center;
                    }
                }
            }

            & section.woocommerce-order-details {
                width: 100% !important;

                &.woocommerce-info {
                    & p {
                        color: var(--black);
                        font-size: responsive 12px 18px;
                        line-height: 1.5;
                        font-weight: 400;
                        font-range: 375px 1440px;
                        background-color: var(--grey-light);
                
                        &:before {
                            color: var(--blue);
                        }
                
                        & strong {
                            font-size: responsive 12px 18px;
                            line-height: 1.5;
                            font-weight: 700;
                        }
                    }
                }

                & table.woocommerce-table--order-details,
                & table.woocommerce-table--custom-fields {
                    border-collapse: collapse;

                    & thead {
                        & tr {
                            & th {
                                color: var(--black);
                                padding: 8px;
                            }
                        }
                    }

                    & tbody {
                        & tr {
                            & td {
                                padding: 8px;

                                &.product-name {
                                    color: var(--blue);
                                    font-size: responsive 14px 20px;
                                    line-height: 1.22222;
                                }

                                &.product-total {
                                    vertical-align: top;
                                    color: var(--grey-medium);
                                    font-size: responsive 14px 20px;
                                    line-height: 1.22222;
                                    font-weight: 500;
                                    
                                    & .woocommerce-Price-amount {
                                        color: var(--grey-medium);
                                        font-size: responsive 14px 20px;
                                        line-height: 1.22222;
                                        font-weight: 500;

                                        & .woocommerce-Price-currencySymbol {
                                            color: var(--grey-medium);
                                            font-size: responsive 14px 20px;
                                            line-height: 1.22222;
                                            font-weight: 500;
                                        }
                                    }
                                }

                                & ul {
                                    margin: 0 0 16px;
                                    padding-left: 1.4rem;

                                    &:first-child {
                                        margin-top: 8px;
                                    }

                                    & li {
                                        color: var(--grey-medium);
                                        font-size: responsive 12px 18px;
                                        line-height: 1.22222;
                                    }

                                    & li.drukwerk {
                                        color: var(--grey-medium);
                                        font-size: responsive 12px 18px;
                                        line-height: 1.22222;

                                        & .pewc-order-item-label,
                                        & .pewc-order-item-price {
                                            color: var(--grey-medium);
                                            font-size: responsive 12px 18px;
                                            line-height: 1.22222;

                                            & .woocommerce-Price-amount {
                                                color: var(--grey-medium);
                                                font-size: responsive 12px 18px;
                                                line-height: 1.22222;

                                                & .woocommerce-Price-currencySymbol {
                                                    color: var(--grey-medium);
                                                    font-size: responsive 12px 18px;
                                                    line-height: 1.22222;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    & tfoot {
                        & tr {
                            & th {
                                color: var(--grey-medium);
                                font-size: responsive 14px 20px;
                                line-height: 1.22222;
                            }

                            & td {
                                color: var(--grey-medium);
                                font-size: responsive 14px 20px;
                                line-height: 1.22222;
                                font-weight: 500;
                                
                                & .woocommerce-Price-amount {
                                    color: var(--grey-medium);
                                    font-size: responsive 14px 20px;
                                    line-height: 1.22222;
                                    font-weight: 500;

                                    & .woocommerce-Price-currencySymbol {
                                        color: var(--grey-medium);
                                        font-size: responsive 14px 20px;
                                        line-height: 1.22222;
                                        font-weight: 500;
                                    }
                                }
                            }

                            &:nth-last-child(2) {
                                & th {
                                    color: var(--black);
                                    font-size: responsive 18px 24px;
                                    line-height: 1.22222;
                                    border-top: 3px solid var(--black-10);
                                }
    
                                & td {
                                    color: var(--black);
                                    font-size: responsive 18px 24px;
                                    line-height: 1.22222;
                                    font-weight: 500;
                                    border-top: 3px solid var(--black-10);
                                    
                                    & .woocommerce-Price-amount {
                                        color: var(--black);
                                        font-size: responsive 18px 24px;
                                        line-height: 1.22222;
                                        font-weight: 500;
    
                                        & .woocommerce-Price-currencySymbol {
                                            color: var(--black);
                                            font-size: responsive 18px 24px;
                                            line-height: 1.22222;
                                            font-weight: 500;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                & table.woocommerce-table--custom-fields {
                    & tr {
                        & th {
                            width: 1px;
                            white-space: nowrap;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 480px) {
    body.woocommerce-order-received {
        & .woocommerce {
            & .woocommerce-order {
                & ul.woocommerce-order-overview {
                    flex-direction: column;
    
                    & li {
                        width: 100%;
                        margin: 0 0 8px;
                        padding: 8px 0;
                        border-right: 0;
                        border-bottom: 1px dashed #d3ced2;

                        &:last-child {
                            margin: 0;
                            border-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}
